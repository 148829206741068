import { useT } from "@transifex/react";

import { RotatedP6Icon, ToggleItem, ToggleText, TopBottomToggleWrapper } from "./StyledComponents";

interface PDFSettingsTopBottomToggleProps {
    isTop: boolean;
    togglePosition: () => void;
    disabled?: boolean;
}

const PDFSettingsTopBottomToggle = (props: PDFSettingsTopBottomToggleProps) => {
    const t = useT();

    const t_top = t("top", { _tags: "PDFConfiguration" });
    const t_bottom = t("bottom", { _tags: "PDFConfiguration" });

    const renderToggleHalf = (label: string, isActive: boolean, iconName: string) => {
        return (
            <ToggleItem isActive={isActive}>
                <RotatedP6Icon name={iconName} />
                <ToggleText>{label}</ToggleText>
            </ToggleItem>
        );
    };

    return (
        <TopBottomToggleWrapper
            disabled={props.disabled}
            onClick={() => !props.disabled && props.togglePosition()}
        >
            {renderToggleHalf(t_top, props.isTop, "arrow-left")}
            {renderToggleHalf(t_bottom, !props.isTop, "arrow-right")}
        </TopBottomToggleWrapper>
    );
};

export default PDFSettingsTopBottomToggle;
