import { TestDetailsWord, TestQuestionModel, UserOwnCard, VerbtrainingSelectedWord } from "p6m-p6u";
import React from "react";
import { ExerciseDirections } from "../../../../../../enums/directions";
import { getExerciseDirection } from "../../../../../../helpers/TestCreation";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";
import WordScoreBubble from "../../../../../../basic/wordScoreBubble/WordScoreBubble";

import { Wrapper } from "../../StyledComponents";
import {
    QuestionAndAnswer,
    StandardWordRow,
    Word,
    WordControl,
    WordIcon,
    WordWrapper,
    WordAndScoreWrapper,
    StandardWrapper,
} from "./StyledComponents";

const _findWordAmongWords = (wordId: string, words: Array<TestDetailsWord | VerbtrainingSelectedWord>) => {
    return words.find((word) => {
        if ("id" in word) {
            return word.id === wordId;
        } else {
            return false;
        }
    });
};

interface DetailsStandardProps {
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
    currentExerciseModel: TestQuestionModel;
    setCurrentExerciseModel: (exercise: TestQuestionModel) => void;
    setIsTestSynced: (isSynced: boolean) => void;
    showScores: boolean;
}

const DetailsStandard: React.FC<DetailsStandardProps> = (props) => {
    const words: Array<TestDetailsWord | VerbtrainingSelectedWord | UserOwnCard> =
        props.currentExerciseModel.wordsInProgress || [];

    function updateWordDirection(wordId: string, direction: ExerciseDirections) {
        const exercise = { ...props.currentExerciseModel };
        if (exercise.wordsInProgress) {
            const wordToChange: TestDetailsWord | VerbtrainingSelectedWord | undefined = _findWordAmongWords(
                wordId,
                exercise.wordsInProgress
            );

            if (wordToChange && "id" in wordToChange) {
                wordToChange.isQuestionShown = direction === ExerciseDirections.QUESTION_TO_ANSWER;

                // update overall direction
                exercise.direction = getExerciseDirection(exercise);

                props.setIsTestSynced(false);
            }

            props.setCurrentExerciseModel(exercise);
        }
    }

    const toggleWordDirection = (word: TestDetailsWord | VerbtrainingSelectedWord | UserOwnCard) => {
        if ("id" in word) {
            const newWordDirection = word.isQuestionShown
                ? ExerciseDirections.ANSWER_TO_QUESTION
                : ExerciseDirections.QUESTION_TO_ANSWER;
            updateWordDirection(word.id ?? "", newWordDirection);
        }
    };

    const renderWord = (word: string, isShown: boolean) => {
        return (
            <WordWrapper>
                <WordIcon name={isShown ? "eye" : "eye-closed"} />
                <Word className={isShown ? "" : "blur"}>{word}</Word>
            </WordWrapper>
        );
    };

    const renderWordRow = (word: TestDetailsWord | UserOwnCard) => {
        return (
            <WordAndScoreWrapper key={"wordRow" + word.question}>
                 <StandardWordRow className="word-row">
                    <QuestionAndAnswer onClick={() => toggleWordDirection(word)}>
                        {renderWord(word.question ?? "", word.isQuestionShown ?? true)}
                        {renderWord(word.answer ?? "", !word.isQuestionShown)}
                    </QuestionAndAnswer>
                    <WordControl>
                        <WordIcon
                            name={"arrows-right-left"}
                            onClick={() => toggleWordDirection(word)}
                        />
                        <WordIcon
                            name={"close"}
                            onClick={() => props.onRemoveWord(word.id)}
                        />
                    </WordControl>
                </StandardWordRow>

                {props.showScores && <WordScoreBubble wordId={word.id ?? ""} />}
            </WordAndScoreWrapper>
        );
    };

    return (
        <Wrapper>
            <StandardWrapper>
                {words.length > 0 &&
                    words.map((word, index) => {
                        if ("id" in word && word.question && word.answer) {
                            return renderWordRow(word);
                        } else {
                            // do not render VerbtrainingSelectedWord fallback
                            return <div key={index} />;
                        }
                    })}
            </StandardWrapper>
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsStandard;
