import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import DesignConstants from "../../../../constants/DesignConstants";

interface PDFSignaturesProps {
    showParentSignature?: boolean;
    showTeacherSignature?: boolean;
}

const PDFSignatures = (props: PDFSignaturesProps) => {
    const t = useT();
    const t_signatureOfParent = t("Signature of a parent", { _tags: "PDFConfiguration" });
    const t_signatureOfTeacher = t("Signature of the teacher", { _tags: "PDFConfiguration" });

    const renderSignature = (label: string) => {
        return (
            <View style={styles.signatureWrapper}>
                <Text style={styles.basicText}>{label}</Text>
            </View>
        );
    };

    return (
        <View
            style={styles.allSignaturesWrapper}
            wrap={false}
        >
            {props.showParentSignature && renderSignature(t_signatureOfParent)}
            {props.showTeacherSignature && renderSignature(t_signatureOfTeacher)}
        </View>
    );
};

const styles = StyleSheet.create({
    basicText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    allSignaturesWrapper: {
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XXL,
        justifyContent: "flex-end",
    },
    signatureWrapper: {
        flex: 1,
        justifyContent: "flex-end",
        paddingBottom: DesignConstants.PDF.GAP_M,
        borderBottomColor: DesignConstants.COLORS.DARK_GREY,
        borderBottomWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        maxWidth: "40vw",
    },
});

export default PDFSignatures;
