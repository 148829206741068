import * as React from "react";
import styled from "styled-components/macro";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import Modal, { Styles } from "react-modal";
import { BlankButton, PrimaryButton } from "p6-react-shared";
import { useT } from "@transifex/react/dist";
import { ReactComponent as IncorrectIcon } from "../../../assets/icons/exercise-failed.svg";

export interface InfoModalProps {
    isOpen: boolean;
    onRequestClose: () => any;
    modalIcon?: React.FC;
    iconColor?: string;
    modalTitle?: React.ReactNode;
    cancelAction?: () => any;
    cancelText?: string;
    continueAction?: () => any;
    continueText?: string;
    children?: JSX.Element | JSX.Element[];
    className?: string;
    includeCloseHeader?: boolean;
}

const ModalContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    > * {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const ModalIcon = styled(SvgIcon)`
    height: 3rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        height: 4rem;
    }
`;
const ModalTitle = styled.div`
    text-align: center;
    font-size: 1.3rem;
    color: ${(props) => props.theme.base.primaryColor};
    padding: 0 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: inherit;
        font-size: 1.6rem;
    }
`;

const ModalContinueCTA = styled(PrimaryButton)`
    box-shadow: 0 5px 0 rgb(0 0 0 / 10%);
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: normal;
    padding: 0.5rem 0;
    text-align: center;
    height: 4rem;
    border-radius: 1rem;

    & > span {
        text-align: center;
    }
`;

const ModalCancelCTA = styled(BlankButton)`
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: normal;
    padding: 0.5rem 0;
    text-align: center;
    height: 4rem;
    border-radius: 1rem;
    border: solid 1px #707070;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.base.primaryColor};
    }

    & > span {
        text-align: center;
        color: #666;

        &:hover {
            color: ${(props) => props.theme.base.primaryColor};
        }
    }
`;

const CloseHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 1rem;
`;

const CloseIconWrapper = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
`;

const modalStyles: Styles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        zIndex: 100,
    },
    content: {
        position: "unset",
        margin: "1rem",
        border: "solid 1px #707070",
        borderRadius: "1rem",
        padding: "2rem 1rem",
        maxWidth: "800px",
        maxHeight: "95%",
    },
};

Modal.setAppElement("#root");

const InfoModal: React.FC<InfoModalProps> = (props) => {
    const t = useT();
    const t_cancelText = t("Cancel", { _tags: "infoModal" });
    const t_continue = t("Continue", { _tags: "infoModal" });

    const {
        modalTitle,
        modalIcon,
        iconColor,
        children,
        cancelAction,
        cancelText,
        continueAction,
        continueText,
        isOpen,
        onRequestClose,
        includeCloseHeader,
    } = props;
    const modalProps = { isOpen, onRequestClose };

    return (
        <Modal
            {...modalProps}
            style={modalStyles}
        >
            <ModalContent className={props.className}>
                {includeCloseHeader && onRequestClose && (
                    <CloseHeader>
                        <CloseIconWrapper onClick={onRequestClose}>
                            <SvgIcon
                                svgComponent={IncorrectIcon}
                                color={"#fc7c00"}
                            />
                        </CloseIconWrapper>
                    </CloseHeader>
                )}
                {!!modalIcon && (
                    <ModalIcon
                        svgComponent={modalIcon}
                        color={iconColor}
                    />
                )}

                {!!modalTitle && <ModalTitle>{modalTitle}</ModalTitle>}
                {children}
                {!!cancelAction && (
                    <ModalCancelCTA
                        text={!!cancelText ? cancelText : t_cancelText}
                        onClick={cancelAction}
                    />
                )}
                {!!continueAction && (
                    <ModalContinueCTA onClick={continueAction}>
                        {!!continueText ? continueText : t_continue}
                    </ModalContinueCTA>
                )}
            </ModalContent>
        </Modal>
    );
};

export default InfoModal;
