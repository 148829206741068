import { useT } from "@transifex/react";
import moment, { Moment } from "moment";
import { useState } from "react";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import PDFSettingControl from "../../PDFSettingControl";
import PDFSettingDetails from "../../pdfSettingDetails/PDFSettingDetails";

import {
    ActionsWrapper,
    DateText,
    DateTimeWrapper,
    StyledIcon,
    StyledKeyboardDateTimePicker,
    Wrapper,
} from "./StyledComponents";

interface PDFDateSettingControlProps {
    settingTitle: string;
    isActive: boolean;
    currentDate?: number;
    onToggle: () => void;
    handleDateSelection: (wip: number) => void;
}

const PDFDateSettingControl = (props: PDFDateSettingControlProps) => {
    const inputRef = useClickOutside(() => {
        if (isEditing) {
            setIsEditing(false);
            if (currentDate) handleSave();
        }
    });

    const t = useT();

    const t_prefillDate = t("Prefill date", { _tags: "PDFConfiguration" });
    const t_on = t("on", { _tags: "PDFConfiguration" });
    const t_off = t("off", { _tags: "PDFConfiguration" });

    const tomorrowUnix = moment().add(1, "days").unix() * 1000;
    const currentUnix = moment(props.currentDate ?? 0).unix() * 1000;

    const [isEditing, setIsEditing] = useState(false);
    const [currentDate, setCurrentDate] = useState<Moment>(moment(currentUnix || tomorrowUnix));
    const displayDate = currentDate.format("DD.MM.YYYY");

    function handleEdit(event: React.MouseEvent) {
        if (!props.isActive) return;

        event.stopPropagation();
        setIsEditing(true);
    }

    function handleSave(event?: React.MouseEvent) {
        if (!props.isActive) return;

        if (event) {
            event.stopPropagation();
        }

        props.handleDateSelection(currentDate.unix() * 1000);
        setIsEditing(false);
    }

    function handleDiscard(event?: React.MouseEvent) {
        if (!props.isActive) return;

        if (event) {
            event.stopPropagation();
        }

        setCurrentDate(moment(currentUnix || tomorrowUnix));
        setIsEditing(false);
    }

    function handleDelete(e: React.MouseEvent) {
        if (!props.isActive) return;

        e.stopPropagation();

        props.handleDateSelection(0);
        setCurrentDate(moment(tomorrowUnix));
        setIsEditing(false);
    }

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSave();
        } else if (e.key === "Escape") {
            e.preventDefault();
            handleDiscard();
        }
    }

    const renderClickableIcons = () => {
        return (
            <ActionsWrapper>
                {isEditing ? (
                    <>
                        <StyledIcon
                            name={"exercise-ok"}
                            onMouseDown={handleSave}
                            disabled={!props.isActive}
                        />
                        <StyledIcon
                            name={"close"}
                            onMouseDown={handleDiscard}
                            disabled={!props.isActive}
                        />
                    </>
                ) : (
                    <>
                        <StyledIcon
                            name={"edit-avatar"}
                            onMouseDown={handleEdit}
                            disabled={!props.isActive}
                        />
                        {props.currentDate !== undefined && props.currentDate !== 0 && (
                            <StyledIcon
                                name={"trash"}
                                onMouseDown={handleDelete}
                                disabled={!props.isActive}
                            />
                        )}
                    </>
                )}
            </ActionsWrapper>
        );
    };

    const renderDateTimePicker = () => {
        return (
            <DateTimeWrapper onKeyDown={handleKeyDown}>
                <StyledKeyboardDateTimePicker
                    disabled={!props.isActive}
                    value={currentDate}
                    views={["year", "day"]}
                    onChange={(date: unknown) => {
                        if (!moment.isMoment(date)) return;
                        setCurrentDate(date);
                    }}
                    format="DD.MM.YYYY"
                    displayWeekNumber={true}
                />
            </DateTimeWrapper>
        );
    };

    const renderContent = () => {
        if (isEditing) {
            return renderDateTimePicker();
        } else {
            return (
                <DateText
                    isPlaceholder={props.currentDate === undefined || props.currentDate === 0}
                    disabled={!props.isActive}
                >
                    {props.currentDate ? displayDate : t_prefillDate}
                </DateText>
            );
        }
    };

    return (
        <PDFSettingControl
            variant={"expandable"}
            settingTitle={props.settingTitle}
            statusText={props.isActive ? (props.currentDate ? displayDate : t_on) : t_off}
        >
            <PDFSettingDetails
                checked={props.isActive}
                onChange={props.onToggle}
            >
                <Wrapper
                    onMouseDown={handleEdit}
                    isEditing={isEditing}
                    disabled={!props.isActive}
                    ref={inputRef}
                >
                    {renderContent()}
                    {renderClickableIcons()}
                </Wrapper>
            </PDFSettingDetails>
        </PDFSettingControl>
    );
};

export default PDFDateSettingControl;
