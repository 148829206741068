import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";

export const ExercisePointsToggleWrapper = styled.div`
    height: 24px; // added a fixed height to remove "default" margin from input field
    display: flex;
    align-items: center;
`;

export const ExercisePointsToggle = styled.input<{ disabled?: boolean }>`
    appearance: none;
    width: 38px;
    height: 22px;
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    position: relative;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
    outline: none;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
    transition: background-color 0.4s;

    &:checked {
        background-color: ${DesignConstants.COLORS.P6_ORANGE};
        border: ${DesignConstants.BORDERS.THIN} transparent;
    }

    &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 14px;
        height: 14px;
        border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
        border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
        transition: transform 0.4s;
    }

    &:checked::after {
        border: ${DesignConstants.BORDERS.THIN} transparent;
        background: ${DesignConstants.COLORS.WHITE};
        transform: translateX(16px);
    }
`;
