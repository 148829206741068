import { useT } from "@transifex/react";
import { debounce, isEqual, merge } from "lodash";
import {
    BVersion,
    Settings,
    SettingsSection,
    SettingsSubSection,
    PreviousUrlNavigationProps,
    TeacherTestDetails,
} from "p6m-p6u";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CloseButton from "../../../basic/closeButton/CloseButton";
import PDFDownloadButton from "../../../basic/pdfBasic/PDFDownloadButton/PDFDownloadButton";
import PDFPreview from "../../../basic/pdfBasic/PDFPreview/PDFPreview";
import SyncStatus from "../../../basic/syncStatus/SyncStatus";
import { settingsDefault } from "../../../config/settingsDefault";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { TestVersionName } from "../../../enums/settings";
import { createBVersion } from "../../../helpers/ABVersionHelper";
import { putRequest } from "../../../helpers/networkHelper";
import { handleMissingScores } from "../../../helpers/ScoringHelper";
import useTriggerOnNavigation from "../../../hooks/useTriggerOnNavigation";
import PDFGoodLuckSettings from "./pdfSettingsSections/pdfGoodLuckSettings/PDFGoodLuckSettings";
import PDFGradesSignaturesSettings from "./pdfSettingsSections/pdfGradesSignaturesSettings/PDFGradesSignaturesSettings";
import PDFHeaderSettings from "./pdfSettingsSections/pdfHeaderSettings/PDFHeaderSettings";
import PDFScoreSettings from "./pdfSettingsSections/pdfScoreSettings/PDFScoreSettings";
import PDFTitleSettings from "./pdfSettingsSections/pdfTitleSettings/PDFTitleSettings";
import { useMediaQuery } from "react-responsive";

import {
    ABSwitch,
    ABSwitchButton,
    ABVersionSwitchWrapper,
    ButtonContainer,
    ConfigSection,
    Container,
    Header,
    PDFConfigurationWrapper,
    PreviewSection,
    StatusAndCloseWrapper,
    Title,
} from "./StyledComponents";

export interface SettingsChangeProps<T extends SettingsSection, K extends SettingsSubSection<T>> {
    sectionTitle: T;
    settingTitle: K;
    setting: {
        key: keyof Settings[T][K];
        value: Settings[T][K][keyof Settings[T][K]];
    };
}

const PDFConfiguration = () => {
    const history = useHistory();
    const location = useLocation<PreviousUrlNavigationProps>();

    const {
        prepareFullTestDetails,
        testContent,
        resetAppStatus,
        setSettings,
        setShowScores,
        showScores,
        setTestContent,
        loadUserTests,
        // createPreviewData, // comment in while EDITING THE PDF
    } = useTestCreationContext();

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobile: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const [teacherTestDetails, setTeacherTestDetails] = useState<TeacherTestDetails | null>(null);
    const [isConfigSyncing, setIsConfigSyncing] = useState(false);
    const [isConfigSynced, setIsConfigSynced] = useState(true);

    const showABGroup = teacherTestDetails
        ? teacherTestDetails?.settings
            ? teacherTestDetails.settings.header.abGroup.show
            : settingsDefault.header.abGroup.show
        : false;

    const [showBVersion, setShowBVersion] = useState(true);
    const testVersionName = showABGroup && showBVersion ? TestVersionName.B : TestVersionName.A;
    const [bVersionTestDetails, setBVersionTestDetails] = useState<TeacherTestDetails | null>(null);

    const t = useT();

    const t_pdfConfiguration = t("PDF Configuration", { _tags: "PDFConfiguration" });
    const t_exercisePDF = t("Exercise Sheet", { _tags: "CreateTest,PDFCreation" });
    const t_close = t("Close PDF Configuration", { _tags: "PDFConfiguration" });
    const t_groupA = t("Group A (original)", { _tags: "PDFConfiguration" });
    const t_groupB = t("Group B", { _tags: "PDFConfiguration" });

    const t_test = t("Test", { _tags: "PDFConfiguration" });
    const t_testVersion = t("Test {version}", {
        version: testVersionName,
        _tags: "PDFConfiguration",
    });

    const t_solution = t("Solution", { _tags: "PDFConfiguration" });
    const t_solutionVersion = t("Solution {version}", {
        version: testVersionName,
        _tags: "PDFConfiguration",
    });

    const abVersionFileName = showABGroup ? `${testVersionName} ` : "";

    const availableButtons = [
        {
            text: showABGroup ? t_testVersion : t_test,
            isSolution: false,
            fileName: `${abVersionFileName}${teacherTestDetails?.name ?? t_exercisePDF} ${t_test}.pdf`,
            testDetails: showABGroup && showBVersion ? bVersionTestDetails : teacherTestDetails,
            secondary: false,
        },
        {
            text: showABGroup ? t_solutionVersion : t_solution,
            isSolution: true,
            fileName: `${abVersionFileName}${teacherTestDetails?.name ?? t_exercisePDF} ${t_solution}.pdf`,
            testDetails: showABGroup && showBVersion ? bVersionTestDetails : teacherTestDetails,
            secondary: true,
        },
    ];

    const handleUnmount = () => {
        resetAppStatus();
        loadUserTests(true);
    };

    useTriggerOnNavigation(handleUnmount, "/create");

    useEffect(() => {
        const getTestDetails = async () => {
            // comment out while EDITING THE PDF
            const currentTestDetails = { ...prepareFullTestDetails(testContent) };

            // comment in while EDITING THE PDF
            // let currentTestDetails = { ...prepareFullTestDetails(testContent) };
            // if (currentTestDetails && currentTestDetails.content && currentTestDetails.content.length > 0) {
            //     // save into localstorage for reload, overwrite if not present
            //     _saveTestInLocalStorage(currentTestDetails, createPreviewData);
            // } else {
            //     currentTestDetails = await _getCurrentTestFromLocalStorage();
            // }
            // / __________________________________________________________________________________________________

            if (!currentTestDetails || !currentTestDetails.content || currentTestDetails.content.length === 0) return;

            const mergedPdfDetails = merge({}, settingsDefault, currentTestDetails.settings); // uses an empty object to merge without references
            setTeacherTestDetails({ ...currentTestDetails, settings: mergedPdfDetails });
        };

        getTestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveTestInDatabase = async (
        currentTestDetails: TeacherTestDetails,
        updatedProperties: Partial<TeacherTestDetails>
    ) => {
        // teacherTestDetails needs to be passed as a prop because during re-render the useState gets reinitalised and teacherTestDetails would be undefined

        if (!currentTestDetails?.id) {
            // not available for anonymous users
            setIsConfigSyncing(false);
            setIsConfigSynced(true);
            return;
        }

        try {
            const testId = currentTestDetails.id;
            const combinedTestDetails = { ...currentTestDetails, ...updatedProperties };

            if (testId === combinedTestDetails.id) {
                await putRequest(`aea/tests/${testId}/`, combinedTestDetails); // saves non-shortened testDetails (with both answers and questions)
                setIsConfigSynced(true);
            }

            setIsConfigSyncing(false);
        } catch (error) {
            setIsConfigSyncing(false);
        }
    };

    const handleSave = async (
        currentTestDetails: TeacherTestDetails,
        updatedProperties: Partial<TeacherTestDetails>
    ) => {
        if (!updatedProperties) return;

        await saveTestInDatabase(currentTestDetails, updatedProperties);

        // comment in while EDITING THE PDF
        // _saveTestInLocalStorage(updatedProperties);
    };

    const saveSettingsDebounced = debounce(
        async (currentTestDetails: TeacherTestDetails, updatedProperties: Partial<TeacherTestDetails>) => {
            await handleSave(currentTestDetails, updatedProperties);
        },
        10000
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveSettingsMemorised = useMemo(() => saveSettingsDebounced, []); // prevents the debounce from being recreated on every render

    useEffect(() => {
        if (showABGroup) createBVersionFromA();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teacherTestDetails?.content, teacherTestDetails?.settings, teacherTestDetails?.showScores]);

    const createBVersionFromA = (overwrite?: boolean) => {
        const bVersion: BVersion = createBVersion(teacherTestDetails ?? {}, overwrite);

        setBVersionTestDetails(bVersion.bVersionTestDetails);

        if (
            bVersion.shorthand.length > 0 &&
            !isEqual(bVersion.shorthand, teacherTestDetails?.settings?.header.abGroup.bVersion)
        ) {
            handleSettingsChange({
                sectionTitle: "header",
                settingTitle: "abGroup",
                setting: { key: "bVersion", value: bVersion.shorthand },
            });
        }
    };

    const handleSettingsChange = <T extends SettingsSection, K extends SettingsSubSection<T>>({
        sectionTitle,
        settingTitle,
        setting,
    }: SettingsChangeProps<T, K>) => {
        if (!teacherTestDetails?.settings) return;

        const copiedTeacherTestDetails = { ...teacherTestDetails };

        const updatedPdfDetails = {
            ...(copiedTeacherTestDetails.settings as Settings),
            [sectionTitle]: {
                ...(copiedTeacherTestDetails.settings?.[sectionTitle] ?? {}),
                [settingTitle]: {
                    ...(copiedTeacherTestDetails.settings?.[sectionTitle]?.[settingTitle] ?? {}),
                    [setting.key]: setting.value,
                },
            },
        };

        copiedTeacherTestDetails.settings = updatedPdfDetails;
        setTeacherTestDetails(copiedTeacherTestDetails);
        setIsConfigSyncing(true);
        setIsConfigSynced(false);
        saveSettingsMemorised(teacherTestDetails, { settings: copiedTeacherTestDetails.settings });

        setSettings(updatedPdfDetails);
    };

    const handleClose = async () => {
        if (isConfigSyncing) {
            saveSettingsMemorised.cancel();

            if (teacherTestDetails?.settings) {
                await handleSave(teacherTestDetails, {
                    settings: teacherTestDetails.settings,
                    showScores: teacherTestDetails.showScores,
                });
            }
        }

        history.replace(location?.state?.urlBeforeConfig || "/create", {
            urlBeforePreview: location?.state?.urlBeforePreview || "/create",
        });
    };

    const fixPDFScore = (): TeacherTestDetails => {
        let updatedTeacherTest = { ...teacherTestDetails };

        if (!updatedTeacherTest.showScores) {
            const newExercisesWithScores = handleMissingScores([...(updatedTeacherTest.content ?? [])]);

            updatedTeacherTest = {
                ...updatedTeacherTest,
                content: newExercisesWithScores ? newExercisesWithScores ?? [] : updatedTeacherTest.content,
            };
        }

        return updatedTeacherTest;
    };

    const onToggleScore = async () => {
        let updatedTeacherTest = fixPDFScore();

        updatedTeacherTest = {
            ...updatedTeacherTest,
            showScores: !updatedTeacherTest.showScores,
        };

        setTeacherTestDetails(updatedTeacherTest);
        setIsConfigSyncing(true);
        setIsConfigSynced(false);

        saveSettingsMemorised(updatedTeacherTest, {
            showScores: updatedTeacherTest.showScores,
            content: updatedTeacherTest.content,
        });

        setShowScores(updatedTeacherTest.showScores ?? showScores);
        setTestContent(updatedTeacherTest.content ?? testContent);
    };

    const renderABSwitch = () => {
        return (
            <ABVersionSwitchWrapper>
                <ABSwitch
                    onClick={() => {
                        setShowBVersion(!showBVersion);
                    }}
                >
                    <ABSwitchButton isActive={!showBVersion}>{t_groupA}</ABSwitchButton>
                    <ABSwitchButton isActive={showBVersion}>{t_groupB}</ABSwitchButton>
                </ABSwitch>
            </ABVersionSwitchWrapper>
        );
    };

    return (
        <PDFConfigurationWrapper>
            <StatusAndCloseWrapper>
                <SyncStatus
                    isSyncing={isConfigSyncing}
                    isSynced={isConfigSynced}
                    isAbsolutePosition
                />
                {isMobile && <Title>{t_pdfConfiguration}</Title>}
                <CloseButton
                    onClick={handleClose}
                    hintText={t_close}
                />
            </StatusAndCloseWrapper>
            <Header>
                {!isMobile && <Title>{t_pdfConfiguration}</Title>}
                <ButtonContainer>
                    {availableButtons.map((button, buttonIndex) => (
                        <PDFDownloadButton
                            key={buttonIndex}
                            buttonText={button.text}
                            isSolution={button.isSolution}
                            fileName={button.fileName}
                            testDetails={button.testDetails ?? {}}
                            secondary={button.secondary}
                        />
                    ))}
                </ButtonContainer>
            </Header>
            {teacherTestDetails && (
                <Container>
                    <PreviewSection>
                        {showABGroup && renderABSwitch()}
                        <PDFPreview
                            testDetails={
                                showABGroup && showBVersion && bVersionTestDetails
                                    ? bVersionTestDetails ?? {}
                                    : teacherTestDetails ?? {}
                            }
                        />
                    </PreviewSection>
                    <ConfigSection>
                        <PDFHeaderSettings
                            teacherTestDetails={teacherTestDetails}
                            handleSettingsChange={handleSettingsChange}
                            createBVersionFromA={createBVersionFromA}
                            setShowBVersion={setShowBVersion}
                        />
                        <PDFTitleSettings
                            teacherTestDetails={teacherTestDetails}
                            handleSettingsChange={handleSettingsChange}
                        />
                        <PDFScoreSettings
                            teacherTestDetails={teacherTestDetails}
                            handleSettingsChange={handleSettingsChange}
                            onToggleScore={onToggleScore}
                        />
                        <PDFGradesSignaturesSettings
                            teacherTestDetails={teacherTestDetails}
                            handleSettingsChange={handleSettingsChange}
                        />
                        <PDFGoodLuckSettings
                            teacherTestDetails={teacherTestDetails}
                            handleSettingsChange={handleSettingsChange}
                        />
                    </ConfigSection>
                </Container>
            )}
        </PDFConfigurationWrapper>
    );
};

export default PDFConfiguration;

/* comment in while EDITING THE PDF _____________________________________________________________________
 -> this will enable reloading the page by saving the data into the localstorage
 -> search for "EDITING THE PDF" to find all places in this file

 PLEASE NOTE: when this page is reloaded, the close button can break due to missing history / location
*/

// const _getCurrentTestFromLocalStorage = () => {
//     const storedTestDetails = localStorage.getItem("localFullPreviewData");

//     if (storedTestDetails) {
//         const parsedTestDetails = JSON.parse(storedTestDetails);
//         return parsedTestDetails;
//     }

//     return null;
// };

// const _updateLocalStorage = (
//     storageKey: string,
//     updatedProperties: Partial<TeacherTestDetails>,
//     overwriteData?: TeacherTestDetails
// ) => {
//     try {
//         const existingData = localStorage.getItem(storageKey);
//         if (existingData || overwriteData) {
//             console.log("saving into localstorage");
//             const existingTestDetails = overwriteData ?? JSON.parse(existingData ?? "");
//             const updatedData = {
//                 ...existingTestDetails,
//                 ...updatedProperties,
//             };
//             localStorage.setItem(storageKey, JSON.stringify(updatedData));
//         }
//     } catch (error) {
//         console.log("→ [LOG] Error updating local storage", error);
//     }
// };

// const _saveTestInLocalStorage = (
//     updatedProperties: Partial<TeacherTestDetails>,
//     createPreviewData?: () => StudentTestContent
// ) => {
//     _updateLocalStorage("localPreviewData", updatedProperties, createPreviewData ? updatedProperties : undefined);

//     const overwritePreviewData = createPreviewData ? createPreviewData() : undefined;
//     _updateLocalStorage("localFullPreviewData", updatedProperties, overwritePreviewData);
// };

// _____________________________________________________________________________________________________
