import { useT } from "@transifex/react/dist";
import { debounce } from "lodash";
import { StudentQuestionAnswer } from "p6m-p6u";
import React, { useEffect, useRef, useState } from "react";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

import {
    BiggerTextArea,
    CreateTextExerciseWrapper,
    ExerciseQuestionWordsContainer,
    QuestionWordSpan,
    TextFieldStarterTextWrapper,
} from "./StyledComponents";

export interface CreateTextExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
    isMobile: boolean;
}

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// CreateText was called JumbledWords in legacy
// please clean up if possible (likely affects data structures) when properly touching this file
const CreateTextExercise: React.FC<CreateTextExerciseProps> = (props) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "jumbledWordsExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answerContent, setAnswersContent] = useState(studentAnswerItem);
    const [isEditionMode, setIsEditionMode] = useState<boolean>(false);

    const updateAnswer = function (value: string) {
        const newAnswers = { ...answerContent };
        newAnswers.jumbledWordsAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            {
                ...answerContent,
            },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answerContent]);

    const textRef = useRef<HTMLTextAreaElement>(null);

    const renderWordBox = () => {
        if (!studentAnswerItem.jumbledWords) return;

        const jumbledWords = studentAnswerItem.jumbledWords.map((w) => {
            return (
                <QuestionWordSpan
                    isMobile={props.isMobile}
                    key={"key_q_" + w.replace(" ", "")}
                >
                    {w}
                </QuestionWordSpan>
            );
        });

        return (
            <ExerciseQuestionWordsContainer isMobile={props.isMobile}>{jumbledWords}</ExerciseQuestionWordsContainer>
        );
    };

    return (
        <CreateTextExerciseWrapper>
            {renderWordBox()}
            {studentAnswerItem.textFieldStarterText && (
                <TextFieldStarterTextWrapper>{studentAnswerItem.textFieldStarterText}</TextFieldStarterTextWrapper>
            )}
            <BiggerTextArea
                onChange={(event) => {
                    updateAnswer(event.target.value);
                }}
                type="text"
                placeholder={t_placeholder}
                ref={textRef}
                value={answerContent.jumbledWordsAnswer}
                onFocus={() => setIsEditionMode(true)}
                onBlur={() => setIsEditionMode(false)}
            />
            {props.isMobile && isAndroid && isEditionMode && <InputControlArea />}
        </CreateTextExerciseWrapper>
    );
};

export default CreateTextExercise;
