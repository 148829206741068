import styled from "styled-components";
import ExerciseTextArea from "../../../basic/exerciseTextArea/ExerciseTextArea";

export const CreateTextExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
`;

export const ExerciseQuestionWordsContainer = styled.div<{ isMobile: boolean }>`
    border-radius: 5px;
    border: solid 1px #cccccc;
    background: #f6f6f6;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 80%;
    min-height: 3rem;
    padding: 0.5rem;
    margin-bottom: 2rem;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        padding: 0.3rem;
        margin-bottom: 1rem;`};
`;

export const QuestionWordSpan = styled.span<{ isMobile: boolean }>`
    margin: 1rem;

    ${({ isMobile }) => isMobile && `margin: 5px;`};
`;

export const BiggerTextArea = styled(ExerciseTextArea)`
    > textarea {
        height: 150px;
    }
`;

export const TextFieldStarterTextWrapper = styled.div`
    width: 100%;
    margin-top: 1rem;
`;
