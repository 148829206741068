import styled, { css } from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const ConnectWordsExerciseWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding-top: ${DesignConstants.SPACES.S};
    margin-bottom: ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.S};

    ${({ isMobile }) => isMobile && `gap: ${DesignConstants.SPACES.XS};`};
`;

const basicColumnCss = css<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${({ isMobile }) =>
        isMobile &&
        `padding: ${DesignConstants.SPACES.XXS};
        width: 50%;`};
`;

export const ConnectWordsLeftWrapper = styled.div<{ isMobile: boolean }>`
    gap: ${DesignConstants.SPACES.S};
    ${basicColumnCss};
`;

export const ConnectWordsRightWrapper = styled.div<{ isMobile: boolean }>`
    gap: ${DesignConstants.SPACES.S};
    ${basicColumnCss};
`;
