import styled from "styled-components";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import FlagIcon from "../../../basic/flagIcon/FlagIcon";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
`;

export const InfoHeader = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;

    ${({ isMobile }) =>
        isMobile &&
        `align-items: unset;
        flex-direction: row-reverse;
        & > div:nth-child(2) {
            margin: 0;
        }
        `};
`;

export const TestConditionsWrapper = styled(MainBackgroundSquircle)<{ isMobile: boolean }>`
    width: 80%;
    padding: 2rem;
    overflow: auto;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        padding: 2rem;
        `};
`;

export const TestConditionsTeacherDescWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 3;
    text-align: center;
    margin-bottom: 1rem;

    ${({ isMobile }) =>
        isMobile &&
        `padding: 0 1rem;
        p {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
        `};
`;

export const StartWrapper = styled.div<{ isMobile: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;

    > button {
        width: 80%;
        height: 60px;

        ${({ isMobile }) => isMobile && `width: 100%;`};
    }
`;

export const TeacherNameWrapper = styled.div<{ isMobile: boolean }>`
    text-align: center;
    flex: 6;
    margin: 0 0 1rem 0;

    ${({ isMobile }) =>
        isMobile &&
        `display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;`};
`;

export const QuestionIconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`;

export const TestConditionsInfoWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: space-evenly;
    align-items: normal;
    width: 100%;
    text-align: center;
    flex: 0;

    ${({ isMobile }) => isMobile && `padding: 2rem 0 0 0;`};
`;

export const InputModalTitleWrapper = styled.div``;

export const InputModalTestTime = styled.div`
    color: ${(props) => props.theme.base.primaryColor};
    font-size: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    > svg {
        width: 2rem;
    }

    > p {
        font-size: 1.1rem;
        font-weight: bold;
        margin: 0 0.25rem;
    }
`;

export const TestConditionItemWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: center;

    ${({ isMobile }) => isMobile && `padding: 0 1rem;`};

    &.orange {
        color: ${(props) => props.theme.base.primaryColor};
    }
`;

export const TestConditionItemSeparator = styled.div`
    width: 1px;
    border-left: solid 1px #ccc;
    height: 100%;
`;

export const NewTestTitle = styled.span<{ isMobile: boolean }>`
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1rem;

    ${({ isMobile }) =>
        isMobile &&
        `font-weight: bold;
        margin: 0;
        font-size: 1.2rem;`};
`;

export const TeacherName = styled.span<{ isMobile: boolean }>`
    font-size: 1.2rem;
    font-weight: bold;

    ${({ isMobile }) =>
        isMobile &&
        `font-size: 1.2rem;
        font-weight: bold;`};
`;

export const StyledInput = styled.input<{ isMobile: boolean }>`
    height: 60px;
    width: 80%;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: inset 0 5px 0 rgb(0 0 0 / 10%);
    border: none;
    text-align: center;
    font-size: 1.6rem;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        margin-bottom: 0;`};
`;

export const TestConditionIcon = styled(SvgIcon)<{ isMobile: boolean }>`
    width: 2.5rem;
    margin-bottom: 0.3rem;

    ${({ isMobile }) => isMobile && `width: 2.5rem;`};
`;

export const TestConditionIconTitle = styled.p<{ isMobile: boolean }>`
    font-size: 1.1rem;
    margin: 0 0 3px 0;

    ${({ isMobile }) => isMobile && `display: none;`};
`;

export const TestConditionIconDesc = styled.p`
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 3px 0;
`;

export const TestConditionsTitle = styled.p`
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    display: none;
`;

export const TestConditionsGoodLuck = styled.p`
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    display: none;
`;

export const TeacherComment = styled.p`
    text-align: center;
    font-size: 1.3rem;
    margin: 0;
`;

export const StyledFlagIcon = styled(FlagIcon)``;

export const HelpIconComponent = styled(SvgIcon)`
    height: 2rem;
    cursor: pointer;
`;

export const AppInfoSubtitle = styled.p<{ isMobile: boolean }>`
    text-align: center;
    font-size: 1.1rem;

    ${({ isMobile }) =>
        isMobile &&
        `color: inherit;
        font-size: 1.3rem;`};
`;

export const DatePassedText = styled.p`
    text-align: center;
    width: 80%;
    font-size: 2rem;
`;

export const TestDescriptionWrapper = styled.div<{ isMobile: boolean }>`
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isMobile }) => isMobile && `flex: 2;`};
`;

export const TestDescriptionInner = styled.div``;
export const DataDeletionNote = styled.p`
    color: #666;
`;
