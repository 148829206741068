import { useT } from "@transifex/react";
import PDFSettingControl from "../../PDFSettingControl";
import PDFSettingDetails from "../../pdfSettingDetails/PDFSettingDetails";

import { ReshuffleButton, ShuffleButtonWrapper } from "./StyledComponents";

interface PDFABGroupSettingControlProps {
    settingTitle: string;
    isActive: boolean;
    onToggle: () => void;
    onShuffleBVersion: () => void;
}

const PDFABGroupSettingControl: React.FC<PDFABGroupSettingControlProps> = (props) => {
    const t = useT();

    const t_abGroup = t("A / B Group", { _tags: "PDFConfiguration" });
    const t_on = t("on", { _tags: "PDFConfiguration" });
    const t_off = t("off", { _tags: "PDFConfiguration" });
    const t_reshuffleB = t("Reshuffle B", { _tags: "PDFConfiguration" });

    return (
        <PDFSettingControl
            variant={"expandable"}
            settingTitle={t_abGroup}
            statusText={props.isActive ? t_on : t_off}
        >
            <PDFSettingDetails
                checked={props.isActive}
                onChange={props.onToggle}
            >
                <ShuffleButtonWrapper>
                    <ReshuffleButton
                        icon={"arrow-both"}
                        iconPosition={"LEFT"}
                        buttonStyle={"LIGHT_BORDER"}
                        disabled={!props.isActive}
                        onClick={props.onShuffleBVersion}
                    >
                        {t_reshuffleB}
                    </ReshuffleButton>
                </ShuffleButtonWrapper>
            </PDFSettingDetails>
        </PDFSettingControl>
    );
};

export default PDFABGroupSettingControl;
