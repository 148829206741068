import { useT } from "@transifex/react";
import ToggleSwitch from "../../../../../basic/toggleSwitch/ToggleSwitch";

import { PDFSettingDetailsWrapper, ToggleContainer, ToggleStatus } from "./StyledComponents";

interface PDFSettingDetailsProps {
    checked: boolean;
    onChange: () => void;
    children: React.ReactNode;
    statusText?: string;
    disabled?: boolean;
}

const PDFSettingDetails = (props: PDFSettingDetailsProps) => {
    const t = useT();

    const t_show = t("show", { _tags: "PDFConfiguration" });

    return (
        <PDFSettingDetailsWrapper>
            {props.children}
            <ToggleContainer>
                <ToggleStatus disabled={props.disabled}>{props.statusText ? props.statusText : t_show}</ToggleStatus>
                <ToggleSwitch
                    checked={props.checked}
                    onChange={props.onChange}
                    disabled={props.disabled}
                />
            </ToggleContainer>
        </PDFSettingDetailsWrapper>
    );
};

export default PDFSettingDetails;
