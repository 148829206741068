import { useT } from "@transifex/react/dist";
import { debounce, replace } from "lodash";
import { StudentQuestionAnswer } from "p6m-p6u";
import React, { useEffect, useRef, useState } from "react";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

import {
    ExerciseQuestionWordsContainer,
    ExerciseRowContainer,
    FillInTheGapExerciseWrapper,
    InlineQuestionSpan,
    QuestionWordSpan,
    StyledInlineInput,
} from "./StyledComponents";

export interface FillInTheGapExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
    isMobile: boolean;
}

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        // console.log("debouncing");
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const FillInTheGapExercise = (props: FillInTheGapExerciseProps) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "fillInTheGapExercise,inputPlaceholder" });

    const [answersContent, setAnswersContent] = useState(
        props.studentAnswerItem.studentAnswers ? props.studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const updateItemAtPos = function (pos: number, value: string) {
        const newAnswers = [...answersContent];
        newAnswers[pos].studentAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            { ...props.studentAnswerItem, studentAnswers: answersContent },
            props.updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(props.studentAnswerItem.studentAnswers!.length));

    const checkFocus = function (pos: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            if (pos + 1 === refs.current.length) {
                refs.current[pos]?.blur();
            } else {
                refs.current[pos + 1]?.focus();
            }
        }
    };

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };
    const focusExerciseAtIndex = function (index: number) {
        // console.log('focusing at index', index)
        if (index < props.studentAnswerItem.studentAnswers!.length) {
            refs.current[index]?.focus();
        }
    };

    const renderGapSentences = () => {
        if (!props.studentAnswerItem.studentAnswers) return;

        let exercises = [];
        for (let i = 0; i < props.studentAnswerItem.studentAnswers.length; i++) {
            let item = props.studentAnswerItem.studentAnswers[i];

            if (item.gap_sentence) {
                let questionText = item.gap_sentence;
                questionText = replace(questionText, /_+/g, "^");
                // This should split the phrase in two, one before the gap, the other one after.
                let questionTextArray = questionText.split("^");
                let gapLength = item.gap_sentence.length - questionText.length;

                exercises.push(
                    <ExerciseRowContainer
                        key={"key_" + item.id}
                        isMobile={props.isMobile}
                    >
                        <InlineQuestionSpan>
                            {questionTextArray[0]}

                            <StyledInlineInput
                                key={i}
                                onChange={(event) => {
                                    updateItemAtPos(i, event.target.value);
                                }}
                                onKeyUp={(event) => checkFocus(i, event)}
                                type="text"
                                placeholder={t_placeholder}
                                ref={(element: any) => (refs.current[i] = element)}
                                value={item.studentAnswer}
                                onFocus={() => handleFocus(i)}
                                className={`${gapLength > 12 * 2 ? "l" : gapLength > 8 * 2 ? "m" : "s"}`}
                            />

                            {!!questionTextArray[1] ? <span>{questionTextArray[1]}</span> : null}
                        </InlineQuestionSpan>
                    </ExerciseRowContainer>
                );
            }
        }

        return exercises;
    };

    const renderGapWords = () => {
        if (!props.studentAnswerItem.fillInTheGapWords) return;

        return (
            <ExerciseQuestionWordsContainer isMobile={props.isMobile}>
                {props.studentAnswerItem.fillInTheGapWords.map((w) => {
                    return (
                        <QuestionWordSpan
                            isMobile={props.isMobile}
                            key={"key_q_" + w.replace(" ", "")}
                        >
                            {w}
                        </QuestionWordSpan>
                    );
                })}
            </ExerciseQuestionWordsContainer>
        );
    };

    return (
        <FillInTheGapExerciseWrapper>
            {renderGapWords()}
            {props.studentAnswerItem.studentAnswers && renderGapSentences()}
            {props.isMobile && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={props.studentAnswerItem.studentAnswers?.length}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </FillInTheGapExerciseWrapper>
    );
};

export default FillInTheGapExercise;
