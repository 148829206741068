import { Text, View, StyleSheet } from "@react-pdf/renderer";
import DesignConstants from "../../../constants/DesignConstants";
import { Settings } from "p6m-p6u";

interface PDFTitleProps {
    title: string;
    settings: Settings;
    pdfUnitNames?: string;
}

const PDFTitle = (props: PDFTitleProps) => {
    const showTestTitle = props.settings?.titleAndTopics?.title?.show;
    const showTopics = props.settings?.titleAndTopics?.vocabularyTopics?.show;

    if (!showTestTitle) return null; // topics will be shown in PDFHeader instead (if title hidden and topics shown)

    return (
        <View style={styles.headingContainer}>
            {showTestTitle && <Text style={styles.testTitle}>{props.title}</Text>}
            {showTopics && props.pdfUnitNames && <Text style={styles.unitsTitle}>{props.pdfUnitNames}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
    headingContainer: {
        alignItems: "center",
        marginBottom: DesignConstants.PDF.GAP_M,
        gap: DesignConstants.PDF.GAP_XXS,
    },
    testTitle: {
        fontSize: DesignConstants.PDF.FONTSIZE_PAGE_HEADER,
        fontWeight: DesignConstants.PDF.SEMIBOLD,
        textAlign: "center",
    },
    unitsTitle: {
        fontSize: DesignConstants.PDF.FONTSIZE_EXERCISE_HEADER,
        color: DesignConstants.COLORS.DARK_GREY,
        whiteSpace: "nowrap",
    },
});

export default PDFTitle;
