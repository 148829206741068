import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Runs a given function on navigating away to a given target without calling it on the first render
 * @param onNavigateAway the function to call on navigation to the path
 * @param cleanUpPath the path to trigger the function
 */
const useTriggerOnNavigation = (onNavigateAway: () => void, cleanUpPath: string) => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            if (newLocation.pathname === cleanUpPath) {
                onNavigateAway();
            }
        });

        return () => unlisten();
    }, [history, onNavigateAway, cleanUpPath]);
};

export default useTriggerOnNavigation;
