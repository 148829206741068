export enum ExerciseDirections {
    QUESTION_TO_ANSWER,
    ANSWER_TO_QUESTION,
    RANDOM,
    PERSONALIZED,
}

export enum EditScoreDirection {
    INCREMENT = "INCREMENT",
    DECREMENT = "DECREMENT",
}
