import { View } from "@react-pdf/renderer";
import React, { ReactNode } from "react";
import DesignConstants from "../../../constants/DesignConstants";
import PDFExerciseTitle from "./PDFExerciseTitle";
import PDFExerciseStarterText from "./PDFExerciseStarterText";
import { ExerciseTypes, Exercises } from "../../../enums/exercises";

interface PDFExerciseWrapperProps {
    exerciseTask: string;
    exerciseNumber: number;
    starterText?: string;
    questionMode?: string;
    wrapIsEnabled: boolean;
    children?: ReactNode;
    exerciseScore: number;
    showExerciseScore: boolean;
}

const PDFExerciseWrapper: React.FC<PDFExerciseWrapperProps> = (props: PDFExerciseWrapperProps) => {
    return (
        <View wrap={props.wrapIsEnabled}>
            <View
                wrap={false}
                minPresenceAhead={DesignConstants.PDF.MIN_PRESENCE_AHEAD}
            >
                <PDFExerciseTitle
                    exerciseNumber={props.exerciseNumber}
                    exerciseTask={props.exerciseTask}
                    smallerMarginBottom={
                        props.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT) && !props.starterText
                    }
                    exerciseScore={props.exerciseScore}
                    showExerciseScore={props.showExerciseScore}
                />
                {props.starterText && (
                    <PDFExerciseStarterText
                        starterText={props.starterText}
                        smallerMarginBottom={props.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT)}
                    />
                )}
            </View>
            {props.children}
        </View>
    );
};

export default PDFExerciseWrapper;
