import DesignConstants from "../../constants/DesignConstants";
import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const CancelButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    gap: ${DesignConstants.SPACES.XXS};
`;

export const CancelTextWrapper = styled.div`
    display: flex;
    position: absolute;
    right: ${DesignConstants.FONT_SIZES.XXL};
    font-size: ${DesignConstants.FONT_SIZES.L};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    padding: ${DesignConstants.SPACES.XS};
    white-space: nowrap;
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    height: ${DesignConstants.FONT_SIZES.XXL};
    cursor: pointer;
`;

export const StyledHeaderActionsIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    color: ${DesignConstants.COLORS.MID_GREY};
`;
