import blackCat from "../assets/img/pdf/configuration/BlackCat.png";
import happyCorgi from "../assets/img/pdf/configuration/HappyCorgi.png";
import raccoon from "../assets/img/pdf/configuration/Raccoon.png";
import sidneyClover from "../assets/img/pdf/configuration/SidneyClover.png";
import sidneyHearts from "../assets/img/pdf/configuration/SidneyHearts.png";
import sidneyThinking from "../assets/img/pdf/configuration/SidneyThinking.png";
import sidneyThumbsUp from "../assets/img/pdf/configuration/SidneyThumbsUp.png";
import type { GoodLuckImage } from "p6m-p6u";
import { GoodLuckNames } from "../enums/settings";

export const goodLuckImages: GoodLuckImage[] = [
    {
        id: "1",
        name: GoodLuckNames.SIDNEY_THUMBS_UP,
        imageUrl: sidneyThumbsUp,
    },
    {
        id: "2",
        name: GoodLuckNames.SIDNEY_CLOVER,
        imageUrl: sidneyClover,
    },
    {
        id: "3",
        name: GoodLuckNames.SIDNEY_HEARTS,
        imageUrl: sidneyHearts,
    },
    {
        id: "4",
        name: GoodLuckNames.SIDNEY_THINKING,
        imageUrl: sidneyThinking,
    },
    {
        id: "5",
        name: GoodLuckNames.HAPPY_CORGI,
        imageUrl: happyCorgi,
    },
    {
        id: "6",
        name: GoodLuckNames.BLACK_CAT,
        imageUrl: blackCat,
    },
    {
        id: "7",
        name: GoodLuckNames.RACCOON,
        imageUrl: raccoon,
    },
];
