import styled from "styled-components/macro";
import DesignConstants from "../../../constants/DesignConstants";
import { SwitchItemPosition } from "../../../enums/positions";
import { PreviewView } from "../../../enums/views";

export const Wrapper = styled.div``;

export const TestPreviewHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: ${DesignConstants.SPACES.S} 0;
    padding: ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.S};
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
`;

export const MiddleContentWrapper = styled.div<{ isPDF?: boolean }>`
    flex: 1;
    width: 100%;
    background: ${(props) => (props.isPDF ? "" : DesignConstants.COLORS.LIGHTEST_GREY)};
    border: ${(props) => (props.isPDF ? "" : DesignConstants.BORDERS.THIN_GREY)};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const PreviewModeSwitchWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
`;

export const PreviewModeSwitchItem = styled.div<{
    currentMode: string;
    mode: string;
    position: SwitchItemPosition;
}>`
    height: 3rem;
    width: 8rem;
    background: white;
    font-size: ${DesignConstants.FONT_SIZES.L};
    display: flex;
    align-items: center;
    cursor: pointer;

    ${({ currentMode, mode, position }) => {
        if (currentMode === mode) {
            return ` background: ${DesignConstants.COLORS.P6_ORANGE}; color: white; `;
        }

        if (currentMode === PreviewView.MOBILE && position === SwitchItemPosition.RIGHT) {
            return `border-left: ${DesignConstants.BORDERS.THIN_GREY};`;
        }

        if (currentMode === PreviewView.PDF && position === SwitchItemPosition.LEFT) {
            return `border-right: ${DesignConstants.BORDERS.THIN_GREY};`;
        }

        return "";
    }}

    &:first-child {
        border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER} 0 0
            ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
        justify-content: center;
    }

    &:nth-child(2) {
        justify-content: center;
    }

    &:last-child {
        border-radius: 0 ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER}
            ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER} 0;
        justify-content: center;
        border-right: none;
    }
`;

export const PreviewModeSwitchText = styled.p`
    color: inherit;
`;

export const TestPreviewInfo = styled.div`
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.L};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    text-align: center;
    font-size: ${DesignConstants.FONT_SIZES.L};
    margin: ${DesignConstants.SPACES.XS} auto;
`;

export const PDFPreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const PDFDocumentWrapper = styled.div`
    height: 700px;
`;

export const PDFDownloadButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${DesignConstants.SPACES.M};
`;

export const IframeWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};

    &.${PreviewView.MOBILE} {
        height: 640px;
        width: 360px;
    }

    &.${PreviewView.DESKTOP} {
        height: 80vh;
    }
`;
