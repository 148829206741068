import { StyleSheet, View } from "@react-pdf/renderer";
import { GoodLuckSettings, TestDetailsItem } from "p6m-p6u";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";
import { ExerciseDirections } from "../../../enums/directions";
import { sanitizePDFText } from "../../../helpers/PDFHelper";
import { PDFGoodLuckImageWrapper } from "../pdfGoodLuckImage/PDFGoodLuckImageWrapper";
import PDFGapSentences from "../pdfSubComponents/PDFGapSentences";
import PDFWordBox from "../pdfSubComponents/PDFWordBox";

interface PDFGapSentenceExerciseProps {
    exercise: TestDetailsItem;
    goodLuckSettings: GoodLuckSettings;
    isLastExercise: boolean;
    isSolution?: boolean;
}

const PDFGapSentenceExercise: React.FC<PDFGapSentenceExerciseProps> = (props) => {
    const selectedLearningWords =
        props.exercise.selectedWords?.map((w) =>
            "id" in w && w.id
                ? sanitizePDFText(
                      props.exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION ? w.answer : w.question
                  )
                : ""
        ) ?? [];

    return (
        <View style={styles.pdfGapSentenceContainer}>
            <PDFWordBox words={selectedLearningWords} />
            <PDFGoodLuckImageWrapper
                isLastElement={props.isLastExercise}
                goodLuckSettings={props.goodLuckSettings}
                isSolution={props.isSolution}
            >
                <PDFGapSentences
                    selectedWords={props.exercise.selectedWords ?? []}
                    isSolution={props.isSolution}
                />
            </PDFGoodLuckImageWrapper>
        </View>
    );
};

const styles = StyleSheet.create({
    pdfGapSentenceContainer: {
        display: "flex",
        flexDirection: "column",
        gap: DesignConstants.PDF.GAP_XS,
    },
});

export default PDFGapSentenceExercise;
