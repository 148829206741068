import styled from "styled-components";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../constants/DesignConstants";

export const SettingRow = styled.div`
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: ${DesignConstants.SPACES.XS};
        right: ${DesignConstants.SPACES.XS};
        height: 1px;
        background-color: ${DesignConstants.COLORS.LIGHTEST_GREY};
    }

    &:last-child::after {
        display: none;
    }
`;

export const SettingHeader = styled.div<{ isExpanded?: boolean; disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    padding: ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.XS};
    ${({ isExpanded }) => isExpanded && `padding-bottom: ${DesignConstants.SPACES.XS}`};
`;

export const SettingTitle = styled.div<{ disabled?: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.M};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const ExpandableIndicator = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

// can be imported into other components for custom status
export const SettingStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
`;

// can be imported into other components for custom status
export const CustomStatusStyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${DesignConstants.COLORS.MID_GREY};
`;

// can be imported into other components for custom status
export const SettingsStatusText = styled.p`
    color: ${DesignConstants.COLORS.MID_GREY};
    font-size: ${DesignConstants.FONT_SIZES.M};
    line-height: 1;
`;

export const StyledChevronIcon = styled(PhaseSixIcon)<{ disabled?: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.L};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ExpandedContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${DesignConstants.SPACES.S};
    padding-top: 0;
`;
