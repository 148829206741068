import { useT } from "@transifex/react";
import PDFCollapsible from "../../pdfCollapsible/PDFCollapsible";
import PDFSettingControl from "../../pdfSettingControl/PDFSettingControl";
import { SettingsChangeProps } from "../../PDFConfiguration";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";
import usePDFUnits from "../../../../../hooks/usePDFUnits";

interface PDFTitleSettingsProps {
    teacherTestDetails: TeacherTestDetails;
    handleSettingsChange: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        settingsChangeProps: SettingsChangeProps<T, K>
    ) => void;
}

const PDFTitleSettings = (props: PDFTitleSettingsProps) => {
    const { pdfUnits } = usePDFUnits(props.teacherTestDetails);

    const t = useT();

    const t_header = t("Title and Topics", { _tags: "PDFConfiguration" });
    const t_showTitle = t("Title", { _tags: "PDFConfiguration" });
    const t_showVocabularyTopics = t("Vocabulary Topics", { _tags: "PDFConfiguration" });

    const settings = props.teacherTestDetails?.settings;
    const showTitle = !!settings?.titleAndTopics?.title?.show;
    const showVocabularyTopics = !!settings?.titleAndTopics?.vocabularyTopics?.show;

    return (
        <PDFCollapsible title={t_header}>
            <PDFSettingControl
                variant="toggle"
                settingTitle={t_showTitle}
                checked={showTitle}
                onChange={() => {
                    props.handleSettingsChange({
                        sectionTitle: "titleAndTopics",
                        settingTitle: "title",
                        setting: { key: "show", value: !showTitle },
                    });
                }}
            />
            <PDFSettingControl
                variant="toggle"
                settingTitle={t_showVocabularyTopics}
                checked={showVocabularyTopics}
                onChange={() => {
                    props.handleSettingsChange({
                        sectionTitle: "titleAndTopics",
                        settingTitle: "vocabularyTopics",
                        setting: {
                            key: "show",
                            value: !showVocabularyTopics,
                        },
                    });
                }}
                disabled={pdfUnits.length === 0}
            />
        </PDFCollapsible>
    );
};

export default PDFTitleSettings;
