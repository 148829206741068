export enum PreviewView {
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE",
    PDF = "PDF",
}

export enum Views {
    INTRO,
    PRACTICE,
    OUTRO,
    UNAUTHORIZED,
}

export enum PracticeViews {
    PRE_EXERCISES_OVERVIEW,
    EXERCISES,
    OVERVIEW,
}
