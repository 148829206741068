// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import { createNewExercise, createUpdatedDrawerContent, createUpdatedTestContent } from "../../../helpers/TestCreation";

import { debounce } from "lodash";
import { TestQuestionModel } from "p6m-p6u";
import { Exercises, ExerciseTypes } from "../../../enums/exercises";
import { SummaryOpenModals } from "./SummaryModals";

import SyncStatus from "../../../basic/syncStatus/SyncStatus";
import ExerciseSheetTitle from "../../../basic/create/ExerciseSheetTitle/ExerciseSheetTitle";
import SummaryVocabularyAccordion from "./SummaryVocabularyAccordion";
import SummaryExercisesAccordion from "./SummaryExercisesAccordion";
import SummaryFooter from "./SummaryFooter";
import SummaryModals from "./SummaryModals";
import { useT } from "@transifex/react";

import { SyncStatusWrapper, Wrapper } from "./StyledComponents";

export enum SummaryModes {
    OVERVIEW = "OVERVIEW",
    SELECT_EXERCISE_TYPE = "SELECT_EXERCISE_TYPE",
    EXERCISE_DETAILS = "EXERCISE_DETAILS",
}

const updateTestInServerDebounced = debounce(
    (functionToCall) => {
        functionToCall();
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const Summary: React.FC = () => {
    const {
        updateCurrentExerciseProperties,
        testContent,
        setTestContent,
        testName,
        saveTest,
        resetAppStatus,
        vocabularyDrawerContent,
        setVocabularyDrawerContent,
        loadUserTests,
        currentExerciseModel,
        setCurrentExerciseModel,
        setIsTestSynced,
        isTestSynced,
        isTitleSynced,
    } = useTestCreationContext();
    const { userId, setIsDataBeingLoaded, userInfo } = useGeneralContext();

    const history = useHistory();

    const t = useT();
    const t_pleaseAddExercise = t("Please add an exercise to save this test", { _tags: "CreateTest,Summary" });

    const hasExercises = testContent?.length > 0;
    const isSaveExerciseDisabled =
        currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT)
            ? !currentExerciseModel.extraComments || currentExerciseModel.extraComments.length === 0
            : !currentExerciseModel.wordsInProgress || currentExerciseModel.wordsInProgress.length < 3;

    const [isTestBeingSynced, setIsTestBeingSynced] = useState(false);
    const [openModal, setOpenModal] = useState<SummaryOpenModals | undefined>();
    const [exerciseContentModalExerciseIndex, setExerciseContentModalExerciseIndex] = useState<number>(-1);
    const [originalDirection, setOriginalDirection] = useState(currentExerciseModel.direction ?? 0);
    const [exerciseVocabularyOpenId, setExerciseVocabularyOpenId] = useState<string>("");

    const [currentMode, setCurrentMode] = useState<SummaryModes>(
        hasExercises ? SummaryModes.OVERVIEW : SummaryModes.SELECT_EXERCISE_TYPE
    );

    useEffect(() => {
        setCurrentExerciseModel({});

        if (!userInfo.isAnonymousUser && hasExercises) {
            updateTestInServerDebounced(saveTestData);
        } else {
            setIsTestBeingSynced(false);
            setIsTestSynced(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOriginalDirection(currentExerciseModel.direction ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExerciseModel.exerciseId]);

    const saveTestData = useCallback(
        (updatedContent?: TestQuestionModel[]) => {
            if (userId && testName && !userInfo.isAnonymousUser) {
                setIsTestBeingSynced(true);
                saveTest(updatedContent ?? testContent)
                    .then(() => {
                        setIsTestSynced(true);
                    })
                    .catch(() => {
                        setIsTestSynced(false);
                    })
                    .finally(() => {
                        setIsTestBeingSynced(false);
                        setTimeout(() => {}, 2000);
                    });
            } else {
                setIsTestBeingSynced(false);
                setIsTestSynced(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [saveTest, testName, userId, userInfo]
    );

    function finishTestEditing() {
        saveTest(testContent, true).then(
            async () => {
                localStorage.removeItem("localPreviewData");
                resetAppStatus();
                setIsDataBeingLoaded(false);
                loadUserTests(true);
                setIsTestSynced(false);
                history.push("/create");
            },
            (error) => {
                console.log(error);
            }
        );
    }

    function finishTest() {
        if (userInfo.isAnonymousUser || !userId) {
            setOpenModal(SummaryOpenModals.ANONYMOUS_USER_REGISTRATION);
        } else {
            const areAllCardsUsed = Object.values(vocabularyDrawerContent).every(
                (c) => c.exerciseIds && c.exerciseIds.length > 0
            );
            if (areAllCardsUsed) {
                finishTestEditing();
            } else {
                setOpenModal(SummaryOpenModals.UNUSED_INITIAL_VOCABULARY);
            }
        }
    }

    function saveExercise() {
        const newExercise = createNewExercise(currentExerciseModel);
        const updatedTestContent = createUpdatedTestContent(testContent, currentExerciseModel, newExercise);
        const updatedDrawerContent = createUpdatedDrawerContent({ ...vocabularyDrawerContent }, newExercise);

        setTestContent(updatedTestContent);
        setCurrentExerciseModel({});
        setVocabularyDrawerContent(updatedDrawerContent);

        return updatedTestContent;
    }

    function saveTestDataDebounced(updatedContent: TestQuestionModel[]) {
        setIsTestSynced(false);
        setIsTestBeingSynced(true);
        updateTestInServerDebounced(() => saveTestData(updatedContent));
    }

    async function handleContinue() {
        if (currentMode === SummaryModes.EXERCISE_DETAILS) {
            const newTestContent = saveExercise();
            setIsTestSynced(false);
            saveTestDataDebounced(newTestContent);
            setCurrentExerciseModel({});
            setExerciseVocabularyOpenId("");
            setCurrentMode(SummaryModes.OVERVIEW);
        } else {
            finishTest();
        }
    }

    function handleBack() {
        if (originalDirection !== null) {
            updateCurrentExerciseProperties({ direction: originalDirection });
        }

        if (
            currentMode === SummaryModes.OVERVIEW ||
            (currentMode === SummaryModes.SELECT_EXERCISE_TYPE && !hasExercises)
        ) {
            history.goBack();
        } else {
            setIsTestSynced(true);
            setCurrentExerciseModel({});
            setExerciseVocabularyOpenId("");
            setCurrentMode(SummaryModes.OVERVIEW);
        }
    }

    function handleCloseModal() {
        setOpenModal(undefined);
        if (exerciseContentModalExerciseIndex > -1) {
            setExerciseContentModalExerciseIndex(-1);
        }
    }

    return (
        <Wrapper>
            {typeof isTestSynced !== "undefined" && !userInfo.isAnonymousUser && (
                <SyncStatusWrapper>
                    <SyncStatus
                        isSyncing={isTestBeingSynced}
                        isSynced={isTestSynced && isTitleSynced}
                        error={{
                            show: !hasExercises,
                            message: t_pleaseAddExercise,
                        }}
                    />
                </SyncStatusWrapper>
            )}
            <ExerciseSheetTitle setIsTestBeingSynced={setIsTestBeingSynced} />
            <SummaryVocabularyAccordion />
            <SummaryExercisesAccordion
                hasExercises={hasExercises}
                currentMode={currentMode}
                setCurrentMode={setCurrentMode}
                setOpenModal={setOpenModal}
                setExerciseContentModalExerciseIndex={setExerciseContentModalExerciseIndex}
                saveTestDataDebounced={saveTestDataDebounced}
                exerciseVocabularyOpenId={exerciseVocabularyOpenId}
                setExerciseVocabularyOpenId={setExerciseVocabularyOpenId}
            />
            <SummaryFooter
                currentMode={currentMode}
                isSaveExerciseDisabled={isSaveExerciseDisabled}
                isTestBeingSynced={isTestBeingSynced}
                onClickButtonBack={handleBack}
                onClickButtonContinue={handleContinue}
            />
            <SummaryModals
                openModalType={openModal}
                onClose={handleCloseModal}
                exerciseContentModalExerciseIndex={exerciseContentModalExerciseIndex}
                finishTestEditing={finishTestEditing}
            />
        </Wrapper>
    );
};

export default Summary;
