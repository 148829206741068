import styled from "styled-components";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";

export const FillInTheGapExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
`;

export const ExerciseRowContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 80%;
    justify-content: unset;
    flex-wrap: wrap;
    line-height: 1.4;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        margin-bottom: 1.5rem;
        line-height: 1.6;`};
`;

export const ExerciseQuestionWordsContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    border-radius: 5px;
    border: solid 1px #cccccc;
    background: #f6f6f6;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 80%;
    min-height: 3rem;
    padding: 0.5rem;
    margin-bottom: 2rem;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        padding: 0.3rem;
        margin-bottom: 1rem;
        `};
`;

export const QuestionWordSpan = styled.span<{ isMobile: boolean }>`
    margin: 1rem;

    ${({ isMobile }) => isMobile && `margin: 5px;`};
`;

export const InlineQuestionSpan = styled.span`
    display: inline-block;
    white-space: pre-line;
    width: 100%;
`;

export const StyledInlineInput = styled(ExerciseInputField)`
    display: inline-block;
    margin: 0;
    padding: 5px 5px 5px 0;
    width: 250px;

    > input {
        padding: 0 0 0 5px !important;
    }

    &.s {
        width: 135px;
    }

    &.m {
        width: 190px;
    }
`;
