export enum SettingPosition {
    TOP = "TOP",
    BOTTOM = "BOTTOM",
}

export enum GoodLuckNames {
    SIDNEY_CLOVER = "SIDNEY_CLOVER",
    SIDNEY_HEARTS = "SIDNEY_HEARTS",
    SIDNEY_THINKING = "SIDNEY_THINKING",
    SIDNEY_THUMBS_UP = "SIDNEY_THUMBS_UP",
    HAPPY_CORGI = "HAPPY_CORGI",
    BLACK_CAT = "BLACK_CAT",
    RACCOON = "RACCOON",
}

export enum GradeRangeLimitType {
    MIN = "MIN",
    MAX = "MAX",
}

export enum TestVersionName {
    A = "A",
    B = "B",
}
