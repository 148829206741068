import { StyleSheet, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import { MinMaxGradeRange, PercentageGradingScale } from "p6m-p6u";
import { useMemo } from "react";
import { settingsDefault } from "../../../../../config/settingsDefault";
import DesignConstants from "../../../../../constants/DesignConstants";
import { convertToMinMaxGradingScale } from "../../../../../helpers/GradingScaleHelper";
import PDFGradingScaleCell from "./PDFGradingScaleCell";

interface PDFGradingScaleProps {
    overallScore?: number;
    gradingScaleSettings?: PercentageGradingScale;
}

const PDFGradingScale = (props: PDFGradingScaleProps) => {
    const t = useT();
    const t_grade = t("Grade", { _tags: "PDFConfiguration" });
    const t_points = t("Points", { _tags: "PDFConfiguration" });

    const gradingScale = useMemo(() => {
        // calculated inside Memo to reduce the amount of times the clean up function runs
        // react-pdf does not seem to optimise this enough to move it outside
        const gradingScaleSettings =
            props.gradingScaleSettings ?? settingsDefault.gradingAndSignatures.gradingScale.scale;

        return convertToMinMaxGradingScale(props.overallScore ?? 100, gradingScaleSettings);
    }, [props.gradingScaleSettings, props.overallScore]);

    const convertRangeToString = (gradeRange: MinMaxGradeRange) => {
        if (gradeRange.maxScore === gradeRange.minScore) {
            return gradeRange.maxScore.toString(); // only show one number if min = max
        } else {
            return gradeRange.maxScore + " - " + gradeRange.minScore;
        }
    };

    const renderGradeColumn = (topText: string, bottomText: string, isRowTitle?: boolean) => {
        return (
            <View
                style={styles.gradeColumn}
                key={"graderow" + topText + bottomText}
            >
                <PDFGradingScaleCell
                    borderLeft={!isRowTitle}
                    borderBottom
                    keyName={"grade" + topText}
                    isRowTitle={isRowTitle}
                >
                    {topText}
                </PDFGradingScaleCell>
                <PDFGradingScaleCell
                    borderLeft={!isRowTitle}
                    keyName={"range" + bottomText}
                    isRowTitle={isRowTitle}
                >
                    {bottomText}
                </PDFGradingScaleCell>
            </View>
        );
    };

    const renderGrades = () => {
        if (gradingScale.length === 0) {
            return null;
        }

        return gradingScale.map((gradeRange: MinMaxGradeRange) => {
            const range = convertRangeToString(gradeRange);
            return renderGradeColumn(gradeRange.grade.toString(), range);
        });
    };

    const renderRowTitles = () => {
        return renderGradeColumn(t_grade, t_points, true);
    };

    if (gradingScale.length === 0) return null;

    return (
        <View
            style={styles.outerContainer}
            wrap={false}
        >
            {renderRowTitles()}
            {renderGrades()}
        </View>
    );
};

const styles = StyleSheet.create({
    outerContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        borderWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        borderColor: DesignConstants.COLORS.DARK_GREY,
        borderRadius: DesignConstants.PDF.BORDER_RADIUS_S,
    },
    gradeColumn: {
        display: "flex",
        flex: 1,
    },
});

export default PDFGradingScale;
