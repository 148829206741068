import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";
import StyledButtonWithIcon from "../../styledButtonWithIcon/StyledButtonWithIcon";

export const PDFViewWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const ReloadButton = styled(StyledButtonWithIcon)`
    a:link {
        text-decoration: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        margin-bottom: ${DesignConstants.SPACES.S};
    }
`;
