import React from "react";
import { TestDetailsWord, TestQuestionModel } from "p6m-p6u";
import ExerciseEmptyLines from "../../detailsSubComponents/exerciseEmptyLines/ExerciseEmptyLines";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";
import { ExerciseDirections } from "../../../../../../enums/directions";
import WordScoreBubble from "../../../../../../basic/wordScoreBubble/WordScoreBubble";

import { Wrapper } from "../../StyledComponents";
import {
    WriteSentenceWrapper,
    ItemWrapper,
    WordAndIconWrapper,
    Word,
    RemoveIcon,
    LinesContainer,
    WordAndScoreWrapper,
} from "./StyledComponents";

export interface DetailsWriteSentenceProps {
    hasWords: boolean;
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
    showScores: boolean;
    currentExerciseModel: TestQuestionModel;
}

const DetailsWriteSentence: React.FC<DetailsWriteSentenceProps> = (props) => {
    const getTextToShow = (word: TestDetailsWord) => {
        return props.currentExerciseModel.direction === ExerciseDirections.ANSWER_TO_QUESTION ? word.answer : word.question;
    };

    const renderWordWithLines = (word: TestDetailsWord) => {
        return (
            <WordAndScoreWrapper key={"word_" + word.question}>
                <ItemWrapper className="word-row">
                    <WordAndIconWrapper>
                        <Word>{getTextToShow(word)}</Word>
                        <RemoveIcon
                            name={"close"}
                            onClick={() => props.onRemoveWord(word.id)}
                        />
                    </WordAndIconWrapper>
                    <LinesContainer>
                        <ExerciseEmptyLines />
                    </LinesContainer>
                </ItemWrapper>

                {props.showScores && <WordScoreBubble wordId={word.id ?? ""} />}
            </WordAndScoreWrapper>
        );
    };

    return (
        <Wrapper>
            {props.hasWords && (
                <WriteSentenceWrapper>
                    {props.currentExerciseModel.wordsInProgress?.map((word, index) => {
                        if ("id" in word && word.question && word.answer) {
                            return renderWordWithLines(word);
                        } else {
                            // do not render VerbtrainingSelectedWord fallback
                            return <div key={index} />;
                        }
                    })}
                </WriteSentenceWrapper>
            )}
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsWriteSentence;
