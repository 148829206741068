import styled from "styled-components";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";
import StyledButtonWithIcon from "../../../../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import DesignConstants from "../../../../../../constants/DesignConstants";

export const GradingScaleEditor = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.XS};
`;

export const GradingScaleExpandable = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: column;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const GradingScaleExpandableHeader = styled.div<{ isOpen?: boolean; disabled?: boolean }>`
    display: flex;
    flex: 1;
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
    justify-content: space-between;
    align-items: center;

    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    ${({ isOpen }) => isOpen && `border-bottom: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};`}
`;

export const Text = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const StyledP6Icon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.L};
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ResetButton = styled(StyledButtonWithIcon)`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-family: inherit;
`;

export const ResetButtonText = styled.p`
    display: flex;
    flex-direction: column;
    text-wrap: wrap;
`;

export const GradingScaleContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GradeRangeText = styled.div<{ isMin?: boolean }>`
    display: flex;
    justify-content: ${({ isMin }) => (isMin ? "flex-end" : "flex-start")};
    font-size: ${DesignConstants.FONT_SIZES.M};
    min-width: ${DesignConstants.FONT_SIZES.XXL}; // matches the widest width from the getExerciseScoreWidth function
`;

export const GradingScaleEditorRow = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    flex: 1;

    ${({ borderBottom }) =>
        borderBottom && `border-bottom: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};`};
`;

export const GradingScaleEditorCell = styled.div<{ borderRight?: boolean; stretch?: boolean }>`
    display: flex;
    flex: ${({ stretch }) => (stretch ? 10 : 1)};
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
    min-width: ${DesignConstants.SIZES.ICON_M}; // protect against collapsing on smaller screens
    justify-content: center;
    align-items: center;

    ${({ borderRight }) =>
        borderRight && `border-right: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};`};
`;

export const GradeRangeWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
`;

export const ScoreAndCounterControlWrapper = styled.div<{ isMin?: boolean }>`
    display: flex;
    flex-direction: ${({ isMin }) => (isMin ? "row" : "row-reverse")};
    flex: 1;
    gap: ${DesignConstants.SPACES.XS};
    align-items: center;
`;
