import React from "react";
import { ExerciseCreationModel, TestDetailsWord } from "p6m-p6u";
import { Exercises, ExerciseTypes } from "../../../../../../../enums/exercises";
import { sanitizeText } from "../../../../../../../helpers/TestCreation";
import WordScoreBubble from "../../../../../../../basic/wordScoreBubble/WordScoreBubble";

import { ListItem, RemoveWord, RemoveWordIcon, TextWrapper, Wrapper, WordAndScoreWrapper } from "./StyledComponents";

export interface GapSentenceListProps {
    onRemoveWord: (wordId?: string) => any;
    showScores: boolean;
    currentExerciseModel: ExerciseCreationModel;
}

const GapSentenceList: React.FC<GapSentenceListProps> = (props) => {
    const renderGapSentence = (word: TestDetailsWord) =>
        props.currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP) &&
        sanitizeText(word.gap_sentence ?? "");

    const renderDelete = (wordId?: string) => (
        <RemoveWord
            onClick={() => props.onRemoveWord(wordId)}
            className={
                !props.currentExerciseModel.wordsInProgress || props.currentExerciseModel.wordsInProgress.length <= 3
                    ? "disabled"
                    : ""
            }
        >
            <RemoveWordIcon name={"close"} />
        </RemoveWord>
    );

    return (
        <Wrapper>
            {props.currentExerciseModel.wordsInProgress?.map((w) => {
                if ("id" in w) {
                    return (
                        <WordAndScoreWrapper key={"wordListing_" + w.id}>
                            <ListItem className="word-row">
                                <TextWrapper>
                                    {props.currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP) &&
                                        "gap_sentence" in w &&
                                        renderGapSentence(w)}
                                </TextWrapper>
                                {renderDelete(w.id)}
                            </ListItem>
                            {props.showScores && <WordScoreBubble wordId={w.id ?? ""} />}
                        </WordAndScoreWrapper>
                    );
                } else {
                    return null;
                }
            })}
        </Wrapper>
    );
};

export default GapSentenceList;
