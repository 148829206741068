// LIBRARIES
import { useT } from "@transifex/react";
import { PreviousUrlNavigationProps, TeacherTestDetails } from "p6m-p6u";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CloseButton from "../../../basic/closeButton/CloseButton";
import PDFDownloadButton from "../../../basic/pdfBasic/PDFDownloadButton/PDFDownloadButton";
import PDFPreview from "../../../basic/pdfBasic/PDFPreview/PDFPreview";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { SwitchItemPosition } from "../../../enums/positions";
import { PreviewView } from "../../../enums/views";
import useTriggerOnNavigation from "../../../hooks/useTriggerOnNavigation";
import { logEventWithProps } from "../../../logging/Logger";
import MainWrapper from "../../students/MainWrapper/MainWrapper";

import {
    IframeWrapper,
    MiddleContentWrapper,
    PDFDocumentWrapper,
    PDFDownloadButtonsWrapper,
    PDFPreviewWrapper,
    PreviewModeSwitchItem,
    PreviewModeSwitchText,
    PreviewModeSwitchWrapper,
    TestPreviewHeader,
    TestPreviewInfo,
    Wrapper,
} from "./StyledComponents";

const TestPreview = () => {
    const history = useHistory();
    const location = useLocation<PreviousUrlNavigationProps>();

    const { refreshPreviewData } = useStudentPracticeContext();
    const { prepareFullTestDetails, testContent, resetAppStatus, loadUserTests, canLogEvent } =
        useTestCreationContext();

    const fullTestDetailsForPDF: TeacherTestDetails = prepareFullTestDetails(testContent);

    useEffect(() => {
        // loads data from main context into preview / practice context
        refreshPreviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testContent]);

    const handleCleanUp = () => {
        resetAppStatus();
        loadUserTests(true);
    };

    useTriggerOnNavigation(handleCleanUp, "/create");

    const t = useT();
    const t_desktop = t("Desktop", { _tags: "CreateTest,TestPreview" });
    const t_mobile = t("Mobile", { _tags: "CreateTest,TestPreview" });
    const t_pdf = t("PDF", { _tags: "CreateTest,TestPreview" });
    const t_closePreview = t("close preview", { _tags: "CreateTest,TestPreview" });
    const t_previewHint = t(
        "This page shows a preview of your task, i.e. the edits are not saved. To test the task yourself, use the generated link. You will see all exercises as your students would see them.",
        { _tags: "CreateTest,TestPreview" }
    );
    const t_exercisePDF = t("Exercise Sheet", { _tags: "CreateTest,TestPreview" });
    const t_testDownload = t("Download Test as PDF", { _tags: "CreateTest,TestPreview" });
    const t_configurePdf = t("Configure PDF", { _tags: "CreateTest,TestPreview" });

    const [currentPreviewMode, setCurrentPreviewMode] = useState<PreviewView>(PreviewView.MOBILE);

    return (
        <Wrapper>
            <CloseButton
                onClick={() => {
                    history.replace(location?.state?.urlBeforePreview || "/create");
                }}
                hintText={t_closePreview}
            />
            <TestPreviewInfo>{t_previewHint}</TestPreviewInfo>
            <TestPreviewHeader>
                <PreviewModeSwitchWrapper>
                    <PreviewModeSwitchItem
                        onClick={() => setCurrentPreviewMode(PreviewView.MOBILE)}
                        currentMode={currentPreviewMode}
                        mode={PreviewView.MOBILE}
                        position={SwitchItemPosition.LEFT}
                    >
                        <PreviewModeSwitchText>{t_mobile}</PreviewModeSwitchText>
                    </PreviewModeSwitchItem>

                    <PreviewModeSwitchItem
                        onClick={() => setCurrentPreviewMode(PreviewView.DESKTOP)}
                        currentMode={currentPreviewMode}
                        mode={PreviewView.DESKTOP}
                        position={SwitchItemPosition.CENTER}
                    >
                        <PreviewModeSwitchText>{t_desktop}</PreviewModeSwitchText>
                    </PreviewModeSwitchItem>

                    <PreviewModeSwitchItem
                        onClick={() => setCurrentPreviewMode(PreviewView.PDF)}
                        currentMode={currentPreviewMode}
                        mode={PreviewView.PDF}
                        position={SwitchItemPosition.RIGHT}
                    >
                        <PreviewModeSwitchText>{t_pdf}</PreviewModeSwitchText>
                    </PreviewModeSwitchItem>
                </PreviewModeSwitchWrapper>
            </TestPreviewHeader>
            <MiddleContentWrapper isPDF={currentPreviewMode === PreviewView.PDF}>
                {currentPreviewMode === PreviewView.PDF ? (
                    fullTestDetailsForPDF &&
                    fullTestDetailsForPDF?.content &&
                    fullTestDetailsForPDF?.content?.length > 0 && (
                        <PDFPreviewWrapper>
                            <PDFDownloadButtonsWrapper>
                                <StyledButtonWithIcon
                                    icon={"sliders"}
                                    iconPosition={"RIGHT"}
                                    buttonStyle={"PRIMARY"}
                                    onClick={() => {
                                        if (canLogEvent()) {
                                            logEventWithProps("Opened configuration", {
                                                testId: fullTestDetailsForPDF.id,
                                                origin: "preview",
                                            });
                                        }

                                        history.push("/create/pdf-configuration", {
                                            urlBeforeConfig: "/create/preview",
                                            urlBeforePreview: location?.state?.urlBeforePreview || "/create",
                                        });
                                    }}
                                >
                                    {t_configurePdf}
                                </StyledButtonWithIcon>
                                <PDFDownloadButton
                                    buttonText={t_testDownload}
                                    fileName={(fullTestDetailsForPDF.name ?? t_exercisePDF) + ".pdf"}
                                    testDetails={fullTestDetailsForPDF}
                                    secondary
                                />
                            </PDFDownloadButtonsWrapper>
                            <PDFDocumentWrapper>
                                <PDFPreview testDetails={fullTestDetailsForPDF} />
                            </PDFDocumentWrapper>
                        </PDFPreviewWrapper>
                    )
                ) : (
                    <IframeWrapper className={`${currentPreviewMode}`}>
                        <MainWrapper
                            isMobile={currentPreviewMode === PreviewView.MOBILE}
                            limitHeight
                            isTestRun
                        />
                    </IframeWrapper>
                )}
            </MiddleContentWrapper>
        </Wrapper>
    );
};

export default TestPreview;
