import React, { useRef, useState } from "react";
import { useT } from "@transifex/react";
import { useHistory } from "react-router-dom";
import { ExerciseCreationModel, TestQuestionModel, UnitCard, UserOwnCard } from "p6m-p6u";
import { ExerciseTypes, Exercises, ExercisesWithDirection, ActiveExerciseTypes } from "../../../enums/exercises";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { logEventWithProps } from "../../../logging/Logger";
import {
    createTestSuggestionData,
    filterVocabularyForNotSelectedWords,
    generateExerciseId,
} from "../../../helpers/TestCreation";
import { SummaryModes } from "../../../views/create/summary/Summary";
import DesignConstants from "../../../constants/DesignConstants";
import SelectExerciseTypeItem from "./SelectExerciseTypeItem/SelectExerciseTypeItem";
import { ExerciseDirections } from "../../../enums/directions";
import useExerciseDescription from "../../../hooks/useExerciseDescription";
import { Locales } from "../../../hooks/useExerciseDescriptionStructure";
import { handleMissingScores } from "../../../helpers/ScoringHelper";

import {
    CreateSuggestionWrapper,
    CreateSuggestionInfoText,
    BlankButtonNoBorder,
    ExerciseName,
    SelectExerciseTypeWrapper,
    DropDownOuterContainer,
    DropDownInnerContainer,
    CreateManuallyAndIconWrapper,
    IconWrapper,
    StyledIcon,
    OpenClosedWrapper,
    StyledArrowButton,
    NoExercisesWrapper,
    NoExercisesText,
    BottomScrollDiv,
    ExerciseSelectorWrapper,
} from "./StyledComponents";

export interface SelectExerciseTypeProps {
    isFirstExercise: boolean;
    setCurrentMode: React.Dispatch<React.SetStateAction<SummaryModes>>;
    saveTestDataDebounced: (newTestContent: TestQuestionModel[]) => void;
    setExerciseVocabularyOpenId: React.Dispatch<React.SetStateAction<string>>;
}

const SelectExerciseType: React.FC<SelectExerciseTypeProps> = (props) => {
    const {
        testContent,
        setTestContent,
        vocabularyDrawerContent,
        setVocabularyDrawerContent,
        setCurrentExerciseModel,
        setIsTestSynced,
        canLogEvent,
        showScores,
    } = useTestCreationContext();

    const history = useHistory();
    const t = useT();
    const { getDefaultDescription } = useExerciseDescription();

    const t_getSuggestion = t("Get Suggestion", { _tags: "CreateTest,SelectExerciseType" });
    const t_getSuggestionDescription = t(
        "Have an exercise automatically created for you based on your choice of vocabulary",
        {
            _tags: "CreateTest,SelectExerciseType",
        }
    );
    const t_createExerciseManually = t("Create an exercise manually, based on your choice of vocabulary.", {
        _tags: "CreateTest,SelectExerciseType",
    });
    const t_gapSentenceInfo = t("You need at least three gap sentences to use this exercise type", {
        _tags: "CreateTest,SelectExerciseType",
    });
    const t_unavailableForOwnContent = t("This exercise type is not available for your own content.", {
        _tags: "CreateTest,SelectExerciseType",
    });
    const t_NoExercises = t("No exercises yet", { _tags: "CreateTest,SelectExerciseType" });

    function isUnitCard(value: UnitCard | UserOwnCard): value is UnitCard {
        return value.hasOwnProperty("gap_sentence");
    }

    const gapSentences = Object.values(vocabularyDrawerContent).filter((vocabulary) => {
        if (vocabulary.wordContent && isUnitCard(vocabulary.wordContent)) {
            return vocabulary.wordContent.gap_sentence !== "";
        }
        return false;
    });

    let arePublisherCardsInDrawer = false;
    let areOwnCardsInDrawer = false;

    Object.values(vocabularyDrawerContent).forEach((vocabulary) => {
        if (!vocabulary.wordContent) return;
        isUnitCard(vocabulary.wordContent) ? (arePublisherCardsInDrawer = true) : (areOwnCardsInDrawer = true);
    });

    const areThereOwnCardsOnly = areOwnCardsInDrawer && !arePublisherCardsInDrawer;

    const [currentOpenExerciseDescription, setCurrentOpenExerciseDescription] = useState<"none" | Exercises>("none");
    const [isDropdownOpen, setIsDropdownOpen] = useState(!props.isFirstExercise);

    const bottomRef = useRef<HTMLDivElement>(null);

    function changeOpenDescription(e: React.MouseEvent<HTMLDivElement>, targetDescription: Exercises) {
        e.stopPropagation();
        if (currentOpenExerciseDescription === targetDescription) {
            setCurrentOpenExerciseDescription("none");
        } else {
            setCurrentOpenExerciseDescription(targetDescription);
        }
    }

    function createNewExerciseModel(exerciseType: Exercises, redirect?: boolean) {
        const model: ExerciseCreationModel = {
            status: "NEW",
            exerciseId: generateExerciseId(exerciseType, testContent),
            questionMode: ExerciseTypes.get(exerciseType),
            extraComments: getDefaultDescription(exerciseType, Locales.DE),
        };
        if (ExercisesWithDirection.includes(exerciseType)) {
            model.direction = ExerciseDirections.QUESTION_TO_ANSWER;
        }

        if (exerciseType === Exercises.FREE_TEXT) {
            model.includeEmptyFreeTextField = true;
            if (showScores) {
                model.exerciseScore = 5;
            }
        } else {
            props.setExerciseVocabularyOpenId(model.exerciseId ?? "");
        }

        setCurrentExerciseModel(model);

        if (redirect) {
            history.push("/create/exercise");
        }
    }

    const createSuggestion = (testToUse?: Array<TestQuestionModel>): TestQuestionModel[] | undefined => {
        const availableVocabulary = filterVocabularyForNotSelectedWords(vocabularyDrawerContent);

        if (availableVocabulary.length < 3) return;

        const { testSuggestionData, newVocabularyDrawerContent } = createTestSuggestionData(
            testContent,
            testToUse,
            availableVocabulary,
            vocabularyDrawerContent,
            getDefaultDescription
        );

        setVocabularyDrawerContent(newVocabularyDrawerContent);

        try {
            if (canLogEvent()) {
                let wordsUsed: number = 0;
                const exerciseTypes: Array<string> = [];

                testSuggestionData.forEach((t) => {
                    wordsUsed += t.selectedWords?.length || 0;
                    if (t.questionMode && !exerciseTypes.includes(t.questionMode)) {
                        exerciseTypes.push(t.questionMode);
                    }
                });

                logEventWithProps(
                    testToUse ? "Vokabeltest v2 Suggestion Updated" : "Vokabeltest v2 Suggestion Created",
                    {
                        exercises_in_test: testSuggestionData.length,
                        items_in_test: wordsUsed,
                        exercise_types_used: exerciseTypes,
                    }
                );
            }
        } catch (e) {
            console.log(e);
        }

        return testSuggestionData;
    };

    function selectExerciseType(exerciseType: Exercises) {
        createNewExerciseModel(exerciseType);
        setIsTestSynced(false);
        props.setCurrentMode(SummaryModes.EXERCISE_DETAILS);
    }

    function createAndSaveSuggestion() {
        const newTestContent = createSuggestion();
        if (newTestContent) {
            setIsTestSynced(false);
            setTestContent(newTestContent);
            props.saveTestDataDebounced(newTestContent);
            setCurrentExerciseModel({});
            props.setCurrentMode(SummaryModes.OVERVIEW);

            if (showScores) {
                const newExercisesWithScores = handleMissingScores(newTestContent);
                if (newExercisesWithScores) setTestContent(newExercisesWithScores);
            }
        }
    }

    const renderSelectExerciseType = () => {
        const selectExercisesTypeItems: JSX.Element[] = ActiveExerciseTypes.map((exerciseType) => {
            const isGapSentenceExerciseType = Exercises[exerciseType] === Exercises.FILL_GAP;
            const isInfoHidden = isGapSentenceExerciseType && areThereOwnCardsOnly;
            const isDisabledGapSentence = isGapSentenceExerciseType && gapSentences.length < 3;
            const infoText = isDisabledGapSentence
                ? areThereOwnCardsOnly
                    ? t_unavailableForOwnContent
                    : t_gapSentenceInfo
                : "";

            return (
                <SelectExerciseTypeItem
                    key={Exercises[exerciseType]}
                    exerciseType={Exercises[exerciseType]}
                    infoText={infoText}
                    isDisabled={isDisabledGapSentence}
                    isInfoHidden={isInfoHidden}
                    isInfoOpen={currentOpenExerciseDescription === Exercises[exerciseType]}
                    onToggleInfo={(event: React.MouseEvent<HTMLDivElement>) =>
                        changeOpenDescription(event, Exercises[exerciseType])
                    }
                    onSelect={!isDisabledGapSentence ? () => selectExerciseType(Exercises[exerciseType]) : () => {}}
                />
            );
        });

        return <SelectExerciseTypeWrapper>{selectExercisesTypeItems}</SelectExerciseTypeWrapper>;
    };

    const renderNoExercisesInfo = () => {
        return (
            <NoExercisesWrapper>
                <NoExercisesText>{t_NoExercises}</NoExercisesText>
            </NoExercisesWrapper>
        );
    };

    const renderCreateSuggestion = () => (
        <CreateSuggestionWrapper>
            <BlankButtonNoBorder
                onClick={createAndSaveSuggestion}
                buttonStyle={"PRIMARY"}
                icon={"magic-wand"}
                iconPosition={"LEFT"}
                disabled={filterVocabularyForNotSelectedWords(vocabularyDrawerContent).length < 3}
            >
                <ExerciseName>{t_getSuggestion}</ExerciseName>
            </BlankButtonNoBorder>
            <CreateSuggestionInfoText>{t_getSuggestionDescription}</CreateSuggestionInfoText>
        </CreateSuggestionWrapper>
    );

    const renderDropDownOuterContainer = () => (
        <DropDownOuterContainer
            className={isDropdownOpen ? "infoOpen" : ""}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
            <DropDownInnerContainer className={isDropdownOpen ? "infoOpen" : ""}>
                <CreateManuallyAndIconWrapper className={isDropdownOpen ? "infoOpen" : ""}>
                    <IconWrapper>
                        <StyledIcon
                            name={"edit-avatar"}
                            color={isDropdownOpen ? DesignConstants.COLORS.P6_ORANGE : undefined}
                        />
                    </IconWrapper>
                    <ExerciseName>{t_createExerciseManually}</ExerciseName>
                </CreateManuallyAndIconWrapper>
                <IconWrapper>
                    <OpenClosedWrapper>
                        <StyledArrowButton
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            direction={isDropdownOpen ? "UP" : "DOWN"}
                            arrowColor={isDropdownOpen ? DesignConstants.COLORS.P6_ORANGE : undefined}
                        />
                    </OpenClosedWrapper>
                </IconWrapper>
            </DropDownInnerContainer>
        </DropDownOuterContainer>
    );

    return (
        <ExerciseSelectorWrapper>
            {renderCreateSuggestion()}
            {renderDropDownOuterContainer()}
            {isDropdownOpen && renderSelectExerciseType()}
            {props.isFirstExercise && renderNoExercisesInfo()}
            <BottomScrollDiv ref={bottomRef} />
        </ExerciseSelectorWrapper>
    );
};

export default SelectExerciseType;
