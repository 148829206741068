import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    overflow: hidden;
    flex: 1;
`;

export const ExerciseContentContainer = styled.div`
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    border-top: ${DesignConstants.BORDERS.THIN_GREY};
    padding: ${DesignConstants.SPACES.M};
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.M};
`;