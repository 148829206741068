import styled from "styled-components";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../constants/DesignConstants";

export const TopBottomToggleWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const ToggleItem = styled.div<{ isActive?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: ${DesignConstants.SPACES.XXXL};
    gap: ${DesignConstants.SPACES.XXS};
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    border: ${DesignConstants.BORDERS.SEMI_THIN} "transparent";

    ${({ isActive }) =>
        isActive &&
        `background-color:  ${DesignConstants.COLORS.WHITE};
        border: ${DesignConstants.BORDERS.SEMI_THIN}  ${DesignConstants.COLORS.MID_GREY};`}
`;

export const ToggleText = styled.p`
    display: flex;
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const RotatedP6Icon = styled(PhaseSixIcon)`
    display: flex;
    transform: rotate(90deg);
    font-size: ${DesignConstants.FONT_SIZES.XL};
    justify-content: center;
    align-items: center;
    width: ${DesignConstants.SIZES.ICON_XXS};
    height: ${DesignConstants.SIZES.ICON_XXS};
`;
