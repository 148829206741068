import styled from "styled-components/macro";
import DesignConstants from "../../constants/DesignConstants";
import { ReactComponent as Logo } from "../../assets/img/p6-logo.svg";

export const Header = styled.div`
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    position: sticky;
    top: 0;
    box-shadow: 0 3px 3px rgb(0 0 0 / 10%);
    background-color: white;
    z-index: 1000;
`;

export const MainWrapper = styled.div`
    width: 100%;

    &.fill {
        height: 100%;
        min-height: 100vh;
    }

    &.gray {
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    }
`;

export const AnonymousPlaceholderTop = styled.div`
    width: 100%;
    height: ${DesignConstants.SPACES.S};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const BodyWrapper = styled.div<{ isIframeMode?: boolean; isPDFConfiguration?: boolean }>`
    ${({ isPDFConfiguration }) =>
        !isPDFConfiguration && `max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px;`}
    margin: auto;
    padding: ${(props) => (props.isIframeMode ? "0px" : DesignConstants.SPACES.S)} ${DesignConstants.SPACES.S}
        ${DesignConstants.SPACES.XL} ${DesignConstants.SPACES.S};
    ${({ isPDFConfiguration }) => isPDFConfiguration && `padding: 0;`}
    width: 100%;
    min-height: calc(100vh - 60px);
`;

export const StyledLogo = styled(Logo)`
    height: 90%;
`;
