import { Font } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import { BandUnit, GetBandInfoResponse, TeacherTestDetails } from "p6m-p6u";
import React, { ReactNode } from "react";
import { settingsDefault } from "../../config/settingsDefault";
import DesignConstants from "../../constants/DesignConstants";
import { ExerciseTypes, Exercises, ExercisesWithoutPageWrapInPDF } from "../../enums/exercises";
import { calculateTotalScore, getScoreForExercise } from "../../helpers/ScoringHelper";
import PDFConnectWordsExercise from "./exercises/PDFConnectWordsExercise";
import PDFCreateTextExercise from "./exercises/PDFCreateTextExercise";
import PDFFreeTextExercise from "./exercises/PDFFreeTextExercise";
import PDFGapSentenceExercise from "./exercises/PDFGapSentenceExercise";
import PDFStandardExercise from "./exercises/PDFStandardExercise";
import PDFWriteSentenceExercise from "./exercises/PDFWriteSentenceExercise";
import PDFBasicWrapper from "./PDFBasicWrapper";
import PDFExerciseWrapper from "./pdfSubComponents/PDFExerciseWrapper";

interface PDFFullTestProps {
    pdfUnits?: BandUnit[];
    testDetails?: TeacherTestDetails;
    bookArticleId?: string;
    loadInitArticleIdData: (
        articleId: string,
        isUuid?: boolean,
        shouldSetSelectedBookData?: boolean
    ) => Promise<GetBandInfoResponse>;
    isSolution?: boolean;
}

const PDFFullTest: React.FC<PDFFullTestProps> = (props) => {
    Font.register({
        family: DesignConstants.PDF.PROXIMA_FONT,
        fonts: [
            {
                src: window.location.href.includes("localhost")
                    ? "/fonts/proximanova-semibold.ttf"
                    : "https://www.phase-6.de/system/galleries/html/fonts/proximanova-semibold.ttf",
                fontWeight: 600,
            },
            {
                src: window.location.href.includes("localhost")
                    ? "/fonts/proximanova-regular.ttf"
                    : "https://www.phase-6.de/system/galleries/html/fonts/proximanova-regular.ttf",
                fontWeight: 400,
            },
        ],
    });

    const t = useT();

    const t_solutionPDF = t("Exercise Sheet Solution", { _tags: "CreateTest,PDFCreation" });

    const overallScore = calculateTotalScore(props.testDetails?.content ?? []);

    const renderExercises = () => {
        const testContent = props.testDetails?.content;
        if (!testContent) return null;

        let exercises: ReactNode[] = [];

        const goodLuckSettings =
            props.testDetails?.settings?.additional.goodLuck ?? settingsDefault.additional.goodLuck;

        const lastExerciseId = testContent[testContent.length - 1].exerciseId;

        testContent.forEach((exercise, index) => {
            let exerciseContent: ReactNode | undefined = undefined;

            const isLastExercise = exercise.exerciseId === lastExerciseId;

            switch (exercise.questionMode) {
                case ExerciseTypes.get(Exercises.CONNECT_WORDS):
                    exerciseContent = (
                        <PDFConnectWordsExercise
                            exercise={exercise}
                            goodLuckSettings={goodLuckSettings}
                            isLastExercise={isLastExercise}
                            isSolution={props.isSolution}
                        />
                    );
                    break;
                case ExerciseTypes.get(Exercises.FILL_GAP):
                    exerciseContent = (
                        <PDFGapSentenceExercise
                            exercise={exercise}
                            goodLuckSettings={goodLuckSettings}
                            isLastExercise={isLastExercise}
                            isSolution={props.isSolution}
                        />
                    );
                    break;
                case ExerciseTypes.get(Exercises.WRITE_SENTENCE):
                    exerciseContent = (
                        <PDFWriteSentenceExercise
                            exercise={exercise}
                            goodLuckSettings={goodLuckSettings}
                            isLastExercise={isLastExercise}
                            isSolution={props.isSolution}
                        />
                    );
                    break;
                case ExerciseTypes.get(Exercises.CREATE_TEXT):
                    exerciseContent = (
                        <PDFCreateTextExercise
                            exercise={exercise}
                            goodLuckSettings={goodLuckSettings}
                            isLastExercise={isLastExercise}
                            isSolution={props.isSolution}
                        />
                    );
                    break;
                case ExerciseTypes.get(Exercises.FREE_TEXT):
                    exerciseContent = (
                        <PDFFreeTextExercise
                            goodLuckSettings={goodLuckSettings}
                            isLastExercise={isLastExercise}
                            isSolution={props.isSolution}
                        />
                    );
                    break;
                default: // standard and fallback - TABLE EXERCISE
                    exerciseContent = (
                        <PDFStandardExercise
                            exercise={exercise}
                            goodLuckSettings={goodLuckSettings}
                            isLastExercise={isLastExercise}
                            isSolution={props.isSolution}
                        />
                    );
                    break;
            }

            const isOnePageOnlyExercise = ExercisesWithoutPageWrapInPDF.includes(exercise.questionMode ?? "");

            exercises.push(
                <PDFExerciseWrapper
                    exerciseTask={exercise?.extraComments ?? ""}
                    exerciseNumber={index + 1}
                    starterText={exercise.textFieldStarterText}
                    questionMode={exercise.questionMode}
                    wrapIsEnabled={!isOnePageOnlyExercise || !!props.isSolution}
                    key={exercise.exerciseId ?? "exercise-" + index}
                    exerciseScore={getScoreForExercise(exercise)}
                    showExerciseScore={props.testDetails?.showScores ?? false}
                >
                    {exerciseContent}
                </PDFExerciseWrapper>
            );
        });

        return exercises;
    };

    return (
        <PDFBasicWrapper
            title={props.isSolution ? `${t_solutionPDF}: ${props.testDetails?.name}` : props.testDetails?.name}
            bookArticleId={props.bookArticleId}
            loadInitArticleIdData={props.loadInitArticleIdData}
            ownSubjectInfoName={props.testDetails?.ownSubjectInfo?.name}
            isSolution={props.isSolution}
            settings={props.testDetails?.settings}
            overallScore={overallScore}
            showScores={props.testDetails?.showScores}
            pdfUnits={props.pdfUnits}
        >
            {renderExercises()}
        </PDFBasicWrapper>
    );
};

export default PDFFullTest;
