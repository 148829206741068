import styled from "styled-components";
import DesignConstants from "../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

export const PDFCollapsibleWrapper = styled.div`
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
`;

export const PDFCollapsibleHeader = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};

    ${({ isOpen }) =>
        isOpen &&
        `
            border-bottom: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
        `}
`;

export const PDFCollapsibleHeaderText = styled.p`
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
    font-size: ${DesignConstants.FONT_SIZES.L};
`;

export const StyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
`;
