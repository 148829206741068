import { MinMaxGradeRange, MinMaxGradingScale, SettingsSection, SettingsSubSection } from "p6m-p6u";
import CounterControl from "../../../../../../basic/counterControl/CounterControl";
import { formatScoreToCommaSeparated } from "../../../../../../helpers/ScoringHelper";
import { SettingsChangeProps } from "../../../PDFConfiguration";
import { UpdateGradingScaleParams } from "./PDFGradingScaleEditor";
import { GradeRangeLimitType } from "../../../../../../enums/settings";
import { EditScoreDirection } from "../../../../../../enums/directions";

import {
    GradeRangeText,
    GradeRangeWrapper,
    GradingScaleEditorCell,
    ScoreAndCounterControlWrapper,
    Text,
} from "./StyledComponents";

interface PDFGradeRangeEditorProps {
    gradingScale: MinMaxGradingScale;
    gradeRange: MinMaxGradeRange;
    index: number;
    overallScore: number;
    adaptableScoreLeftIndex: number;
    adaptableScoreRightIndex: number;
    updateGradeRange: (updateGradingScaleParams: UpdateGradingScaleParams) => void;
    handleSettingsChange: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        settingsChangeProps: SettingsChangeProps<T, K>
    ) => void;
}

const PDFGradeRangeEditor = (props: PDFGradeRangeEditorProps) => {
    const isRangeRedcutionPossible = props.gradeRange.maxScore !== props.gradeRange.minScore;

    const isAdaptableScoreLeft = props.adaptableScoreLeftIndex >= 0;
    const isAdaptableScoreRight = props.adaptableScoreRightIndex >= 0;

    const updateGradeRange = (minOrMax: GradeRangeLimitType, direction: EditScoreDirection) => {
        props.updateGradeRange({ gradingScale: props.gradingScale, index: props.index, minOrMax, direction });
    };

    const renderScoreAndControl = (minOrMax: GradeRangeLimitType, showControl: boolean) => {
        const isMin = minOrMax === GradeRangeLimitType.MIN;
        const score = isMin ? props.gradeRange.minScore : props.gradeRange.maxScore;
        const isDecreasable = isMin ? isAdaptableScoreRight : isRangeRedcutionPossible;
        const isIncreasable = isMin ? isRangeRedcutionPossible : isAdaptableScoreLeft;

        return (
            <ScoreAndCounterControlWrapper isMin={isMin}>
                <GradeRangeText isMin={isMin}>{formatScoreToCommaSeparated(score)}</GradeRangeText>
                {showControl && (
                    <CounterControl
                        currentCount={score}
                        onIncrement={() => updateGradeRange(minOrMax, EditScoreDirection.INCREMENT)}
                        onDecrement={() => updateGradeRange(minOrMax, EditScoreDirection.DECREMENT)}
                        minPoints={isDecreasable ? 0 : score}
                        maxPoints={isIncreasable ? props.overallScore : score}
                    />
                )}
            </ScoreAndCounterControlWrapper>
        );
    };

    return (
        <GradingScaleEditorCell stretch>
            <GradeRangeWrapper>
                {renderScoreAndControl(GradeRangeLimitType.MAX, props.index > 0)}
                <Text>{"—"}</Text>
                {renderScoreAndControl(GradeRangeLimitType.MIN, props.index < props.gradingScale.length - 1)}
            </GradeRangeWrapper>
        </GradingScaleEditorCell>
    );
};

export default PDFGradeRangeEditor;
