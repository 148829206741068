import { View } from "@react-pdf/renderer";
import React from "react";
import PDFEmptyLines from "../pdfSubComponents/PDFEmptyLines";
import PDFWordBox from "../pdfSubComponents/PDFWordBox";
import { TestDetailsItem } from "p6m-p6u";
import PDFTable from "../pdfSubComponents/PDFTable";
import { sanitizePDFText } from "../../../helpers/PDFHelper";
import { ExerciseDirections } from "../../../enums/directions";
import { PDFGoodLuckImageWrapper } from "../pdfGoodLuckImage/PDFGoodLuckImageWrapper";
import { GoodLuckSettings } from "p6m-p6u";
import { SettingPosition } from "../../../enums/settings";

interface PDFCreateTextExerciseProps {
    exercise: TestDetailsItem;
    goodLuckSettings: GoodLuckSettings;
    isLastExercise: boolean;
    isSolution?: boolean;
}

const PDFCreateTextExercise: React.FC<PDFCreateTextExerciseProps> = (props) => {
    const renderCreateTextExercise = () => {
        const selectedLearningWords =
            props.exercise.selectedWords?.map((w) =>
                "id" in w && w.id
                    ? sanitizePDFText(
                          props.exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION ? w.answer : w.question
                      )
                    : ""
            ) ?? [];

        const goodLuckIsActive = props.isLastExercise && props.goodLuckSettings.show;
        const imageIsBottom = props.goodLuckSettings.position === SettingPosition.BOTTOM;

        return (
            <View>
                <PDFWordBox words={selectedLearningWords} />
                <PDFGoodLuckImageWrapper
                    isLastElement={props.isLastExercise}
                    goodLuckSettings={props.goodLuckSettings}
                    isSolution={props.isSolution}
                >
                    <PDFEmptyLines
                        linesAmount={Math.min(
                            Math.ceil(selectedLearningWords.length / 3 + (goodLuckIsActive && imageIsBottom ? 2 : 0)),
                            goodLuckIsActive && imageIsBottom ? 8 : 6
                        )}
                    />
                </PDFGoodLuckImageWrapper>
            </View>
        );
    };

    return !props.isSolution ? (
        renderCreateTextExercise()
    ) : (
        <PDFTable
            selectedWords={props.exercise?.selectedWords ?? []}
            showAllQuestions
            showAllAnswers
        />
    );
};

export default PDFCreateTextExercise;
