import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";

export const CounterControlWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    max-width: 1.5rem;
`;

export const ScoreTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const ScoreText = styled.div`
    display: flex;
    align-items: center;
`;
