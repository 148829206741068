//LIBRARIES
import React, { ChangeEvent, useEffect, useRef } from "react";

import {
    ActionsWrapper,
    AlertIcon,
    AlertWrapper,
    InputField,
    StyledIcon,
    ContentWrapper,
    Text,
    Wrapper,
} from "./StyledComponents";

export interface InputWithActionsProps {
    isEditing: boolean;
    setIsEditing: (value: boolean) => void;
    disabled?: boolean;
    currentText: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSave: () => void;
    onDiscard: () => void;
    onDelete?: () => void;
    placeholder?: string;
    showWarning?: boolean;
    isSaveButtonDisabled?: boolean;
    triggerFlash?: boolean;
    iconSize?: string;
    fontSize?: string;
    underlined?: boolean;
}

const InputWithActions: React.FC<InputWithActionsProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.isEditing) {
            inputRef.current?.focus();
        }
    }, [props.isEditing]);

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();
            props.onSave();
        } else if (e.key === "Escape") {
            e.preventDefault();
            props.onDiscard();
        }
    }

    function handleEdit(e: React.MouseEvent) {
        if (!props.isEditing && !props.disabled) {
            e.preventDefault();
            props.setIsEditing(true);
        }
    }

    function handleSave(e: React.MouseEvent) {
        props.isSaveButtonDisabled ? e.preventDefault() : props.onSave();
    }

    function handleDelete(e: React.MouseEvent) {
        e.stopPropagation();
        if (props.onDelete) props.onDelete();
    }

    const renderContent = () => {
        if (props.isEditing) {
            return (
                <InputField
                    type={"text"}
                    value={props.currentText ?? ""}
                    onChange={props.onChange}
                    onKeyDown={handleKeyDown}
                    onBlur={props.onSave}
                    ref={inputRef}
                    placeholder={props.placeholder}
                    fontSize={props.fontSize}
                    disabled={props.disabled}
                />
            );
        } else {
            return (
                <AlertWrapper disabled={props.disabled}>
                    {props.showWarning && !props.currentText && <AlertIcon name="alert" />}
                    <Text
                        fontSize={props.fontSize}
                        lightGrey={props.underlined && props.currentText === ""}
                    >
                        {props.currentText || props.placeholder}
                    </Text>
                </AlertWrapper>
            );
        }
    };

    const renderClickableIcons = () => {
        return (
            <ActionsWrapper>
                {props.isEditing ? (
                    <>
                        <StyledIcon
                            name={"exercise-ok"}
                            onMouseDown={handleSave}
                            disabled={props.isSaveButtonDisabled || props.disabled}
                            iconSize={props.iconSize}
                        />
                        <StyledIcon
                            name={"close"}
                            onMouseDown={props.onDiscard}
                            iconSize={props.iconSize}
                            disabled={props.disabled}
                        />
                    </>
                ) : (
                    <>
                        <StyledIcon
                            name={"edit-avatar"}
                            onClick={() => {}}
                            iconSize={props.iconSize}
                            disabled={props.disabled}
                        />
                        {props.onDelete && props.currentText !== "" && (
                            <StyledIcon
                                name={"trash"}
                                onMouseDown={handleDelete}
                                iconSize={props.iconSize}
                                disabled={props.disabled}
                            />
                        )}
                    </>
                )}
            </ActionsWrapper>
        );
    };

    return (
        <Wrapper
            onMouseDown={handleEdit}
            clickable={!props.isEditing}
            underlined={props.underlined}
            disabled={props.disabled}
        >
            <ContentWrapper
                isFocused={props.isEditing}
                highlighted={props.showWarning}
                underlined={props.underlined}
                isFilledIn={props.currentText !== ""}
                className={props.triggerFlash ? "triggerFlash" : ""}
            >
                {renderContent()}
            </ContentWrapper>

            {renderClickableIcons()}
        </Wrapper>
    );
};

export default InputWithActions;
