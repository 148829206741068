import { ReducedTestQuestionModel, SelectedBookData, TestQuestionModel } from "p6m-p6u";

type LoggingContentParams = {
    items_in_test: number;
    exercise_types_used: (string | undefined)[];
    exercises_in_test: number;
    autom_gener_exercises: number;
    testId: string;
    own_content_items: number;
};

type LoggingContentExtendedBookInformationParams = {
    publisher: string;
    subject: string;
    book: string;
    article: string;
} & LoggingContentParams;

export type ContentDetailsAndLoggingDataParams = {
    ownCardsCount: number;
    bookCardsCount: number;
    loggingContent: LoggingContentParams;
    loggingContentExtendedBookInformation: LoggingContentExtendedBookInformationParams;
    amplitudeData: LoggingContentExtendedBookInformationParams;
};

export const getContentDetailsFromTest = (
    reducedTestContent: ReducedTestQuestionModel[]
): {
    ownCardsCount: number;
    bookCardsCount: number;
    cardsInTest: number;
    exerciseTypes: Array<string | undefined>;
    automaticallyGeneratedExercises: number;
} => {
    let ownCardsCount = 0;
    let bookCardsCount = 0;
    let cardsInTest = 0;
    let exerciseTypes: Array<string | undefined> = [];
    let automaticallyGeneratedExercises = 0;
    reducedTestContent.forEach((i) => {
        cardsInTest += i.selectedWords?.length || 0;
        if (!exerciseTypes.includes(i.questionMode)) {
            exerciseTypes.push(i.questionMode);
        }
        if (i.automaticallyCreated) {
            automaticallyGeneratedExercises++;
        }

        i.selectedWords?.forEach((w) => {
            if ("id" in w) {
                w.isUserCard ? ownCardsCount++ : bookCardsCount++;
            }
        });
    });
    return {
        ownCardsCount,
        bookCardsCount,
        cardsInTest,
        exerciseTypes,
        automaticallyGeneratedExercises,
    };
};

export const getContentDetailsAndLoggingDataFromTest = (
    testId: string,
    testContent: TestQuestionModel[],
    selectedBookData: SelectedBookData
): ContentDetailsAndLoggingDataParams => {
    const { ownCardsCount, bookCardsCount, cardsInTest, exerciseTypes, automaticallyGeneratedExercises } =
        getContentDetailsFromTest(testContent);

    const bookName = composeBookName(selectedBookData);

    let loggingContent = {
        items_in_test: cardsInTest,
        exercise_types_used: exerciseTypes,
        exercises_in_test: testContent.length,
        autom_gener_exercises: automaticallyGeneratedExercises,
        testId,
        own_content_items: ownCardsCount,
    };

    const loggingContentExtendedBookInformation = {
        ...loggingContent,
        publisher: selectedBookData.publisherBook?.PublisherName || "",
        subject: selectedBookData.subject?.Name || "",
        book: bookName,
        article: selectedBookData.band?.Name || "",
    };

    const amplitudeData = {
        ...loggingContentExtendedBookInformation,
        book: bookName.length > 4 ? bookName : "",
    };

    return {
        ownCardsCount,
        bookCardsCount,
        loggingContent,
        loggingContentExtendedBookInformation,
        amplitudeData,
    };
};

export const composeBookName = (selectedBookData: SelectedBookData): string => {
    const bookName = selectedBookData.publisherBook?.BookName;
    const publisherName = selectedBookData.publisherBook?.PublisherName;
    const bandShortName = selectedBookData.band?.ShortName;

    return `${bookName ?? ""} (${publisherName ?? ""}) (${bandShortName ?? ""})`;
};

export const reduceTestContent = (newTestContent: TestQuestionModel[]): ReducedTestQuestionModel[] => {
    return newTestContent.map(({ wordsInProgress, ...item }) => item);
};
