import styled, { keyframes } from "styled-components";
import DesignConstants from "../../constants/DesignConstants";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import { StyledPhase6Icon } from "../../complex/create/exerciseItem/exerciseInfo/directionSelector/StyledComponents";

export const TestSyncStatusWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    height: ${DesignConstants.SPACES.L};
`;

// animation ending at 359 degree to have a clean loop without a duplicated frame at the end / beginning of it
const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export const AnimatedLoadingIcon = styled(StyledPhase6Icon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.DARK_GREY};
    animation: ${spinAnimation} 1.5s infinite;
`;

export const TestSyncStatusIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
`;

export const TestSyncStatusText = styled.div<{ isAbsolutePosition?: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.L};

    ${({ isAbsolutePosition }) =>
        isAbsolutePosition &&
        `position: absolute;
        left: ${DesignConstants.FONT_SIZES.XXL};
        padding: ${DesignConstants.SPACES.XS};
        background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
        white-space: nowrap;
        `}
`;
