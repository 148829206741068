import { SettingPosition } from "../../../../../../enums/settings";
import PDFSettingsTopBottomToggle from "../../../pdfSettingsTopBottomButton/PDFSettingsTopBottomToggle";
import PDFSettingDetails from "../../pdfSettingDetails/PDFSettingDetails";

import { LabelAndTopDownWrapper, SettingTitle } from "./StyledComponents";

export interface TopBottomSettingDetails {
    subSettingTitle?: string;
    statusLabel?: string; // used for rendering the status in the wrapping control
    isActive: boolean;
    position: SettingPosition;
    toggleOnOff: () => void;
    togglePosition: () => void;
}

export interface TopBottomSettingDetailsProps extends TopBottomSettingDetails {
    disabled?: boolean;
}

const PDFTopBottomSettingDetails = (props: TopBottomSettingDetailsProps) => {
    const isTop = props.position === SettingPosition.TOP;
    return (
        <PDFSettingDetails
            checked={props.isActive}
            onChange={props.toggleOnOff}
            disabled={props.disabled}
        >
            <LabelAndTopDownWrapper>
                {props.subSettingTitle && (
                    <SettingTitle disabled={props.disabled}>{props.subSettingTitle}</SettingTitle>
                )}
                <PDFSettingsTopBottomToggle
                    isTop={isTop}
                    togglePosition={props.togglePosition}
                    disabled={!props.isActive || props.disabled}
                />
            </LabelAndTopDownWrapper>
        </PDFSettingDetails>
    );
};

export default PDFTopBottomSettingDetails;
