import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import React from "react";
import DesignConstants from "../../../../constants/DesignConstants";

interface PDFClassProps {
    className?: string;
}

const PDFClass: React.FC<PDFClassProps> = (props: PDFClassProps) => {
    const t = useT();

    const t_classColon = t("Class:", { _tags: "PDFConfiguration" });

    const isClassDefined = !!props.className;
    const isLongClassName = props.className && props.className.length > 20;

    return (
        <View
            style={[
                isClassDefined ? styles.classWrapperFilledIn : styles.classWrapper,
                isLongClassName ? styles.classWrapperFlex : styles.classWrapperLimited,
            ]}
        >
            <Text style={isClassDefined ? styles.headerTextClassDefined : styles.headerText}>{t_classColon}</Text>
            {isClassDefined && (
                <Text style={isLongClassName ? styles.specificClassWithFlex : styles.specificClass}>
                    {props.className}
                </Text>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    headerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    headerTextClassDefined: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    classWrapper: {
        display: "flex",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XS,
        minWidth: "25%",
        marginRight: DesignConstants.PDF.GAP_S,
    },
    classWrapperFilledIn: {
        display: "flex",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XXS,
        marginRight: DesignConstants.PDF.GAP_S,
    },
    classWrapperFlex: {
        flex: 1,
        maxWidth: "100%",
    },
    classWrapperLimited: {
        maxWidth: "40%",
    },
    specificClass: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    specificClassWithFlex: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
        flex: 1,
    },
});

export default PDFClass;
