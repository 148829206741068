import { useT } from "@transifex/react/dist";
import { debounce } from "lodash";
import { StudentQuestionAnswer } from "p6m-p6u";
import { useEffect, useRef, useState } from "react";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

import { BiggerTextArea, FreeTextExerciseWrapper, TextFieldStarterTextWrapper } from "./StyledComponents";

export interface FreeTextExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
    isMobile: boolean;
}

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const FreeTextExercise = (props: FreeTextExerciseProps) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "FreeTextExercise,inputPlaceholder" });

    const [answerContent, setAnswersContent] = useState(props.studentAnswerItem);
    const [isEditionMode, setIsEditionMode] = useState<boolean>(false);

    const updateAnswer = function (value: string) {
        const newAnswers = { ...answerContent };
        newAnswers.textAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            {
                ...answerContent,
            },
            props.updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answerContent]);

    const textRef = useRef<HTMLTextAreaElement>(null);

    return (
        <FreeTextExerciseWrapper>
            {props.studentAnswerItem.textFieldStarterText && (
                <TextFieldStarterTextWrapper>
                    {props.studentAnswerItem.textFieldStarterText}
                </TextFieldStarterTextWrapper>
            )}
            <BiggerTextArea
                onChange={(event) => {
                    updateAnswer(event.target.value);
                }}
                type="text"
                placeholder={t_placeholder}
                ref={textRef}
                value={answerContent.textAnswer}
                onFocus={() => setIsEditionMode(true)}
                onBlur={() => setIsEditionMode(false)}
            />
            {props.isMobile && isAndroid && isEditionMode && <InputControlArea />}
        </FreeTextExerciseWrapper>
    );
};

export default FreeTextExercise;
