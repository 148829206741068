import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const TileWrapper = styled.div<{ color: string; isMobile: boolean }>`
    position: relative;
    flex: 1;
    height: 70px;
    padding: ${DesignConstants.SPACES.S};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    border: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    cursor: pointer;
    color: black;

    &.selected {
        border: ${DesignConstants.BORDERS.MEDIUM} ${(props) => props.color} !important;
    }

    &.highlighted {
        border-color: ${(props) => props.color};
        border-radius: 0 ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER}
            ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
        background-color: ${(props) => props.color + DesignConstants.COLORS.TRANSPARENT_SUFFIX};
    }

    ${({ isMobile }) =>
        isMobile &&
        `padding: ${DesignConstants.SPACES.XS};
        height: 60px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;`};
`;

export const TileSelectedColor = styled.div<{ color: string }>`
    position: absolute;
    top: -${DesignConstants.SPACES.XS};
    left: -${DesignConstants.SPACES.XS};
    width: ${DesignConstants.SPACES.S};
    height: ${DesignConstants.SPACES.S};
    border-radius: ${DesignConstants.SPACES.S};
    background-color: ${(props) => props.color};
`;

export const HiddenRadio = styled.input`
    display: none;
`;

export const InnerContentWrapper = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const InnerContentWord = styled.p``;
