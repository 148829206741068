import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const PerWordScoreEditorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.M};
    border-right: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    padding: 0 ${DesignConstants.SPACES.S};
`;
