import { useT } from "@transifex/react/dist";
import { debounce } from "lodash";
import { StudentAnswerReviewItem, StudentQuestionAnswer } from "p6m-p6u";
import React, { useEffect, useRef, useState } from "react";
import { isAndroid } from "react-device-detect";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import InputControlArea from "../inputControlArea/InputControlArea";

import { ExerciseItemSeparator, ExerciseRowContainer, QuestionText, StandardExerciseWrapper } from "./StyledComponents";

export interface StandardExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
    isMobile: boolean;
}

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        // console.log("debouncing");
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// do debounce

const StandardExercise = (props: StandardExerciseProps) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "standardExercise,inputPlaceholder" });

    const [answersContent, setAnswersContent] = useState(
        props.studentAnswerItem.studentAnswers ? props.studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const updateItemAtPos = function (pos: number, value: string) {
        const newAnswers = [...answersContent];
        newAnswers[pos].studentAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            { ...props.studentAnswerItem, studentAnswers: answersContent },
            props.updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(props.studentAnswerItem.studentAnswers!.length));
    const checkFocus = function (pos: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            if (pos + 1 === refs.current.length) {
                refs.current[pos]?.blur();
            } else {
                refs.current[pos + 1]?.focus();
            }
        }
    };

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };
    const focusExerciseAtIndex = function (index: number) {
        // console.log('focusing at index', index)
        if (index < props.studentAnswerItem.studentAnswers!.length) {
            refs.current[index]?.focus();
        }
    };

    const renderMobileTable = () => {
        if (!props.studentAnswerItem.studentAnswers) return;

        return props.studentAnswerItem.studentAnswers.map((item: StudentAnswerReviewItem, i: number) => {
            return (
                <ExerciseRowContainer
                    key={"key_" + item.id}
                    isMobile={props.isMobile}
                >
                    <QuestionText>{item.question ?? item.answer}</QuestionText>
                    <ExerciseInputField
                        key={i}
                        onChange={(event) => {
                            updateItemAtPos(i, event.target.value);
                        }}
                        onKeyUp={(event) => checkFocus(i, event)}
                        onFocus={() => handleFocus(i)}
                        type="text"
                        placeholder={t_placeholder}
                        ref={(element: any) => (refs.current[i] = element)}
                        value={item.studentAnswer}
                        isMobile={props.isMobile}
                    />
                </ExerciseRowContainer>
            );
        });
    };

    const renderDesktopTable = () => {
        if (!props.studentAnswerItem.studentAnswers) return;

        return props.studentAnswerItem.studentAnswers.map((item: StudentAnswerReviewItem, i: number) => {
            return (
                <ExerciseRowContainer
                    key={"key_" + item.id}
                    isMobile={props.isMobile}
                >
                    {!!item.question ? (
                        <QuestionText>{item.question}</QuestionText>
                    ) : (
                        <ExerciseInputField
                            key={i}
                            onChange={(event) => {
                                updateItemAtPos(i, event.target.value);
                            }}
                            onKeyUp={(event) => checkFocus(i, event)}
                            type="text"
                            placeholder={t_placeholder}
                            ref={(element: any) => (refs.current[i] = element)}
                            value={item.studentAnswer}
                            isMobile={props.isMobile}
                        />
                    )}
                    <ExerciseItemSeparator />
                    {!!item.answer ? (
                        <QuestionText className="left">{item.answer}</QuestionText>
                    ) : (
                        <ExerciseInputField
                            key={i}
                            onChange={(event) => {
                                updateItemAtPos(i, event.target.value);
                            }}
                            onKeyUp={(event) => checkFocus(i, event)}
                            type="text"
                            placeholder={t_placeholder}
                            ref={(element: any) => (refs.current[i] = element)}
                            value={item.studentAnswer}
                            isMobile={props.isMobile}
                        />
                    )}
                </ExerciseRowContainer>
            );
        });
    };

    const renderTable = () => {
        if (props.isMobile) {
            return renderMobileTable();
        } else {
            return renderDesktopTable();
        }
    };

    return (
        <StandardExerciseWrapper>
            {props.studentAnswerItem.studentAnswers && renderTable()}
            {props.isMobile && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={props.studentAnswerItem.studentAnswers?.length}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </StandardExerciseWrapper>
    );
};

export default StandardExercise;
