import { useT } from "@transifex/react";
import { SettingPosition } from "../../../../../../enums/settings";
import PDFSettingControl from "../../PDFSettingControl";
import PDFTopBottomSettingDetails, { TopBottomSettingDetails } from "./PDFTopBottomSettingDetails";

import { RotatedP6Icon, SettingStatusWrapper, TopDownWrapper } from "./StyledComponents";
import { SettingsStatusText, SettingStatus } from "../../StyledComponents"; //custom status stylings

interface PDFTopBottomSettingControlProps {
    settingTitle: string;
    children?: React.ReactNode;
    topBottomSettingDetails: TopBottomSettingDetails;
    secondaryTopBottomSettingDetails?: TopBottomSettingDetails;
    disabled?: boolean;
}

const PDFTopBottomSettingControl = (props: PDFTopBottomSettingControlProps) => {
    const t = useT();
    const t_top = t("top", { _tags: "PDFConfiguration" });
    const t_bottom = t("bottom", { _tags: "PDFConfiguration" });
    const t_off = t("off", { _tags: "PDFConfiguration" });

    const renderStatusPart = (topBottomSettingDetails?: TopBottomSettingDetails) => {
        if (!topBottomSettingDetails) {
            return null;
        }

        const iconName = topBottomSettingDetails.position === SettingPosition.TOP ? "arrow-left" : "arrow-right";

        const positionText = topBottomSettingDetails.position === SettingPosition.TOP ? t_top : t_bottom;
        const positionStatusText = topBottomSettingDetails.isActive ? positionText : t_off;
        const statusText = topBottomSettingDetails.statusLabel ?? positionStatusText;

        return (
            <SettingStatus>
                <RotatedP6Icon name={iconName} />
                <SettingsStatusText>{statusText}</SettingsStatusText>
            </SettingStatus>
        );
    };

    const renderCustomStatus = () => {
        const isFirstActive = props.topBottomSettingDetails.isActive;
        const isSecondActive = props.secondaryTopBottomSettingDetails?.isActive;

        return (
            <SettingStatusWrapper disabled={props.disabled}>
                {isFirstActive && renderStatusPart(props.topBottomSettingDetails)}
                {isFirstActive && isSecondActive && <SettingsStatusText>{","}</SettingsStatusText>}
                {isSecondActive && renderStatusPart(props.secondaryTopBottomSettingDetails)}
                {!isFirstActive && !isSecondActive && <SettingsStatusText>{t_off}</SettingsStatusText>}
            </SettingStatusWrapper>
        );
    };

    return (
        <PDFSettingControl
            variant={"expandableWithCustomStatus"}
            settingTitle={props.settingTitle}
            customStatus={renderCustomStatus}
            disabled={props.disabled}
        >
            <TopDownWrapper>
                <PDFTopBottomSettingDetails
                    {...props.topBottomSettingDetails}
                    disabled={props.disabled}
                />
                {props.secondaryTopBottomSettingDetails && (
                    <PDFTopBottomSettingDetails
                        {...props.secondaryTopBottomSettingDetails}
                        disabled={props.disabled}
                    />
                )}
                {props.children}
            </TopDownWrapper>
        </PDFSettingControl>
    );
};

export default PDFTopBottomSettingControl;
