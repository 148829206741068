import React from "react";
import PDFEmptyLines from "../pdfSubComponents/PDFEmptyLines";
import { PDFGoodLuckImageWrapper } from "../pdfGoodLuckImage/PDFGoodLuckImageWrapper";
import { GoodLuckSettings } from "p6m-p6u";
import { SettingPosition } from "../../../enums/settings";

interface PDFFreeTextExerciseProps {
    goodLuckSettings: GoodLuckSettings;
    isLastExercise: boolean;
    isSolution?: boolean;
}

const PDFFreeTextExercise: React.FC<PDFFreeTextExerciseProps> = (props) => {
    const hideLines = props.isSolution;
    if (hideLines) return null;

    return (
        <PDFGoodLuckImageWrapper
            isLastElement={props.isLastExercise}
            goodLuckSettings={props.goodLuckSettings}
            isSolution={props.isSolution}
        >
            <PDFEmptyLines
                linesAmount={
                    props.isLastExercise &&
                    props.goodLuckSettings.show &&
                    props.goodLuckSettings.position === SettingPosition.BOTTOM
                        ? 6
                        : 3
                }
            />
        </PDFGoodLuckImageWrapper>
    );
};

export default PDFFreeTextExercise;
