import { useT } from "@transifex/react";
import { useState } from "react";

import { AnimatedLoadingIcon, TestSyncStatusIcon, TestSyncStatusText, TestSyncStatusWrapper } from "./StyledComponents";

interface SyncStatusProps {
    isSyncing: boolean;
    isSynced: boolean;
    error?: {
        show: boolean;
        message?: string;
    };
    isAbsolutePosition?: boolean;
}

const SyncStatus = (props: SyncStatusProps) => {
    const t = useT();

    const t_saved = t("Saved", { _tags: "CreateTest,Summary,PDFConfiguration" });
    const t_saving = t("Saving...", { _tags: "CreateTest,Summary,PDFConfiguration" });
    const t_unsavedChanges = t("Unsaved changes", { _tags: "CreateTest,Summary,PDFConfiguration" });

    const [displayInfoHovering, setDisplayInfoHovering] = useState(false);

    const renderSynced = () => {
        return (
            <TestSyncStatusIcon
                onMouseEnter={() => setDisplayInfoHovering(true)}
                onMouseLeave={() => setDisplayInfoHovering(false)}
                name={props.isSynced ? "cloud" : "alert"}
            />
        );
    };

    const renderSyncing = () => {
        return <AnimatedLoadingIcon name={"sync"} />;
    };

    const renderSyncStatusText = () => {
        let syncStatusText = "";
        if (props.isSyncing) {
            syncStatusText = t_saving;
        } else if (props.isSynced) {
            syncStatusText = t_saved;
        } else {
            syncStatusText = props.error?.show ? props.error.message : t_unsavedChanges;
        }
        return <TestSyncStatusText isAbsolutePosition={props.isAbsolutePosition}>{syncStatusText}</TestSyncStatusText>;
    };

    return (
        <TestSyncStatusWrapper>
            {props.isSyncing ? renderSyncing() : renderSynced()}
            {(displayInfoHovering || props.isSyncing) && renderSyncStatusText()}
        </TestSyncStatusWrapper>
    );
};

export default SyncStatus;
