// LIBRARIES
import { useT } from "@transifex/react/dist";
import { PreviousUrlNavigationProps, StudentTestContent } from "p6m-p6u";
import React, { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components/macro";
import { ReactComponent as infoIcon } from "../../../assets/icons/info.svg";
import { ReactComponent as Overview } from "../../../assets/icons/overview.svg";
import { ReactComponent as SentIcon } from "../../../assets/icons/sent.svg";
import { ReactComponent as NextArrow } from "../../../assets/icons/skip-card.svg";
import ArrowIcon from "../../../basic/arrowIcon/ArrowIcon";
import Exercise from "../../../complex/students/exercise/Exercise";
import InfoModal from "../../../complex/students/infoModal/InfoModal";
import ProgressDetail from "../../../complex/students/progressDetail/ProgressDetail";
import Timer from "../../../complex/students/timer/Timer";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import { PracticeViews } from "../../../enums/views";
import { getIsExampleExercise } from "../../../helpers/Environment";

import {
    AppInfoModalContent,
    CurrentExercise,
    EmptyButtonPlaceholder,
    ExerciseIndicatorContainer,
    InfoHeader,
    InfoHeaderInnerWrapper,
    InfoSubtitle,
    MiddleContentWrapper,
    ModalContent,
    ModalOverlay,
    NoTimerDiv,
    OpenModalIcon,
    OverviewButtonContainer,
    OverviewCTAContainer,
    OverviewIcon,
    OverviewText,
    OverviewTitle,
    ScrollWrapper,
    SendNowButton,
    SendNowIcon,
    Squircle,
    StyledFlagIcon,
    TotalExercises,
    Wrapper,
} from "./StyledComponents";

export interface PracticeProps {
    testData: StudentTestContent;
    isMobile: boolean;
    isTestRun?: boolean;
}

const Practice: React.FC<PracticeProps> = (props: PracticeProps) => {
    const t = useT();
    const t_overview = t("Overview", { _tags: "practiceScreen" });
    const t_sendNow = t("Send Now", { _tags: "practiceScreen" });
    const t_sendTest = t("Submit Now", { _tags: "practiceScreen" });
    const t_completedAllTitle = t("Are you sure you want to submit your results?", { _tags: "practiceScreen" });
    const t_exercisesIncompleteTitle = t("Attention, not all exercises are completed yet", {
        _tags: "practiceScreen",
    });
    const t_onlyExampleAssignement = t("This is only an example assignment", { _tags: "practiceScreen" });
    const t_previewHint = t("This page shows a preview of your task. The answers are not saved.", {
        _tags: "practice,beforeSubmitModal",
    });
    const t_finishPreviewHint = t(
        "No results will be sent to your teacher. Click “Submit now” to finish this preview.",
        {
            _tags: "practice,beforeSubmitModal",
        }
    );
    const t_noChangeAfterSubmission = t("You cannot change your answers after you submitted.", {
        _tags: "practice,beforeSubmitModal",
    });
    const t_backToPreview = t("back to preview", {
        _tags: "practice,beforeSubmitModal",
    });
    const t_close = t("close", {
        _tags: "practice,beforeSubmitModal",
    });

    const {
        currentExerciseId,
        setCurrentExerciseId,
        studentTestAnswer,
        startTimer,
        countdownStarted,
        currentPracticeView,
        setCurrentPracticeView,
        isTimeOver,
        finishPractice,
    } = useStudentPracticeContext();

    const location = useLocation<PreviousUrlNavigationProps>();
    const history = useHistory();

    const requireTimer = !!props.testData.timeLimit;

    if (requireTimer && !countdownStarted) {
        startTimer();
    }

    const [progressDetailModalIsOpen, setProgressDetailModalIsOpen] = useState(false);
    const [beforeSendModalIsOpen, setBeforeSendModalIsOpen] = useState(false);
    const [hasIncompleteExercises, setHasIncompleteExercises] = useState(true);
    const [contentIsOverflowing, setContentIsOverflowing] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const theme = useContext(ThemeContext);

    function toggleProgressDetailModal() {
        setProgressDetailModalIsOpen(!progressDetailModalIsOpen);
    }

    function hideProgressDetailModal() {
        setProgressDetailModalIsOpen(false);
    }

    function hideBeforeSendModal() {
        setBeforeSendModalIsOpen(false);
    }

    const changeSelectedExercise = (newExerciseKey: string) => {
        if (
            studentTestAnswer.questionAnswers &&
            Object.keys(studentTestAnswer.questionAnswers).indexOf(newExerciseKey) > -1
        ) {
            setCurrentExerciseId(newExerciseKey);
            if (progressDetailModalIsOpen) {
                setProgressDetailModalIsOpen(false);
            }

            if (
                currentPracticeView === PracticeViews.OVERVIEW ||
                currentPracticeView === PracticeViews.PRE_EXERCISES_OVERVIEW
            ) {
                setCurrentPracticeView(PracticeViews.EXERCISES);
            }
        } else {
            alert("non existant key");
        }
    };

    const goToNextExercise = () => {
        const exerciseIds = Object.keys(studentTestAnswer.questionAnswers || {});
        const currentExerciseIndex = exerciseIds.indexOf(currentExerciseId);

        if (currentPracticeView === PracticeViews.PRE_EXERCISES_OVERVIEW) {
            setCurrentPracticeView(PracticeViews.EXERCISES);
            return;
        }

        if (currentExerciseIndex === exerciseIds.length - 1) {
            setCurrentPracticeView(PracticeViews.OVERVIEW);
        } else {
            setCurrentExerciseId(exerciseIds[currentExerciseIndex + 1]);
        }

        //Scroll to top
        if (wrapperRef) {
            wrapperRef.current?.scrollIntoView();
        }
    };

    const goToPreviousExercise = () => {
        const exerciseIds = Object.keys(studentTestAnswer.questionAnswers || {});
        const currentExerciseIndex = exerciseIds.indexOf(currentExerciseId);

        if (currentPracticeView === PracticeViews.OVERVIEW) {
            setCurrentPracticeView(PracticeViews.EXERCISES);
        } else if (currentExerciseId !== exerciseIds[0]) {
            setCurrentExerciseId(exerciseIds[currentExerciseIndex - 1]);
        }

        //Scroll to top
        if (wrapperRef) {
            wrapperRef.current?.scrollIntoView();
        }
    };

    const sendTestStep1 = () => {
        if (isTimeOver) {
            finishPractice();
        } else {
            const questionAnswers = Object.values(studentTestAnswer.questionAnswers || {});
            const hasExercisesWithoutAnswers = !!questionAnswers.find(
                (exercise) =>
                    exercise.jumbledWordsAnswer === "" ||
                    exercise.textAnswer === "" ||
                    exercise.studentAnswers?.find((word) => word.studentAnswer === "")
            );

            setHasIncompleteExercises(hasExercisesWithoutAnswers);
            setBeforeSendModalIsOpen(true);
        }
    };

    const renderTestLanguageComponent = () => {
        if (props.testData.language) {
            return (
                <StyledFlagIcon
                    languageName={
                        props.testData.language.answerLanguageName === "Deutsch"
                            ? props.testData.language.questionLanguageName
                            : props.testData.language.answerLanguageName
                    }
                />
            );
        }
        return null;
    };

    const renderPreviousScreenButton = () => {
        return (
            <ArrowIcon
                onClick={goToPreviousExercise}
                direction={"LEFT"}
                disabled={isTimeOver || currentPracticeView === PracticeViews.PRE_EXERCISES_OVERVIEW}
            />
        );
    };
    const renderNextScreenButton = () => {
        return (
            <ArrowIcon
                onClick={goToNextExercise}
                direction={"RIGHT"}
                disabled={isTimeOver || currentPracticeView === PracticeViews.OVERVIEW}
            />
        );
    };

    const progressDetailCallback = () => {
        setCurrentPracticeView(PracticeViews.OVERVIEW);
        hideProgressDetailModal();
    };

    const renderExercisesIndicator = () => {
        return (
            <ExerciseIndicatorContainer
                onClick={toggleProgressDetailModal}
                isMobile={props.isMobile}
                onMouseDown={(event: React.MouseEvent) => {
                    event.preventDefault(); // prevents accidentally selecting text in the children
                }}
            >
                <OverviewIcon svgComponent={Overview} />
                <OverviewText>{t_overview}</OverviewText>
                <OpenModalIcon svgComponent={NextArrow} />
            </ExerciseIndicatorContainer>
        );
    };

    const renderTimerComponent = () => {
        return (
            <Timer
                timeEndCallbackFunction={finishPractice}
                timeLimit={props.testData.timeLimit!}
                isMobile={props.isMobile}
            />
        );
    };
    const renderInfoHeaderResponsive = () => {
        if (props.isMobile && currentPracticeView === PracticeViews.EXERCISES) {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    <InfoHeaderInnerWrapper>
                        {renderExercisesIndicator()}
                        {requireTimer && renderTimerComponent()}
                    </InfoHeaderInnerWrapper>
                    {renderProgressDetailModal()}
                </InfoHeader>
            );
        } else if (props.isMobile && currentPracticeView === PracticeViews.OVERVIEW) {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    <InfoHeaderInnerWrapper>
                        {renderPreviousScreenButton()}
                        {requireTimer && renderTimerComponent()}
                    </InfoHeaderInnerWrapper>
                </InfoHeader>
            );
        } else if (!props.isMobile && currentPracticeView === PracticeViews.EXERCISES) {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    <InfoHeaderInnerWrapper>
                        {renderTestLanguageComponent()}
                        {renderExercisesIndicator()}
                        {requireTimer && renderTimerComponent()}
                        {!requireTimer && <NoTimerDiv />}
                    </InfoHeaderInnerWrapper>
                    {renderProgressDetailModal()}
                </InfoHeader>
            );
        } else if (!props.isMobile && currentPracticeView === PracticeViews.OVERVIEW) {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    <InfoHeaderInnerWrapper>
                        {renderTestLanguageComponent()}
                        <OverviewTitle>{t_overview}</OverviewTitle>
                        {requireTimer && renderTimerComponent()}
                        {!requireTimer && <NoTimerDiv />}
                    </InfoHeaderInnerWrapper>
                </InfoHeader>
            );
        } else {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    <InfoHeaderInnerWrapper>
                        {!props.isMobile && renderTestLanguageComponent()}
                        <OverviewTitle>{t_overview}</OverviewTitle>
                        {requireTimer && renderTimerComponent()}
                        {!requireTimer && <NoTimerDiv />}
                    </InfoHeaderInnerWrapper>
                </InfoHeader>
            );
        }
    };

    const renderContentComponent = () => {
        if (
            currentPracticeView === PracticeViews.PRE_EXERCISES_OVERVIEW ||
            currentPracticeView === PracticeViews.OVERVIEW
        ) {
            return (
                <ProgressDetail
                    changeSelectedExercise={changeSelectedExercise}
                    showTopCta={currentPracticeView === PracticeViews.PRE_EXERCISES_OVERVIEW}
                />
            );
        } else if (props.testData.content) {
            const question: any = props.testData.content.find((c) => c.id === currentExerciseId);

            return (
                <Exercise
                    key={"exercise_" + question.id}
                    testQuestion={question}
                    setContentIsOverflowingCallback={setContentIsOverflowing}
                    isMobile={props.isMobile}
                />
            );
        } else {
            return null;
        }
    };

    const renderOverviewCTAContent = () => {
        if (currentPracticeView === PracticeViews.OVERVIEW) {
            return (
                <OverviewCTAContainer isMobile={props.isMobile}>
                    <SendNowButton onClick={sendTestStep1}>
                        {t_sendNow}
                        <SendNowIcon
                            svgComponent={SentIcon}
                            color={"white"}
                        />
                    </SendNowButton>
                </OverviewCTAContainer>
            );
        } else {
            return (
                <OverviewCTAContainer isMobile={props.isMobile}>
                    {currentExerciseId !== Object.keys(studentTestAnswer.questionAnswers || {})[0] &&
                    currentPracticeView !== PracticeViews.PRE_EXERCISES_OVERVIEW ? (
                        renderPreviousScreenButton()
                    ) : (
                        <EmptyButtonPlaceholder />
                    )}
                    <OverviewButtonContainer isMobile={props.isMobile}>
                        <CurrentExercise>{currentExerciseId.replace("e", "")}</CurrentExercise>
                        <TotalExercises>/{Object.keys(studentTestAnswer.questionAnswers || {}).length}</TotalExercises>
                    </OverviewButtonContainer>
                    {renderNextScreenButton()}
                </OverviewCTAContainer>
            );
        }
    };

    const isExampleExercise = getIsExampleExercise(props.testData.id);

    const ref = useRef<HTMLDivElement>(null);

    const renderMiddleContent = () => {
        return (
            <MiddleContentWrapper isMobile={props.isMobile}>
                <Squircle
                    ref={ref}
                    isMobile={props.isMobile}
                >
                    {renderContentComponent()}
                    {!props.isMobile && contentIsOverflowing && <ScrollWrapper />}
                </Squircle>
            </MiddleContentWrapper>
        );
    };
    const renderProgressDetailModal = () => {
        if (progressDetailModalIsOpen)
            return (
                <ModalOverlay>
                    <ModalContent isMobile={props.isMobile}>
                        <AppInfoModalContent>
                            <ProgressDetail
                                changeSelectedExercise={changeSelectedExercise}
                                showBottomCta={true}
                                bottomCtaCallback={progressDetailCallback}
                            />
                        </AppInfoModalContent>
                    </ModalContent>
                </ModalOverlay>
            );
    };

    const renderInfoModal = () => {
        return (
            <InfoModal
                isOpen={beforeSendModalIsOpen}
                onRequestClose={hideBeforeSendModal}
                modalIcon={infoIcon}
                iconColor={theme.base.primaryColor}
                modalTitle={
                    props.isTestRun
                        ? t_previewHint
                        : isExampleExercise
                        ? t_onlyExampleAssignement
                        : hasIncompleteExercises
                        ? t_exercisesIncompleteTitle
                        : t_completedAllTitle
                }
                cancelText={props.isTestRun ? t_backToPreview : ""}
                continueText={props.isTestRun ? t_close : t_sendTest}
                cancelAction={hideBeforeSendModal}
                continueAction={
                    props.isTestRun
                        ? () => {
                              history.replace(location?.state?.urlBeforePreview || "/create");
                          }
                        : isExampleExercise
                        ? () => window.close()
                        : () => finishPractice()
                }
            >
                <InfoSubtitle isMobile={props.isMobile}>
                    {!props.isTestRun ? (isExampleExercise ? t_finishPreviewHint : t_noChangeAfterSubmission) : ""}
                </InfoSubtitle>
            </InfoModal>
        );
    };

    return (
        <Wrapper ref={wrapperRef}>
            {renderInfoHeaderResponsive()}
            {renderMiddleContent()}
            {renderOverviewCTAContent()}
            {renderInfoModal()}
        </Wrapper>
    );
};

export default Practice;
