import React, { useState } from "react";
import { useT } from "@transifex/react";
import { StudentTestContent, StudentTestSharingData, TeacherTestDetails } from "p6m-p6u";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import DurationPicker from "../../../basic/durationPicker/DurationPicker";
import { useHistory } from "react-router-dom";
import StyledButton from "../../../basic/styledButton/StyledButton";
import PDFDownloadButton from "../../../basic/pdfBasic/PDFDownloadButton/PDFDownloadButton";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import { logEventWithProps } from "../../../logging/Logger";

import {
    TabContentWrapper,
    PDFCtasWrapper,
    OnlineTestInfoWrapper,
    GradeTestWrapper,
    TestShareWrapper,
    Separator,
    CurrentAnswersCount,
    TeacherCommentWrapper,
    TestConfigWrapper,
    TestInfoText,
    CommentTextArea,
    StyledKeyboardDateTimePicker,
    DurationWrapper,
    DeadlineWrapper,
    TestConfigCTAWrapper,
    ShareCTA,
    ShareInfo,
    ShareLink,
    SaveChangesCTA,
    CTASpan,
    InvalidTeacherComment,
} from "./StyledComponents";

export interface TestListElementTabContentProps {
    currentTab: "NONE" | "ONLINE" | "PDF";
    teacherTestDetails: TeacherTestDetails;
    isOwnSubjectStillAvailable: boolean;
    localSharingData: StudentTestSharingData;
    testAlreadyShared: boolean;
    teacherCommentIsInvalid: boolean;
    setTeacherCommentIsInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    setLocalSharingData: React.Dispatch<React.SetStateAction<StudentTestSharingData>>;
    copyTestLink: () => void;
    onShareTestRequested: () => void;
    resetLocalSharingData: () => void;
    studentTestContent: StudentTestContent;
    linkToTest: string;
}

const TestListElementTabContent: React.FC<TestListElementTabContentProps> = ({
    currentTab,
    teacherTestDetails,
    isOwnSubjectStillAvailable,
    localSharingData,
    testAlreadyShared,
    teacherCommentIsInvalid,
    setTeacherCommentIsInvalid,
    setLocalSharingData,
    copyTestLink,
    onShareTestRequested,
    resetLocalSharingData,
    studentTestContent,
    linkToTest,
}) => {
    const { updateSharedTestProperties, isTestSharingInfoBeingLoaded, loadTestForEdition, canLogEvent } =
        useTestCreationContext();

    const history = useHistory();

    const [deadlineIsInvalid, setDeadlineIsInvalid] = useState(false);

    const t = useT();
    const t_testDownload = t("Test", { _tags: "CreateTest,TestListElement" });
    const t_solutionDownload = t("Solution", { _tags: "CreateTest,TestListElement" });
    const t_teacherComment = t("Test instructions", { _tags: "CreateTest,TestListElement" });
    const t_teacherCommentInvalid = t("Please write a message", { _tags: "CreateTest,TestListElement" });
    const t_deadline = t("Deadline", { _tags: "CreateTest,TestListElement" });
    const t_editingTime = t("Editing Time", { _tags: "CreateTest,TestListElement" });
    const t_saveChanges = t("Save Changes", { _tags: "CreateTest,TestListElement" });
    const t_gradeTests = t("Grade Answers", { _tags: "CreateTest,TestListElement" });
    const t_currentAmountToGrade = t("Current Answers", { _tags: "CreateTest,TestListElement" });
    const t_share = t("Share Test", { _tags: "CreateTest,TestListElement" });
    const t_copyShare = t("Copy link and share", { _tags: "CreateTest,TestListElement" });
    const t_copyLinkManually = t("Link to the exercise", { _tags: "CreateTest,TestListElement" });
    const t_solution = t("Solution", { _tags: "CreateTest,TestListElement" });
    const t_exercisePDF = t("Exercise Sheet", { _tags: "CreateTest,PDFCreation" });
    const t_configurePdf = t("Configure", { _tags: "CreateTest,TestListElement" });

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";

    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    async function openConfiguration() {
        try {
            await loadTestForEdition(teacherTestDetails.id ?? "", true);

            if (canLogEvent()) {
                logEventWithProps("Opened configuration", {
                    testId: teacherTestDetails.id,
                    origin: "overview",
                });
            }

            history.push("/create/pdf-configuration", {
                urlBeforeConfig: "/create",
            });
        } catch (e) {
            alert("Error while loading test " + e);
        }
    }

    function updateTeacherComment(val: string) {
        let localData = localSharingData;

        if (val.length > 0) {
            setTeacherCommentIsInvalid(false);
        } else {
            setTeacherCommentIsInvalid(true);
        }

        localData.teacherComment = val;
        setLocalSharingData({ ...localData });
    }

    function updateDeadline(val: any) {
        let localData = localSharingData;
        if (val === null || isNaN(val)) {
            if (isNaN(val)) {
                setDeadlineIsInvalid(true);
            } else {
                localData.dueDate = 0;
                setLocalSharingData({ ...localData });
                setDeadlineIsInvalid(false);
            }
        } else {
            val = val as unknown as moment.Moment;
            if (val.isValid() && val.isAfter(moment.now())) {
                localData.dueDate = val.unix() * 1000;
                setLocalSharingData({ ...localData });
                setDeadlineIsInvalid(false);
            } else {
                setDeadlineIsInvalid(true);
            }
        }
    }

    function updateTimeLimit(val: string) {
        let localData = localSharingData;

        localData.timeLimit = val;
        setLocalSharingData({ ...localData });
    }

    function updateSharedTest() {
        if (teacherTestDetails.id) {
            updateSharedTestProperties(teacherTestDetails.id, localSharingData).catch(() => {
                resetLocalSharingData();
            });
        }
    }

    const TeacherComment = (
        <TeacherCommentWrapper>
            <TestInfoText>{t_teacherComment}</TestInfoText>
            <CommentTextArea
                value={localSharingData.teacherComment}
                disabled={testAlreadyShared}
                className={teacherCommentIsInvalid ? "invalid" : ""}
                onChange={(event) => {
                    updateTeacherComment(event.target.value);
                }}
            />
            {teacherCommentIsInvalid && <InvalidTeacherComment>{t_teacherCommentInvalid}</InvalidTeacherComment>}
            {!isMobileView && (
                <>
                    <ShareCTA
                        icon={"share"}
                        iconPosition={"RIGHT"}
                        onClick={() => {
                            testAlreadyShared ? copyTestLink() : onShareTestRequested();
                        }}
                    >
                        <CTASpan>{testAlreadyShared ? t_copyShare : t_share}</CTASpan>
                    </ShareCTA>
                </>
            )}
        </TeacherCommentWrapper>
    );

    const Deadline = (
        <DeadlineWrapper>
            <TestInfoText>{t_deadline}</TestInfoText>
            <StyledKeyboardDateTimePicker
                value={localSharingData.dueDate ? moment.unix(localSharingData.dueDate / 1000) : null}
                onChange={updateDeadline}
                ampm={false}
                className={`${!testAlreadyShared && "disabled"} ${deadlineIsInvalid && "invalid"}`}
                disablePast={!testAlreadyShared}
                format={"DD.MM.YYYY HH:mm"}
                disableOpenPicker={false}
                displayWeekNumber={true}
            />
        </DeadlineWrapper>
    );

    const Duration = (
        <DurationWrapper>
            <TestInfoText>{t_editingTime}</TestInfoText>
            <DurationPicker
                value={localSharingData.timeLimit}
                disabled={testAlreadyShared}
                onChange={(val) => {
                    updateTimeLimit(val);
                }}
            />
        </DurationWrapper>
    );
    const TestConfigCTA = (
        <TestConfigCTAWrapper>
            {testAlreadyShared && (localSharingData.dueDate || 0) !== (studentTestContent.dueDate || 0) && (
                <SaveChangesCTA
                    buttonStyle={"PRIMARY"}
                    arrowDirection={"NONE"}
                    onClick={updateSharedTest}
                >
                    {t_saveChanges}
                </SaveChangesCTA>
            )}
            {isMobileView && (
                <ShareCTA
                    icon={"share"}
                    iconPosition={"RIGHT"}
                    onClick={() => {
                        testAlreadyShared ? copyTestLink() : onShareTestRequested();
                    }}
                >
                    <CTASpan>{testAlreadyShared ? t_copyShare : t_share}</CTASpan>
                </ShareCTA>
            )}
        </TestConfigCTAWrapper>
    );

    const TestShare = (
        <TestShareWrapper className={!isOwnSubjectStillAvailable || isTestSharingInfoBeingLoaded ? "disabled" : ""}>
            {TeacherComment}
            <TestConfigWrapper>
                {Deadline}
                {Duration}
                {TestConfigCTA}
            </TestConfigWrapper>
        </TestShareWrapper>
    );

    const PDFCtas = (
        <PDFCtasWrapper>
            <PDFDownloadButton
                buttonText={t_testDownload}
                fileName={(teacherTestDetails.name ?? t_exercisePDF) + ".pdf"}
                testDetails={teacherTestDetails}
            />
            <PDFDownloadButton
                buttonText={t_solutionDownload}
                fileName={(teacherTestDetails.name ?? t_exercisePDF) + " " + t_solution + ".pdf"}
                secondary
                testDetails={teacherTestDetails}
                isSolution
            />

            <StyledButtonWithIcon
                icon={"sliders"}
                iconPosition={"RIGHT"}
                buttonStyle={"BLANK"}
                onClick={openConfiguration}
            >
                {t_configurePdf}
            </StyledButtonWithIcon>
        </PDFCtasWrapper>
    );

    const OnlineTestInfo = (
        <OnlineTestInfoWrapper>
            {TestShare}
            <Separator />
            <GradeTestWrapper>
                <TestInfoText>{t_currentAmountToGrade}</TestInfoText>
                <CurrentAnswersCount>{studentTestContent.resultsCount || 0}</CurrentAnswersCount>
                <StyledButton
                    disabled={!studentTestContent.resultsCount || !studentTestContent.id}
                    onClick={() => {
                        history.push("/review/" + studentTestContent.id);
                    }}
                >
                    {t_gradeTests}
                </StyledButton>
            </GradeTestWrapper>
        </OnlineTestInfoWrapper>
    );

    const TestAlreadySharedInfo = (
        <ShareInfo>
            {t_copyLinkManually}: <ShareLink>{linkToTest}</ShareLink>
        </ShareInfo>
    );

    return (
        <TabContentWrapper>
            {currentTab === "PDF" && PDFCtas}
            {currentTab === "ONLINE" && (
                <>
                    {OnlineTestInfo}
                    {testAlreadyShared && TestAlreadySharedInfo}
                </>
            )}
        </TabContentWrapper>
    );
};

export default TestListElementTabContent;
