import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../constants/DesignConstants";

export const WordScoreBubbleWrapper = styled.div<{ isEditing: boolean }>`
    background: ${DesignConstants.COLORS.WHITE};
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHTEST_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.XXS};
    cursor: ${({ isEditing }) => (isEditing ? "default" : "pointer")};
`;

export const StyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    max-width: 1.5rem;
`;
