import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import React from "react";
import DesignConstants from "../../../../constants/DesignConstants";

interface PDFDateProps {
    date?: number;
    showDateLeft?: boolean;
    isDateFlex?: boolean;
}

const PDFDate: React.FC<PDFDateProps> = (props: PDFDateProps) => {
    const t = useT();

    const t_dateColon = t("Date:", { _tags: "PDFConfiguration" });

    const isDateDefined = !!props.date;

    const currentSavedUnixDate = props.date ?? 0;
    const currentDate = new Date(currentSavedUnixDate || 0);
    const displayDate = currentDate.toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    // outer view used for proper positioning
    return (
        <View style={props.isDateFlex ? styles.dateFlex : {}}>
            <View style={isDateDefined ? (props.showDateLeft ? styles.dateDefinedLeft : {}) : styles.dateNotDefined}>
                {isDateDefined ? (
                    <Text style={styles.headerText}>{displayDate}</Text>
                ) : (
                    <Text style={styles.headerText}>{t_dateColon}</Text>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    headerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    dateFlex: {
        flex: 1,
        alignItems: "flex-end",
    },
    dateNotDefined: {
        minWidth: "25vw",
    },
    dateDefinedLeft: {
        marginRight: DesignConstants.PDF.GAP_S,
    },
});

export default PDFDate;
