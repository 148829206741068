import React, { useMemo } from "react";
import { shuffle } from "lodash";
import { TestDetailsWord, UserOwnCard, VerbtrainingSelectedWord } from "p6m-p6u";
import { ConnectColors } from "../../../../../../constants/ConnectColors";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";

import { Wrapper } from "../../StyledComponents";
import { Circle, ConnectOuterWrapper, ConnectWord, ConnectWrapper, RemoveIcon, WordsColumn } from "./StyledComponents";

export interface DetailsConnectWordsProps {
    words: Array<TestDetailsWord | VerbtrainingSelectedWord | UserOwnCard>;
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
}

interface ConnectElement {
    word: string;
    index: number;
    id: string;
}

const DetailsConnectWords: React.FC<DetailsConnectWordsProps> = (props) => {
    // useMemo is used to memoize the shuffled answers to prevent unnecessary re-shuffling on every render
    // ensures that the order of answers remains consistent unless the words prop changes
    const selectedWordsQuestions = useMemo(
        () =>
            props.words?.map((w, i) =>
                "id" in w && w.id ? { word: w.question ?? "", index: i, id: w.id } : { word: "", index: i, id: "" }
            ) ?? [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.words?.length]
    );

    const selectedWordsAnswers = useMemo(
        () =>
            shuffle(
                props.words?.map((w, i) =>
                    "id" in w && w.id ? { word: w.answer ?? "", index: i, id: w.id } : { word: "", index: i, id: "" }
                ) ?? []
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.words?.length]
    );

    const renderWordsColumn = (connectElements: Array<ConnectElement>, rightColumn?: boolean) => {
        return (
            <WordsColumn>
                {connectElements.map((connectElement) => {
                    if (connectElement.word) {
                        return (
                            <ConnectWrapper
                                rightColumn={rightColumn}
                                key={(rightColumn ? "answer" : "question") + connectElement.word}
                            >
                                <RemoveIcon
                                    name={"close"}
                                    onClick={() => props.onRemoveWord(connectElement.id)}
                                />
                                <ConnectWord>{connectElement.word}</ConnectWord>
                                <Circle color={ConnectColors[connectElement.index % 5]} />
                            </ConnectWrapper>
                        );
                    } else {
                        return <div />;
                    }
                })}
            </WordsColumn>
        );
    };

    return (
        <Wrapper>
            {props.words?.length > 0 && (
                <ConnectOuterWrapper>
                    {renderWordsColumn(selectedWordsQuestions)}
                    {renderWordsColumn(selectedWordsAnswers, true)}
                </ConnectOuterWrapper>
            )}
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsConnectWords;
