import React, { FunctionComponent } from "react";
import "../../assets/phase6-icon-font/p6-icon-font.css";

export type TIconNames =
    | "plus"
    | "family"
    | "paint"
    | "chevron-left"
    | "chevron-up"
    | "chevron-down"
    | "chevron-right"
    | "keyboard-icon-none"
    | "keyboard-icon"
    | "keys"
    | "preview"
    | "vocabulary-list-unset"
    | "magic-wand"
    | "time"
    | "reports-family"
    | "reports-group"
    | "reports-add-user"
    | "test"
    | "download"
    | "pin"
    | "history"
    | "flowchart"
    | "genius"
    | "clock"
    | "translate-set"
    | "translate"
    | "cards-2"
    | "hand"
    | "compass"
    | "cube"
    | "cards"
    | "p6"
    | "change-avatar"
    | "notification"
    | "alert"
    | "exercise-failed"
    | "exercise-ok"
    | "info-Kopie"
    | "school_building_1-Kopie"
    | "login"
    | "undone"
    | "sent"
    | "time_1"
    | "overview"
    | "avatar"
    | "logout-Kopie-36"
    | "logout-Kopie-35"
    | "info"
    | "school_building_1"
    | "school_building_2"
    | "school_building"
    | "star-favourit-set"
    | "star-favourit"
    | "my-school"
    | "my-school-set"
    | "logout-Kopie-32"
    | "edit-avatar"
    | "share-ios"
    | "data-privacy-best"
    | "7-in-10-false"
    | "user-unconfirmed"
    | "first-steps"
    | "flag"
    | "leaderboard"
    | "placement"
    | "participants"
    | "phase-up-down"
    | "darkmode"
    | "achievement-task"
    | "data-protection-on"
    | "data-protection-awaiting"
    | "data-protection-none"
    | "annotation-alt"
    | "child-unlock"
    | "child-lock"
    | "cup-learned"
    | "mail"
    | "skip-card"
    | "wrong"
    | "correct"
    | "close"
    | "special-character"
    | "context-menu"
    | "speaker"
    | "arrow-both"
    | "arrow-right"
    | "arrow-left"
    | "leaderboard-nav"
    | "sync"
    | "check-answer"
    | "arrow-back-mobile"
    | "phase-interval"
    | "help"
    | "school-alt"
    | "audio"
    | "settings-alt"
    | "mic"
    | "edit"
    | "star-plus"
    | "star-minus"
    | "search-book"
    | "logout-Kopie-5"
    | "documents"
    | "unlock"
    | "lock"
    | "reset-alt"
    | "logout"
    | "reset"
    | "trash"
    | "share"
    | "menu"
    | "add"
    | "add-to-library"
    | "feedback"
    | "feedback-2"
    | "activation"
    | "annotation"
    | "rocket"
    | "search"
    | "three-stars"
    | "two-stars"
    | "one-star"
    | "no-star"
    | "clipboard"
    | "reports"
    | "library"
    | "cart"
    | "user-avatar"
    | "user"
    | "settings"
    | "learn"
    | "group"
    | "appearance"
    | "school"
    | "house"
    | "dictionary"
    | "cup"
    | "refresh"
    | "sliders";

type Props = {
    name: TIconNames;
    className?: string;
} & any;

export const renderAllIcons = () => {
    return (
        <div style={{ fontSize: 50, flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
            <PhaseSixIcon name={"plus"} />
            <PhaseSixIcon name={"paint"} />
            <PhaseSixIcon name={"family"} />
            <PhaseSixIcon name={"chevron-left"} />
            <PhaseSixIcon name={"chevron-up"} />
            <PhaseSixIcon name={"chevron-down"} />
            <PhaseSixIcon name={"chevron-right"} />
            <PhaseSixIcon name={"keyboard-icon-none"} />
            <PhaseSixIcon name={"keyboard-icon"} />
            <PhaseSixIcon name={"keys"} />
            <PhaseSixIcon name={"preview"} />
            <PhaseSixIcon name={"vocabulary-list-unset"} />
            <PhaseSixIcon name={"magic-wand"} />
            <PhaseSixIcon name={"time"} />
            <PhaseSixIcon name={"reports-family"} />
            <PhaseSixIcon name={"reports-group"} />
            <PhaseSixIcon name={"reports-add-user"} />
            <PhaseSixIcon name={"test"} />
            <PhaseSixIcon name={"download"} />
            <PhaseSixIcon name={"pin"} />
            <PhaseSixIcon name={"history"} />
            <PhaseSixIcon name={"flowchart"} />
            <PhaseSixIcon name={"genius"} />
            <PhaseSixIcon name={"clock"} />
            <PhaseSixIcon name={"translate-set"} />
            <PhaseSixIcon name={"translate"} />
            <PhaseSixIcon name={"cards-2"} />
            <PhaseSixIcon name={"hand"} />
            <PhaseSixIcon name={"compass"} />
            <PhaseSixIcon name={"cube"} />
            <PhaseSixIcon name={"cards"} />
            <PhaseSixIcon name={"p6"} />
            <PhaseSixIcon name={"change-avatar"} />
            <PhaseSixIcon name={"notification"} />
            <PhaseSixIcon name={"alert"} />
            <PhaseSixIcon name={"exercise-failed"} />
            <PhaseSixIcon name={"exercise-ok"} />
            <PhaseSixIcon name={"info-Kopie"} />
            <PhaseSixIcon name={"school_building_1-Kopie"} />
            <PhaseSixIcon name={"login"} />
            <PhaseSixIcon name={"undone"} />
            <PhaseSixIcon name={"sent"} />
            <PhaseSixIcon name={"time_1"} />
            <PhaseSixIcon name={"overview"} />
            <PhaseSixIcon name={"avatar"} />
            <PhaseSixIcon name={"logout-Kopie-36"} />
            <PhaseSixIcon name={"logout-Kopie-35"} />
            <PhaseSixIcon name={"info"} />
            <PhaseSixIcon name={"school_building_1"} />
            <PhaseSixIcon name={"school_building_2"} />
            <PhaseSixIcon name={"school_building"} />
            <PhaseSixIcon name={"star-favourit-set"} />
            <PhaseSixIcon name={"star-favourit"} />
            <PhaseSixIcon name={"my-school"} />
            <PhaseSixIcon name={"my-school-set"} />
            <PhaseSixIcon name={"logout-Kopie-32"} />
            <PhaseSixIcon name={"edit-avatar"} />
            <PhaseSixIcon name={"share-ios"} />
            <PhaseSixIcon name={"data-privacy-best"} />
            <PhaseSixIcon name={"7-in-10-false"} />
            <PhaseSixIcon name={"user-unconfirmed"} />
            <PhaseSixIcon name={"first-steps"} />
            <PhaseSixIcon name={"flag"} />
            <PhaseSixIcon name={"leaderboard"} />
            <PhaseSixIcon name={"placement"} />
            <PhaseSixIcon name={"participants"} />
            <PhaseSixIcon name={"phase-up-down"} />
            <PhaseSixIcon name={"darkmode"} />
            <PhaseSixIcon name={"achievement-task"} />
            <PhaseSixIcon name={"data-protection-on"} />
            <PhaseSixIcon name={"data-protection-awaiting"} />
            <PhaseSixIcon name={"data-protection-none"} />
            <PhaseSixIcon name={"annotation-alt"} />
            <PhaseSixIcon name={"child-unlock"} />
            <PhaseSixIcon name={"child-lock"} />
            <PhaseSixIcon name={"cup-learned"} />
            <PhaseSixIcon name={"mail"} />
            <PhaseSixIcon name={"skip-card"} />
            <PhaseSixIcon name={"wrong"} />
            <PhaseSixIcon name={"correct"} />
            <PhaseSixIcon name={"close"} />
            <PhaseSixIcon name={"special-character"} />
            <PhaseSixIcon name={"context-menu"} />
            <PhaseSixIcon name={"speaker"} />
            <PhaseSixIcon name={"arrow-both"} />
            <PhaseSixIcon name={"arrow-right"} />
            <PhaseSixIcon name={"arrow-left"} />
            <PhaseSixIcon name={"leaderboard-nav"} />
            <PhaseSixIcon name={"sync"} />
            <PhaseSixIcon name={"check-answer"} />
            <PhaseSixIcon name={"arrow-back-mobile"} />
            <PhaseSixIcon name={"phase-interval"} />
            <PhaseSixIcon name={"help"} />
            <PhaseSixIcon name={"school-alt"} />
            <PhaseSixIcon name={"audio"} />
            <PhaseSixIcon name={"settings-alt"} />
            <PhaseSixIcon name={"mic"} />
            <PhaseSixIcon name={"edit"} />
            <PhaseSixIcon name={"star-plus"} />
            <PhaseSixIcon name={"star-minus"} />
            <PhaseSixIcon name={"search-book"} />
            <PhaseSixIcon name={"logout-Kopie-5"} />
            <PhaseSixIcon name={"documents"} />
            <PhaseSixIcon name={"unlock"} />
            <PhaseSixIcon name={"lock"} />
            <PhaseSixIcon name={"reset-alt"} />
            <PhaseSixIcon name={"logout"} />
            <PhaseSixIcon name={"reset"} />
            <PhaseSixIcon name={"trash"} />
            <PhaseSixIcon name={"share"} />
            <PhaseSixIcon name={"menu"} />
            <PhaseSixIcon name={"add"} />
            <PhaseSixIcon name={"add-to-library"} />
            <PhaseSixIcon name={"feedback"} />
            <PhaseSixIcon name={"feedback-2"} />
            <PhaseSixIcon name={"activation"} />
            <PhaseSixIcon name={"annotation"} />
            <PhaseSixIcon name={"rocket"} />
            <PhaseSixIcon name={"search"} />
            <PhaseSixIcon name={"three-stars"} />
            <PhaseSixIcon name={"two-stars"} />
            <PhaseSixIcon name={"one-star"} />
            <PhaseSixIcon name={"no-star"} />
            <PhaseSixIcon name={"clipboard"} />
            <PhaseSixIcon name={"reports"} />
            <PhaseSixIcon name={"library"} />
            <PhaseSixIcon name={"cart"} />
            <PhaseSixIcon name={"user-avatar"} />
            <PhaseSixIcon name={"user"} />
            <PhaseSixIcon name={"settings"} />
            <PhaseSixIcon name={"learn"} />
            <PhaseSixIcon name={"group"} />
            <PhaseSixIcon name={"appearance"} />
            <PhaseSixIcon name={"school"} />
            <PhaseSixIcon name={"house"} />
            <PhaseSixIcon name={"dictionary"} />
            <PhaseSixIcon name={"cup"} />
            <PhaseSixIcon name={"refresh"} />
            <PhaseSixIcon name={"sliders"} />
        </div>
    );
};

const PhaseSixIcon: FunctionComponent<Props> = (props) => {
    const { name, className, color, ...restProps } = props;
    let style = {};
    if (color) {
        style = { color: color };
    }
    return (
        <i
            className={`p6-icon-${name} ${className || ""}`}
            style={style}
            {...restProps}
        ></i>
    );
};

export default PhaseSixIcon;
