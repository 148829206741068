import { Image, StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import DesignConstants from "../../../../constants/DesignConstants";
import logo from "../../../../assets/img/p6-logo-cut.png";
import PDFGoodLuckImage from "../../pdfGoodLuckImage/PDFGoodLuckImage";
import { GoodLuckNames } from "../../../../enums/settings";

interface PDFLogoOrLuckProps {
    showTop: boolean;
    showGoodLuck: boolean;
    goodLuckImageName: GoodLuckNames;
}

const PDFLogoOrLuck: React.FC<PDFLogoOrLuckProps> = (props: PDFLogoOrLuckProps) => {
    const logoWidth = 3;
    const logoHeight = (logoWidth / 1174) * 330; // based on original image size

    const renderLogo = () => {
        return (
            <View style={props.showTop ? styles.logoLuckWrapperTop : styles.logoLuckWrapper}>
                <Image
                    src={logo}
                    style={{
                        height: logoHeight + "cm",
                        width: logoWidth + "cm",
                    }}
                />
            </View>
        );
    };

    const renderGoodLuck = () => {
        return (
            <View style={props.showTop ? styles.logoLuckWrapperTop : styles.logoLuckWrapper}>
                <PDFGoodLuckImage
                    goodLuckImageName={props.goodLuckImageName}
                    isInHeader
                    isInHeaderTopRow={props.showTop}
                />
            </View>
        );
    };

    const renderGoodLuckOrLogo = () => {
        if (props.showGoodLuck) {
            return renderGoodLuck();
        } else {
            return renderLogo();
        }
    };

    return renderGoodLuckOrLogo();
};

const styles = StyleSheet.create({
    logoLuckWrapper: {
        alignItems: "flex-end",
        alignSelf: "flex-start",
        paddingHorizontal: DesignConstants.PDF.GAP_XXS,
    },
    logoLuckWrapperTop: {
        alignSelf: "flex-end",
        paddingHorizontal: DesignConstants.PDF.GAP_XXS,
    },
});

export default PDFLogoOrLuck;
