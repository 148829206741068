import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const StandardWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WordAndScoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.M};

    &:first-child .word-row {
        border-top-left-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
        border-top-right-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    }

    &:last-child .word-row {
        border-bottom: none;
        border-bottom-left-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
        border-bottom-right-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    }
`;

export const StandardWordRow = styled.div`
    width: 100%;
    display: flex;
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHTEST_GREY};
    background: ${DesignConstants.COLORS.WHITE};
`;

export const WordWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.S};
    border-right: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHTEST_GREY};
    cursor: pointer;
`;

export const QuestionAndAnswer = styled.div`
    display: flex;
    flex: 1;
`;

export const Word = styled.div`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.XS};
    border-top: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.WHITE};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.WHITE};

    &.blur {
        opacity: ${DesignConstants.OPACITY.HALF};
        font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
        margin-right: ${DesignConstants.SPACES.S};
        border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.MID_GREY};
        flex: 1;
    }
`;

export const WordIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    cursor: pointer;
`;

export const WordControl = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M};
`;
