import { BandUnit, TeacherTestDetails, TestDetailsItem } from "p6m-p6u";
import { useEffect, useState } from "react";
import { getRequest } from "../helpers/networkHelper";

const _getUnitIds = (selectedUnits: TestDetailsItem[]) => {
    const selectedUnitsIds: string[] = [];

    selectedUnits.forEach((unit) => {
        unit.selectedWords?.forEach((word) => {
            if ("unit_id" in word && word.unit_id) {
                selectedUnitsIds.push(word.unit_id);
            }
        });
    });

    return selectedUnitsIds;
};

const _getCorrespondingBandUnits = (bandUnits: BandUnit[], selectedUnitsIds: string[]) => {
    const foundBandUnits = selectedUnitsIds.reduce((acc, unitId) => {
        const foundUnit = bandUnits.find((bandUnit) => bandUnit.id?.toString() === unitId?.toString());

        return foundUnit ? [...acc, foundUnit] : acc;
    }, [] as BandUnit[]);

    return foundBandUnits;
};

const _removeDuplicates = (selectedUnits: BandUnit[]) => {
    return selectedUnits.filter((item, index, originalUnits) => index === originalUnits.findIndex((t) => t.id === item.id));
};

const _getUnitsForPDFTest = (bandUnits: BandUnit[], exercises: TestDetailsItem[]) => {
    const selectedUnitsIds = _getUnitIds(exercises);

    const selectedUnits = _getCorrespondingBandUnits(bandUnits, selectedUnitsIds);

    return _removeDuplicates(selectedUnits).sort((a, b) => (a.order_id ?? 0) - (b.order_id ?? 0));
};

const usePDFUnits = (testDetails?: TeacherTestDetails) => {
    const [pdfUnits, setPdfUnits] = useState<BandUnit[]>([]);

    useEffect(() => {
        const fetchUnits = async () => {
            const exercises = testDetails?.content;
            const articleId = testDetails?.articleId;

            if (!exercises || !articleId) return;

            try {
                const bandUnitsResponse = await getRequest("p6u/get-units-for-band/", { bandId: articleId });
                const pdfUnits = _getUnitsForPDFTest(bandUnitsResponse.data, exercises) ?? [];
                setPdfUnits(pdfUnits);
            } catch (error) {
                setPdfUnits([]);
            }
        };

        fetchUnits();
    }, [testDetails?.content, testDetails?.articleId]);

    return { pdfUnits };
};

export default usePDFUnits;
