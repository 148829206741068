import { DateTimePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const Wrapper = styled.div<{ isEditing?: boolean; disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    ${({ isEditing }) => isEditing && "flex: 1"};
    gap: ${DesignConstants.SPACES.XS};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const DateTimeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

export const DateText = styled.p<{ isPlaceholder?: boolean; disabled?: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-family: inherit;
    color: ${({ isPlaceholder }) =>
        isPlaceholder ? DesignConstants.COLORS.MID_GREY : DesignConstants.COLORS.DARK_GREY};
    padding: ${DesignConstants.SPACES.XS} 0;
    border-bottom: ${({ isPlaceholder }) =>
        isPlaceholder ? `${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY}` : "none"};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const StyledKeyboardDateTimePicker = styled(DateTimePicker)`
    width: 100%;
    font-family: inherit;
    font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};

    > div {
        border-radius: 0;
    }

    input {
        padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
        font-size: ${DesignConstants.FONT_SIZES.M};
        color: ${DesignConstants.COLORS.DARK_GREY};
        min-width: ${DesignConstants.SPACES.XXXL}; // to make sure date is not cut off
    }

    &.invalid .MuiOutlinedInput-notchedOutline {
        border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.RED}; // error line styling
    }

    .MuiOutlinedInput-root {
        // input underline
        & fieldset {
            // inactive
            border-width: 0 0 1px 0;
            color: ${DesignConstants.COLORS.MID_GREY};
        }

        &.Mui-focused fieldset {
            //input focused
            border-width: 0 0 1px 0;
            border-color: ${DesignConstants.COLORS.P6_ORANGE};
        }
    }

    .MuiButtonBase-root {
        // calendar icon button
        &:hover {
            background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
            color: ${DesignConstants.COLORS.P6_ORANGE};
        }

        & .MuiSvgIcon-root {
            font-size: ${DesignConstants.FONT_SIZES.XL};
        }
    }
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const StyledIcon = styled(PhaseSixIcon)<{ disabled?: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
    opacity: ${({ disabled }) => disabled && DesignConstants.OPACITY.HALF};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
