import { GoodLuckImage } from "p6m-p6u";
import { GoodLuckNames } from "../enums/settings";
import { goodLuckImages } from "../constants/GoodLuckConstants";
import { settingsDefault } from "../config/settingsDefault";

export const getGoodLuckImageByName = (imageName: GoodLuckNames): GoodLuckImage => {
    const selectedImage = goodLuckImages.find((img) => img.name === imageName);

    if (selectedImage) return selectedImage;

    const defaultImg = goodLuckImages.find((img) => img.name === settingsDefault.additional.goodLuck.value);
    return defaultImg || goodLuckImages[0];
};
