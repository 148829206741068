import { TestQuestionModel } from "p6m-p6u";
import { Exercises, ExerciseTypes } from "../../../../../../enums/exercises";
import DetailsCreateText from "../detailsCreateText/DetailsCreateText";
import DetailsWriteSentence from "../detailsWriteSentence/DetailsWriteSentence";

export interface DetailsTextInputProps {
    hasWords: boolean;
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
    currentExerciseModel: TestQuestionModel;
    showScores: boolean;
}

const DetailsTextInput = (props: DetailsTextInputProps) => {
    return props.currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.WRITE_SENTENCE) ? (
        <DetailsWriteSentence
            hasWords={props.hasWords}
            onRemoveWord={props.onRemoveWord}
            isSelectExerciseVocabularyOpen={props.isSelectExerciseVocabularyOpen}
            onClickAddVocabularyButton={props.onClickAddVocabularyButton}
            showScores={props.showScores}
            currentExerciseModel={props.currentExerciseModel}
        />
    ) : (
        <DetailsCreateText
            hasWords={props.hasWords}
            onRemoveWord={props.onRemoveWord}
            isSelectExerciseVocabularyOpen={props.isSelectExerciseVocabularyOpen}
            onClickAddVocabularyButton={props.onClickAddVocabularyButton}
        />
    );
};

export default DetailsTextInput;
