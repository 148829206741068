import React, { useMemo } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { shuffle } from "lodash";
import DesignConstants from "../../../constants/DesignConstants";
import { GoodLuckSettings, TestDetailsItem } from "p6m-p6u";
import PDFTable from "../pdfSubComponents/PDFTable";
import { PDFGoodLuckImageWrapper } from "../pdfGoodLuckImage/PDFGoodLuckImageWrapper";
import { createExerciseKey, sanitizePDFText } from "../../../helpers/PDFHelper";

interface PDFConnectWordsExerciseProps {
    exercise: TestDetailsItem;
    goodLuckSettings: GoodLuckSettings;
    isLastExercise: boolean;
    isSolution?: boolean;
}

const PDFConnectWordsExercise: React.FC<PDFConnectWordsExerciseProps> = (props) => {
    const connectKey = useMemo(() => {
        return createExerciseKey(props.exercise?.selectedWords ?? []);
    }, [props.exercise?.selectedWords]); // to avoid duplicates when switching between A and B

    const renderConnectWords = () => {
        const selectedWordsQuestions =
            props.exercise.selectedWords?.map((w) => ("id" in w && w.id ? w.question : "")) ?? [];
        const selectedWordsAnswers =
            props.exercise.selectedWords?.map((w) => ("id" in w && w.id ? w.answer : "")) ?? [];

        const renderWordsColumn = (words: Array<string | undefined>, isLeftColumn: boolean) => {
            const columnKey = words.join();
            return (
                words?.length > 0 && (
                    <View style={styles.column}>
                        {words.map((word, index) => {
                            const sanitizedWord = sanitizePDFText(word);
                            return (
                                <View
                                    style={styles.wordContainer}
                                    key={`${columnKey}_${connectKey}_connectBubble_${sanitizedWord}`}
                                >
                                    <View
                                        style={[styles.circleContainer, isLeftColumn ? { right: -12 } : { left: -12 }]}
                                    >
                                        {isLeftColumn && (
                                            <Text style={styles.circleContainerText}>
                                                {String.fromCharCode(65 + index)}
                                            </Text>
                                        )}
                                    </View>

                                    <Text
                                        key={`${connectKey}_word_${sanitizedWord}_white`}
                                        style={[styles.text, { color: "white" }]}
                                    >
                                        {sanitizedWord}
                                    </Text>
                                    {/* Necessary to center the text within the border: */}
                                    <Text
                                        key={`${connectKey}_word_${sanitizedWord}`}
                                        style={[styles.text, { position: "absolute", alignSelf: "center" }]}
                                    >
                                        {sanitizedWord}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                )
            );
        };

        return (
            <View style={styles.container}>
                {renderWordsColumn(selectedWordsQuestions, true)}
                {renderWordsColumn(shuffle(selectedWordsAnswers), false)}
            </View>
        );
    };

    return (
        <PDFGoodLuckImageWrapper
            isLastElement={props.isLastExercise}
            goodLuckSettings={props.goodLuckSettings}
            showImageBelowExercise
            isSolution={props.isSolution}
        >
            {!props.isSolution ? (
                renderConnectWords()
            ) : (
                <PDFTable
                    selectedWords={props.exercise?.selectedWords ?? []}
                    showAllQuestions
                    showAllAnswers
                />
            )}
        </PDFGoodLuckImageWrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XL,
        paddingHorizontal: DesignConstants.PDF.GAP_M,
    },
    column: {
        width: "100%",
        flexDirection: "column",
        gap: DesignConstants.PDF.GAP_XS,
        justifyContent: "space-between",
    },
    text: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        textAlign: "center",
    },
    wordContainer: {
        borderRadius: DesignConstants.PDF.BORDER_RADIUS_M,
        borderColor: DesignConstants.PDF.GREY,
        borderWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: DesignConstants.PDF.GAP_XS,
        paddingHorizontal: DesignConstants.PDF.GAP_S,
    },
    circleContainer: {
        border: 1,
        position: "absolute",
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 24,
        backgroundColor: "white",
    },
    circleContainerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.FONT_WEIGHTS.BOLD,
        textAlign: "center",
    },
});

export default PDFConnectWordsExercise;
