import styled from "styled-components";
import DesignConstants from "../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    overflow: hidden;
    gap: ${DesignConstants.SPACES.M};
`;

export const NoWordsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.L};
    line-height: ${DesignConstants.FONT_SIZES.L};
`;

export const InfoIconWrapper = styled.div`
    display: flex;
`;

export const StyledInfoIcon = styled(PhaseSixIcon)`
    display: flex;
    justify-content: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    width: ${DesignConstants.FONT_SIZES.XL};
    white-space: nowrap;
`;
