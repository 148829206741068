import { cloneDeep, isEqual, shuffle } from "lodash";
import {
    BVersion,
    ExerciseWordOrderShorthand,
    TeacherTestDetails,
    TestDetailsItem,
    TestDetailsOrderShorthand,
    TestDetailsWord,
    VerbtrainingSelectedWord,
} from "p6m-p6u";
import { settingsDefault } from "../config/settingsDefault";
import { TestVersionName } from "../enums/settings";

const createExerciseShorthand = (exercise: TestDetailsItem) => {
    let newSelectedWordsIds: string[] = [];
    if (exercise.selectedWords) {
        newSelectedWordsIds = exercise.selectedWords.reduce(
            (selectedWordsIds: string[], selectedWord: TestDetailsWord | VerbtrainingSelectedWord) => {
                if ("id" in selectedWord && selectedWord.id) selectedWordsIds.push(selectedWord.id);
                return selectedWordsIds;
            },
            []
        );
    }
    const newShorthand = {
        exerciseId: exercise.exerciseId ?? "",
        selectedWordIds: newSelectedWordsIds,
    };

    return newShorthand;
};

const findExistingShorthand = (searchId: string, exercises: TestDetailsOrderShorthand) => {
    return exercises.findIndex((exercise: TestDetailsItem) => {
        return exercise.exerciseId === searchId;
    });
};

const checkIfDifferentWords = (wordIds1: string[], wordIds2: string[]) => {
    if (wordIds1.length !== wordIds2.length) return true;

    const sortedWordIds1 = wordIds1.length > 0 ? [...wordIds1].sort() : [];
    const sortedWordIds2 = wordIds2.length > 0 ? [...wordIds2].sort() : [];

    return !isEqual(sortedWordIds1, sortedWordIds2);
};

const getFullBVersionSelectedWords = (wordIds: string[], exercise: TestDetailsItem) => {
    const bVersionSelectedWords: (TestDetailsWord | VerbtrainingSelectedWord)[] = [];
    const exerciseCopy = { ...exercise };

    wordIds?.forEach((wordId: string) => {
        if (exerciseCopy.selectedWords && exerciseCopy.selectedWords.length > 0) {
            const wordIndex: number = exerciseCopy.selectedWords?.findIndex(
                (word: TestDetailsWord | VerbtrainingSelectedWord) => {
                    return "id" in word && word.id && word.id === wordId;
                }
            );

            if (wordIndex !== -1) {
                bVersionSelectedWords.push(exerciseCopy.selectedWords[wordIndex]);
            }
        }
    });

    return bVersionSelectedWords;
};

const createBContent = (
    bGroupTest: TeacherTestDetails,
    existingBVersion: TestDetailsOrderShorthand,
    overwriteAll?: boolean
): BVersion => {
    const bGroupShorthand: TestDetailsOrderShorthand = [];

    if (bGroupTest.content?.length) {
        bGroupTest.content.forEach((exercise: TestDetailsItem) => {
            const foundExerciseBVersionIndex = findExistingShorthand(exercise.exerciseId ?? "", existingBVersion ?? []);

            let isReplacing = overwriteAll || foundExerciseBVersionIndex === -1;

            const bVersionShorthand: ExerciseWordOrderShorthand | undefined = isReplacing
                ? undefined
                : existingBVersion?.[foundExerciseBVersionIndex];

            const bVersionWordIndices =
                bVersionShorthand && bVersionShorthand.selectedWordIds.length > 0
                    ? bVersionShorthand.selectedWordIds
                    : [];

            if (!isReplacing) {
                //have the words been changed?

                const aVersionShorthand = createExerciseShorthand(exercise);
                const aVersionWordIndices = aVersionShorthand.selectedWordIds;

                const isWordsChanged = checkIfDifferentWords(bVersionWordIndices, aVersionWordIndices);

                isReplacing = isWordsChanged;
            }

            if (isReplacing) {
                // update / create B version

                const newSelectedWords = shuffle(exercise.selectedWords);
                exercise.selectedWords = newSelectedWords;

                // save new shuffle version
                bGroupShorthand.push(createExerciseShorthand(exercise));
            } else {
                // B version unchanged
                if (bVersionShorthand) bGroupShorthand.push(bVersionShorthand);

                exercise.selectedWords = getFullBVersionSelectedWords(bVersionWordIndices, exercise);
            }
        });
    }

    return { bVersionTestDetails: bGroupTest, shorthand: bGroupShorthand };
};

export const createBVersion = (teacherTestDetails: TeacherTestDetails, overwriteAll?: boolean): BVersion => {
    if (!teacherTestDetails) return { bVersionTestDetails: {}, shorthand: [] };

    const existingBVersion: TestDetailsOrderShorthand = [
        ...(teacherTestDetails?.settings?.header.abGroup.bVersion ?? []),
    ];

    let bGroupTest = cloneDeep(teacherTestDetails);

    if (!bGroupTest) return { bVersionTestDetails: {}, shorthand: [] };

    if (!bGroupTest?.settings) {
        bGroupTest.settings = cloneDeep(settingsDefault);
    }

    const bVersion = createBContent(bGroupTest, existingBVersion, overwriteAll);

    bGroupTest.content = bVersion.bVersionTestDetails.content;

    // set group to B
    bGroupTest.settings.header.abGroup.value = TestVersionName.B;

    return { bVersionTestDetails: bGroupTest, shorthand: bVersion.shorthand };
};
