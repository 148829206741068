import { useT } from "@transifex/react";
import { SCORING } from "../../../constants/ScoringConstants";
import { formatScoreToCommaSeparated, getExerciseScoreWidth } from "../../../helpers/ScoringHelper";
import { useScoring } from "../../../hooks/useScoring";
import CounterControl from "../../counterControl/CounterControl";
import { EditScoreDirection } from "../../../enums/directions";

import {
    ExerciseScoreWrapper,
    ExerciseScorePoints,
    CounterControlWrapper,
    ExerciseScoreText,
    StyledIcon,
} from "./StyledComponents";

interface ExerciseScoreCounterProps {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    scoreForExercise: number;
    isEditable: boolean;
    isPerWord: boolean;
}

const ExerciseScoreCounter = (props: ExerciseScoreCounterProps) => {
    const { adjustExerciseScore } = useScoring();

    const t = useT();

    const t_point = t("Point", { _tags: "ScoreCounter" });
    const t_points = t("Points", { _tags: "ScoreCounter" });

    const scoreLabel = props.scoreForExercise === 1 ? t_point : t_points;

    return (
        <ExerciseScoreWrapper
            isEditing={props.isEditing}
            isEditable={props.isEditable}
            onClick={() => props.isEditable && props.setIsEditing(true)}
        >
            <ExerciseScorePoints style={{ minWidth: getExerciseScoreWidth(props.scoreForExercise) }}>
                {formatScoreToCommaSeparated(props.scoreForExercise)}
            </ExerciseScorePoints>
            {!props.isPerWord && props.isEditable && props.isEditing && (
                <CounterControlWrapper>
                    <CounterControl
                        currentCount={props.scoreForExercise}
                        onIncrement={() => adjustExerciseScore({ direction: EditScoreDirection.INCREMENT })}
                        onDecrement={() => adjustExerciseScore({ direction: EditScoreDirection.DECREMENT })}
                        minPoints={SCORING.MIN_POINTS}
                    />
                </CounterControlWrapper>
            )}
            <ExerciseScoreText>{scoreLabel}</ExerciseScoreText>
            {props.isEditable && !props.isEditing && <StyledIcon name="edit-avatar" />}
        </ExerciseScoreWrapper>
    );
};

export default ExerciseScoreCounter;
