import { useT } from "@transifex/react";
import { GoodLuckNames } from "../enums/settings";

export const useGoodLuckTranslations = () => {
    const t = useT();

    const translations: Record<GoodLuckNames, string> = {
        [GoodLuckNames.SIDNEY_THUMBS_UP]: t("Sidney Thumbs Up", { _tags: "GoodLuckImages" }),
        [GoodLuckNames.SIDNEY_CLOVER]: t("Sidney with Clover", { _tags: "GoodLuckImages" }),
        [GoodLuckNames.SIDNEY_HEARTS]: t("Sidney with Hearts", { _tags: "GoodLuckImages" }),
        [GoodLuckNames.SIDNEY_THINKING]: t("Sidney Thinking", { _tags: "GoodLuckImages" }),
        [GoodLuckNames.HAPPY_CORGI]: t("Happy Corgi", { _tags: "GoodLuckImages" }),
        [GoodLuckNames.BLACK_CAT]: t("Black Cat", { _tags: "GoodLuckImages" }),
        [GoodLuckNames.RACCOON]: t("Raccoon", { _tags: "GoodLuckImages" }),
    };

    const getTranslatedGoodLuckImgName = (name: GoodLuckNames): string => translations[name] ?? "";

    return { getTranslatedGoodLuckImgName };
};
