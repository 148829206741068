import { useT } from "@transifex/react";
import { Exercises } from "../../../../../../enums/exercises";
import {
    ExerciseDescriptionVariants,
    Locales,
    SentenceVariants,
} from "../../../../../../hooks/useExerciseDescriptionStructure";
import { useTestCreationContext } from "../../../../../../context/TestCreationContext";
import useExerciseDescription from "../../../../../../hooks/useExerciseDescription";

import {
    TaskSuggestionSentenceWrapper,
    MoreSuggestionsWrapper,
    TaskSuggestionWrapper,
    TaskSuggestionSentence,
    StyledIcon,
    EditSuggestionWrapper,
} from "./StyledComponents";

interface ExerciseTaskSuggestionProps {
    exerciseType: Exercises;
    setCurrentText: (currentText: string) => void;
    setIsEditing: (isEditing: boolean) => void;
    triggerFlashingAnimation: () => void;
}

const ExerciseTaskSuggestion: React.FC<ExerciseTaskSuggestionProps> = (props) => {
    const { getExerciseSentences } = useExerciseDescription();
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();
    const t = useT();

    const t_moreSuggestions = t("Further Suggestions:", { _tags: "CreateTest, ExerciseTaskSuggestion" });

    const filteredSentences = getExerciseSentences(props.exerciseType, Locales.DE).filter(
        (entry: ExerciseDescriptionVariants) =>
            entry[SentenceVariants.LONG_SENTENCE] !== currentExerciseModel.extraComments
    );

    return (
        <TaskSuggestionWrapper>
            <MoreSuggestionsWrapper>{t_moreSuggestions}</MoreSuggestionsWrapper>
            <TaskSuggestionSentenceWrapper>
                {filteredSentences.map((entry: ExerciseDescriptionVariants) => (
                    <TaskSuggestionSentence
                        key={`task-suggestion-${entry[SentenceVariants.SHORT_SENTENCE].replace(/\s+/g, "_")}`}
                        onClick={() => {
                            props.setCurrentText(entry[SentenceVariants.LONG_SENTENCE]);
                            updateCurrentExerciseProperties({ extraComments: entry[SentenceVariants.LONG_SENTENCE] });
                            props.triggerFlashingAnimation();
                            setIsTestSynced(false);
                        }}
                    >
                        {entry[SentenceVariants.SHORT_SENTENCE]}
                    </TaskSuggestionSentence>
                ))}
                <EditSuggestionWrapper
                    onClick={() => {
                        props.setIsEditing(true);
                        props.setCurrentText("");
                    }}
                >
                    {`...`}
                    <StyledIcon
                        name={"edit-avatar"}
                        onClick={() => {}}
                    />
                </EditSuggestionWrapper>
            </TaskSuggestionSentenceWrapper>
        </TaskSuggestionWrapper>
    );
};

export default ExerciseTaskSuggestion;
