import { useT } from "@transifex/react/dist";
import { debounce } from "lodash";
import { StudentAnswerReviewItem, StudentQuestionAnswer } from "p6m-p6u";
import { useEffect, useRef, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import ExerciseTextArea from "../../../basic/exerciseTextArea/ExerciseTextArea";
import InputControlArea from "../inputControlArea/InputControlArea";

import { ExerciseRowContainer, QuestionText, WriteSentenceExerciseWrapper } from "./StyledComponents";

export interface WriteSentenceExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
    isMobile: boolean;
}

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// do debounce

const WriteSentenceExercise = (props: WriteSentenceExerciseProps) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "writeSentenceExercise,inputPlaceholder" });

    const [answersContent, setAnswersContent] = useState(
        props.studentAnswerItem.studentAnswers ? props.studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const updateItemAtPos = function (pos: number, value: string) {
        const newAnswers = [...answersContent];
        newAnswers[pos].studentAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        // console.log("using effect to call parent function");
        updateDataToParentDebounced(
            {
                ...props.studentAnswerItem,
                studentAnswers: answersContent,
            },
            props.updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(props.studentAnswerItem.studentAnswers!.length));

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };

    const focusExerciseAtIndex = function (index: number) {
        // console.log('focusing at index', index)
        if (index < props.studentAnswerItem.studentAnswers!.length) {
            refs.current[index]?.focus();
        }
    };

    const renderWriteSentenceParts = () => {
        if (!props.studentAnswerItem.studentAnswers) return;

        return props.studentAnswerItem.studentAnswers.map((item: StudentAnswerReviewItem, i: number) => {
            return (
                <ExerciseRowContainer
                    key={"key_" + item.id}
                    isMobile={props.isMobile}
                >
                    <QuestionText>{item.question ? item.question : item.answer}</QuestionText>
                    <ExerciseTextArea
                        key={i}
                        onChange={(event) => {
                            updateItemAtPos(i, event.target.value);
                        }}
                        type="text"
                        placeholder={t_placeholder}
                        ref={(element: any) => (refs.current[i] = element)}
                        value={item.studentAnswer}
                        onFocus={() => handleFocus(i)}
                    />
                </ExerciseRowContainer>
            );
        });
    };

    return (
        <WriteSentenceExerciseWrapper>
            {renderWriteSentenceParts()}
            {isMobileView && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={props.studentAnswerItem.studentAnswers?.length}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </WriteSentenceExerciseWrapper>
    );
};

export default WriteSentenceExercise;
