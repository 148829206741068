import styled from "styled-components";
import StyledButtonWithIcon from "../../../../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import DesignConstants from "../../../../../../constants/DesignConstants";

export const ReshuffleButton = styled(StyledButtonWithIcon)`
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const ShuffleButtonWrapper = styled.div`
    padding-top: ${DesignConstants.SPACES.XS};
`;
