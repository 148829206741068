import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import ArrowButton from "../../../basic/arrowButton/ArrowButton";
import DesignConstants from "../../../constants/DesignConstants";

export const ExerciseSelectorWrapper = styled.div``;

// shared

export const ExerciseName = styled.p`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    font-size: ${DesignConstants.FONT_SIZES.L};

    &.infoOpen {
        color: ${DesignConstants.COLORS.P6_ORANGE};
    }
`;

// SelectExerciseType

export const CreateSuggestionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    margin-bottom: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: block;
    }
`;

export const CreateSuggestionInfoText = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.L};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.XS};
    }
`;

export const BlankButtonNoBorder = styled(StyledButtonWithIcon)`
    min-width: fit-content;
    border: none !important;
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM} !important;
`;

export const SelectExerciseTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 ${DesignConstants.SPACES.S} 0;
    padding: ${DesignConstants.SPACES.L};
    gap: ${DesignConstants.SPACES.S};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    border-radius: 0 0 ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER} ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-top: none;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.S};
    }
`;

export const DropDownOuterContainer = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.L};
    padding: ${DesignConstants.SPACES.XS};
    margin: 0;
    align-items: center;
    justify-content: space-between;
    border: ${DesignConstants.BORDERS.THIN_GREY};
    min-height: 45px;
    background: white;
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    min-width: 180px;
    cursor: pointer;
    user-select: none;
    text-transform: none;
    display: flex;
    flex-direction: row;
    width: 100%;

    &.infoOpen {
        border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON} ${DesignConstants.BORDER_RADIUS.BUTTON} 0 0;
        border-bottom: none;
    }
`;

export const DropDownInnerContainer = styled.div`
    user-select: none;
    scroll-margin-top: 85px;
    flex-direction: row;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 ${DesignConstants.SPACES.XS} 0 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        scroll-margin-top: 70px;
    }
`;

export const CreateManuallyAndIconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};

    &.infoOpen {
        > p {
            color: ${DesignConstants.COLORS.P6_ORANGE};
        }
    }
`;

export const IconWrapper = styled.div`
    width: ${DesignConstants.SPACES.L};
    height: ${DesignConstants.SPACES.M};
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const StyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const OpenClosedWrapper = styled.div`
    cursor: pointer;
`;

export const StyledArrowButton = styled(ArrowButton)`
    padding: 0 !important;\
`;

export const NoExercisesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${DesignConstants.SPACES.XXL} 0;
`;

export const NoExercisesText = styled.p``;

export const BottomScrollDiv = styled.div``;