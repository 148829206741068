import React from "react";

import { useImperativeHandle, useRef } from "react";
import useFitText from "use-fit-text";

import { HiddenRadio, InnerContentWord, InnerContentWrapper, TileSelectedColor, TileWrapper } from "./StyledComponents";

export interface ConnectWordsTileProps {
    className?: string;
    highlighted?: boolean;
    word: string;
    type: "answer" | "question";
    onChange: (val: string) => any;
    selectedVal: string;
    onDrop: (event: React.DragEvent<HTMLDivElement>, type: "answer" | "question", word: string) => any;
    highlightColor: string;
    isMobile: boolean;
}

const ConnectWordsTile = React.forwardRef<HTMLInputElement, ConnectWordsTileProps>((props, passedRef) => {
    const hiddenCheckboxRef = useRef<HTMLInputElement>(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useImperativeHandle(passedRef, () => hiddenCheckboxRef.current!, [hiddenCheckboxRef.current]);

    const { fontSize, ref } = useFitText({ minFontSize: 10 });

    const { word, type, className, highlightColor, onChange, selectedVal, highlighted } = props;

    const handleClick = function () {
        onChange(word);
    };

    const localOnDragStart = function (event: React.DragEvent<HTMLDivElement>) {
        event.dataTransfer.setData("text", `${type}^*${word}`);
    };

    const localOnDragOver = function (event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
    };

    return (
        <TileWrapper
            className={`
                ${className ? className : ""}
                ${word === selectedVal ? "selected" : ""}
                ${highlighted ? "highlighted" : ""}
            `}
            onClick={handleClick}
            color={highlightColor}
            draggable={true}
            onDragStart={localOnDragStart}
            onDragOver={localOnDragOver}
            onDrop={(e) => props.onDrop(e, type, word)}
            isMobile={props.isMobile}
        >
            {highlighted && <TileSelectedColor color={highlightColor} />}
            <HiddenRadio
                type="radio"
                ref={hiddenCheckboxRef}
                checked={word === selectedVal}
                name={type}
                readOnly={true}
                value={word}
            />
            <InnerContentWrapper
                ref={ref}
                style={{ fontSize }}
            >
                <InnerContentWord>{word}</InnerContentWord>
            </InnerContentWrapper>
        </TileWrapper>
    );
});

export default ConnectWordsTile;
