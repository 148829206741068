import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const PDFConfigurationWrapper = styled.div`
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: ${DesignConstants.SPACES.XS};
    }
`;

export const StatusAndCloseWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} 0 ${DesignConstants.SPACES.XS};
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    margin-bottom: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-bottom: ${DesignConstants.SPACES.XXS};
    }
`;

export const Title = styled.h1`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.P6_ORANGE};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${DesignConstants.SPACES.M};
    row-gap: ${DesignConstants.SPACES.XS};
    justify-content: center;
    flex-wrap: wrap;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column-reverse;
    }
`;

export const ABVersionSwitchWrapper = styled.div`
    padding: ${DesignConstants.SPACES.S};
    border-bottom: none;
    background-color: ${DesignConstants.COLORS.WHITE};
`;

export const ABSwitch = styled.div`
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    display: flex;
    flex-direction: row;
    flex: 1;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
    cursor: pointer;
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
`;

export const ABSwitchButton = styled.p<{ isActive: boolean }>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding: ${DesignConstants.SPACES.XS};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    border: ${DesignConstants.BORDERS.SEMI_THIN} transparent;

    ${({ isActive }) =>
        isActive &&
        `background-color:  ${DesignConstants.COLORS.WHITE};
        border: ${DesignConstants.BORDERS.SEMI_THIN}  ${DesignConstants.COLORS.MID_GREY};
        color: ${DesignConstants.COLORS.MID_GREY};
        font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD}
        `};
`;

export const PreviewSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 5;
`;

export const ConfigSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.L};
    flex: 3;
    height: 100%;
    overflow-y: auto;

    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.M};
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.S};
    }
`;
