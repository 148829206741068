import styled from "styled-components";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../../../constants/DesignConstants";

export const PDFSettingsDropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.XS};
`;

export const PDFSettingsTopBottomWrapper = styled.div`
    display: flex;
`;

export const DropdownContainer = styled.div`
    position: relative;
    width: 232px;
`;

export const DropdownButton = styled.button<{ isDisabled: boolean }>`
    width: 100%;
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    background: ${DesignConstants.COLORS.WHITE};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    text-align: left;
    cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${DesignConstants.FONT_SIZES.M};
    color: ${DesignConstants.COLORS.DARK_GREY};
    opacity: ${(props) => (props.isDisabled ? DesignConstants.OPACITY.HALF : 1)};
    font-family: inherit;
`;

export const DropdownIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
`;

export const DropdownList = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: ${DesignConstants.COLORS.WHITE};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    margin-top: ${DesignConstants.SPACES.XXS};
    z-index: 1;
`;

export const DropdownItem = styled.div<{ isSelected: boolean }>`
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    cursor: pointer;
    font-size: ${DesignConstants.FONT_SIZES.M};
    background-color: ${(props) => props.isSelected && DesignConstants.COLORS.P6_ORANGE};
    color: ${(props) => props.isSelected && DesignConstants.COLORS.WHITE};

    &:hover {
        background-color: ${(props) => !props.isSelected && DesignConstants.COLORS.BACKGROUND_LIGHT};
    }
`;
