import * as React from "react";
import { InputHTMLAttributes } from "react";
import { useMediaQuery } from "react-responsive";

import { InputField, InputWrapper } from "./StyledComponents";

export interface ExerciseInputFieldProps {
    className?: string;
    type?: "text" | "number";
    isMobile?: boolean;
}

const ExerciseInputField = React.forwardRef<
    HTMLInputElement,
    ExerciseInputFieldProps & InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    const isMobileByScreenWidth: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const isMobileView = props.isMobile ?? isMobileByScreenWidth;

    return (
        <InputWrapper
            {...props}
            className={props.className ? props.className : ""}
            isMobile={isMobileView}
        >
            <InputField
                {...props}
                className={props.className}
                type={props.type || "text"}
                ref={ref}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
            />
        </InputWrapper>
    );
});

export default ExerciseInputField;
