import styled from "styled-components";
import DesignConstants from "../../../../../constants/DesignConstants";

export const PDFSettingDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    column-gap: ${DesignConstants.SPACES.M};
    row-gap: ${DesignConstants.SPACES.XS};
    flex-wrap: wrap;
`;

export const ToggleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
`;

export const ToggleStatus = styled.p<{ disabled?: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.M};
    line-height: 1;
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;
