import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";

export const OverallExerciseScoreWrapper = styled.div`
    display: flex;
    background: ${DesignConstants.COLORS.WHITE};
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHTEST_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    min-height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: end;
    align-self: flex-end;
`;
