import { StyleSheet, Text, View } from "@react-pdf/renderer";
import DesignConstants from "../../../../../constants/DesignConstants";

interface PDFGradingScaleCellProps {
    children: string;
    keyName?: string;
    isRowTitle?: boolean;
    borderBottom?: boolean;
    borderLeft?: boolean;
}

const PDFGradingScaleCell = (props: PDFGradingScaleCellProps) => {
    return (
        <View
            style={[
                styles.gradingElement,
                props.isRowTitle ? styles.gradingElementTitle : {},
                props.borderLeft ? styles.borderLeft : {},
                props.borderBottom ? styles.borderBottom : {},
            ]}
            key={props.keyName ?? props.children}
        >
            <Text style={styles.basicText}>{props.children}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    gradingElement: {
        alignItems: "center",
        justifyContent: "center",
        height: DesignConstants.PDF.GAP_M,
    },
    gradingElementTitle: {
        alignItems: "flex-start",
        justifyContent: "center",
        padding: DesignConstants.PDF.GAP_XS,
    },
    borderBottom: {
        borderBottomColor: DesignConstants.COLORS.DARK_GREY,
        borderBottomWidth: DesignConstants.PDF.BORDER_WIDTH_S,
    },
    borderLeft: {
        borderLeftColor: DesignConstants.COLORS.DARK_GREY,
        borderLeftWidth: DesignConstants.PDF.BORDER_WIDTH_S,
    },
    basicText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        lineHeight: 1,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
});

export default PDFGradingScaleCell;
