import PDFCollapsible from "../../pdfCollapsible/PDFCollapsible";
import { useT } from "@transifex/react";
import PDFGoodLuckSettingControl from "../../pdfSettingControl/specialisedControls/pdfGoodLuckSettingControl/PDFGoodLuckSettingControl";
import { SettingsChangeProps } from "../../PDFConfiguration";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";

interface PDFGoodLuckSettingsProps {
    teacherTestDetails: TeacherTestDetails;
    handleSettingsChange: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        settingsChangeProps: SettingsChangeProps<T, K>
    ) => void;
}

const PDFGoodLuckSettings = (props: PDFGoodLuckSettingsProps) => {
    const t = useT();

    const t_goodLuck = t("Additional", { _tags: "PDFConfiguration" });

    return (
        <PDFCollapsible title={t_goodLuck}>
            <PDFGoodLuckSettingControl
                teacherTestDetails={props.teacherTestDetails}
                handleSettingsChange={props.handleSettingsChange}
            />
        </PDFCollapsible>
    );
};

export default PDFGoodLuckSettings;
