import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import DesignConstants from "../../../constants/DesignConstants";
import { GoodLuckNames } from "../../../enums/settings";
import { getGoodLuckImageByName } from "../../../helpers/GoodLuckHelper";

interface PDFGoodLuckImageProps {
    goodLuckImageName: GoodLuckNames;
    isInHeader?: boolean;
    isInHeaderTopRow?: boolean;
}

export const _getGoodLuckImgSource = (goodLuckName: GoodLuckNames) => {
    const selectedImage = getGoodLuckImageByName(goodLuckName);
    return selectedImage.imageUrl;
};

export const PDFGoodLuckImage = (props: PDFGoodLuckImageProps) => {
    const t = useT();

    const t_goodLuck = t("Good Luck!", { _tags: "PDFConfiguration" });

    const dynamicWidth = props.isInHeaderTopRow
        ? DesignConstants.PDF.GAP_L
        : props.isInHeader
        ? DesignConstants.PDF.GAP_XL
        : "3.5cm";

    const image = _getGoodLuckImgSource(props.goodLuckImageName);
    if (!image) return null;

    return (
        <View
            style={{
                ...styles.goodLuckWrapper,
                flexDirection: props.isInHeaderTopRow ? "row" : "column",
                gap: props.isInHeaderTopRow ? DesignConstants.PDF.GAP_XS : DesignConstants.PDF.GAP_XXS,
                minWidth: dynamicWidth,
                height: !props.isInHeader ? "100%" : "auto",
            }}
            wrap={false}
        >
            <Text style={styles.goodLuckText}>{t_goodLuck.toUpperCase()}</Text>
            <View style={styles.goodLuckImageWrapper}>
                <Image
                    src={image}
                    style={{
                        ...styles.goodLuckImage,
                        width: dynamicWidth,
                    }}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    goodLuckWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        border: DesignConstants.BORDERS.MEDIUM,
    },
    goodLuckText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.FONT_WEIGHTS.BOLD,
    },
    goodLuckImageWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    goodLuckImage: {
        objectFit: "contain",
    },
});

export default PDFGoodLuckImage;
