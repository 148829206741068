import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import React from "react";
import DesignConstants from "../../../../constants/DesignConstants";

interface PDFABGroupProps {
    showAtTop: boolean;
    groupName?: string;
}

const PDFABGroup: React.FC<PDFABGroupProps> = (props: PDFABGroupProps) => {
    const t = useT();
    const t_groupColon = t("Group:", { _tags: "PDFConfiguration" });

    return (
        <View style={props.showAtTop ? styles.abWrapperTop : styles.abWrapper}>
            <Text style={props.showAtTop ? styles.abGroupTextTop : styles.abGroupText}>{t_groupColon}</Text>
            <Text style={props.showAtTop ? styles.abGroupTextTop : styles.abGroupText}>{props.groupName}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    abWrapper: {
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XXS,
    },
    abWrapperTop: {
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XXS,
        marginRight: DesignConstants.PDF.GAP_S,
    },
    abGroupText: {
        fontSize: DesignConstants.PDF.FONTSIZE_EMPHASIZED_HEADER,
        fontWeight: DesignConstants.PDF.SEMIBOLD,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    abGroupTextTop: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.SEMIBOLD,
        color: DesignConstants.COLORS.DARK_GREY,
    },
});

export default PDFABGroup;
