import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const ChevronWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
`;

export const ChevronButton = styled(PhaseSixIcon)<{ opacity: string; cursor: string }>`
    font-size: ${DesignConstants.FONT_SIZES.S};
    font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
    cursor: ${({ cursor }) => cursor};
    opacity: ${({ opacity }) => opacity};
`;
