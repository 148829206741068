import styled from "styled-components/macro";
import { PrimaryButton } from "p6-react-shared";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import FlagIcon from "../../../basic/flagIcon/FlagIcon";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    position: relative;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
`;

export const InfoHeader = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    padding: ${DesignConstants.SPACES.S} 0;

    ${({ isMobile }) => isMobile && `padding: ${DesignConstants.SPACES.XS} 0`};
`;

export const InfoHeaderInnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const MiddleContentWrapper = styled.div<{ isMobile: boolean }>`
    width: 90%;
    display: flex;
    flex: 1;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        justify-content: center;
        `};
`;

export const StyledFlagIcon = styled(FlagIcon)``;

export const AppInfoModalContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 70vh;
    padding: 1rem;

    > * {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ExerciseIndicatorContainer = styled.div<{ isMobile: boolean }>`
    cursor: pointer;
    padding-left: 24px;
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    > p {
        width: auto;
        margin: 0 0.5rem;
    }

    ${({ isMobile }) =>
        isMobile &&
        `text-align: left;
        padding: 0;`};
`;

export const CurrentExercise = styled.span`
    font-size: 1.5rem;
`;

export const TotalExercises = styled.span``;

export const OpenModalIcon = styled(SvgIcon)`
    width: 1.5rem;
    transform: rotate(90deg);
    margin: 0;
    vertical-align: middle;
`;

export const OverviewIcon = styled(SvgIcon)`
    width: 1.6rem;
    vertical-align: middle;
`;

export const OverviewCTAContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5rem;
    width: 90%;
    justify-content: center;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        justify-content: center;`};
`;

export const OverviewButtonContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: auto;
    margin: 0 2rem;

    ${({ isMobile }) => isMobile && `margin: 0 1.5rem;`};
`;

export const OverviewText = styled.p`
    text-transform: uppercase;
`;

export const SendNowButton = styled(PrimaryButton)`
    width: 100%;
    box-shadow: 0 5px 0 rgb(0 0 0 / 10%);
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: normal;
    padding: 0.5rem 0;

    > span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
`;

export const SendNowIcon = styled(SvgIcon)`
    width: 2rem;
    margin-left: 0.5rem;
`;

export const NoTimerDiv = styled.div`
    flex: 1;
`;

export const OverviewTitle = styled.p`
    font-size: 1.2rem;
`;

export const InfoSubtitle = styled.p<{ isMobile: boolean }>`
    text-align: center;
    font-size: 1.1rem;

    ${({ isMobile }) =>
        isMobile &&
        `color: inherit;
        font-size: 1.3rem;`};
`;

export const ModalOverlay = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    backgroundcolor: transparent;
    z-index: 30;
    height: 0;
`;

export const ModalContent = styled.div<{ isMobile: boolean }>`
    position: absolute;
    top: ${DesignConstants.SPACES.XS};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    min-width: 250px;
    max-width: 800px;
    boxshadow: rgb(0 0 0 / 10%) 1px 4px 0px;
    background-color: ${DesignConstants.COLORS.WHITE};

    box-shadow: 0 ${DesignConstants.SPACES.XXS} 0 0 ${DesignConstants.COLORS.LIGHTEST_GREY};

    ${({ isMobile }) => isMobile && `top: ${DesignConstants.SPACES.S};`};
`;

export const ScrollWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding-right: 1rem;
    min-height: 70px;
    position: absolute;
    bottom: 0;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.3rem;
`;

export const Squircle = styled(MainBackgroundSquircle)`
    position: relative;
`;

export const EmptyButtonPlaceholder = styled.div`
    width: 2rem;
`;
