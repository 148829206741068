import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React, { useMemo } from "react";
import PDFEmptyLines from "../pdfSubComponents/PDFEmptyLines";
import { TestDetailsItem } from "p6m-p6u";
import DesignConstants from "../../../constants/DesignConstants";
import PDFTable from "../pdfSubComponents/PDFTable";
import { createExerciseKey, sanitizePDFText } from "../../../helpers/PDFHelper";
import { ExerciseDirections } from "../../../enums/directions";
import { PDFGoodLuckImageWrapper } from "../pdfGoodLuckImage/PDFGoodLuckImageWrapper";
import { GoodLuckSettings } from "p6m-p6u";
import { SettingPosition } from "../../../enums/settings";

interface PDFWriteSentenceExerciseProps {
    exercise: TestDetailsItem;
    goodLuckSettings: GoodLuckSettings;
    isLastExercise: boolean;
    isSolution?: boolean;
}

const PDFWriteSentenceExercise: React.FC<PDFWriteSentenceExerciseProps> = (props) => {
    const writeSentenceKey = useMemo(() => {
        return createExerciseKey(props.exercise?.selectedWords ?? []);
    }, [props.exercise?.selectedWords]); // to avoid duplicates when switching between A and B

    const renderWordWithEmptyLines = () => {
        const selectedWordsQuestions =
            props.exercise.selectedWords?.map((w) =>
                "id" in w && w.id
                    ? sanitizePDFText(
                          props.exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION ? w.answer : w.question
                      )
                    : ""
            ) ?? [];

        return selectedWordsQuestions.map((word, i) => {
            const isLastWord = i === selectedWordsQuestions.length - 1;
            return (
                <View
                    style={i === 0 ? {} : { paddingTop: DesignConstants.PDF.GAP_M }}
                    key={`${writeSentenceKey}_sentence_${word}`}
                    wrap={false}
                >
                    <Text style={styles.text}>{word}</Text>
                    <PDFGoodLuckImageWrapper
                        isLastElement={props.isLastExercise && isLastWord}
                        goodLuckSettings={props.goodLuckSettings}
                        isSolution={props.isSolution}
                    >
                        <PDFEmptyLines
                            linesAmount={
                                props.isLastExercise &&
                                isLastWord &&
                                props.goodLuckSettings.show &&
                                props.goodLuckSettings.position === SettingPosition.BOTTOM
                                    ? 5
                                    : 3
                            }
                        />
                    </PDFGoodLuckImageWrapper>
                </View>
            );
        });
    };

    return !props.isSolution ? (
        <View>{renderWordWithEmptyLines()}</View>
    ) : (
        <PDFTable
            selectedWords={props.exercise?.selectedWords ?? []}
            showAllQuestions
            showAllAnswers
        />
    );
};

const styles = StyleSheet.create({
    text: {
        paddingHorizontal: DesignConstants.PDF.GAP_S,
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
    },
});

export default PDFWriteSentenceExercise;
