import * as React from "react";
import { Squircle } from "./StyledComponents";

export interface MainBackgroundSquircleProps {
    className?: string;
    children?: React.ReactNode;
    isMobile: boolean;
}

const MainBackgroundSquircle = React.forwardRef<HTMLDivElement, MainBackgroundSquircleProps>((props, ref) => {
    return (
        <Squircle
            {...props} // has to be first to potentially be overwritten
            className={props.className}
            ref={ref}
            isMobile={props.isMobile}
        >
            {props.children}
        </Squircle>
    );
});

export default MainBackgroundSquircle;
