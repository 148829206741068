import styled from "styled-components";
import ExerciseTextArea from "../../../basic/exerciseTextArea/ExerciseTextArea";

export const FreeTextExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
`;

export const BiggerTextArea = styled(ExerciseTextArea)`
    > textarea {
        height: 150px;
    }
`;

export const TextFieldStarterTextWrapper = styled.div`
    width: 100%;
    margin-top: 1rem;
`;
