import DesignConstants from "../../constants/DesignConstants";

import { ChevronWrapper, ChevronButton } from "./StyledComponents";

interface CounterControlProps {
    currentCount: number;
    onIncrement: () => void;
    onDecrement: () => void;
    minPoints: number;
    maxPoints?: number;
}

const CounterControl = (props: CounterControlProps) => {
    const isMaxReached = props.currentCount === props.maxPoints;
    const isMinReached = props.currentCount === props.minPoints;

    return (
        <ChevronWrapper>
            <ChevronButton
                name="chevron-up"
                onClick={isMaxReached ? undefined : props.onIncrement}
                aria-label="increase points"
                disabled={isMaxReached}
                opacity={isMaxReached ? DesignConstants.OPACITY.HALF : 1}
                cursor={isMaxReached ? "not-allowed" : "pointer"}
            />
            <ChevronButton
                name="chevron-down"
                onClick={isMinReached ? undefined : props.onDecrement}
                aria-label="decrease points"
                disabled={isMinReached}
                opacity={isMinReached ? DesignConstants.OPACITY.HALF : 1}
                cursor={isMinReached ? "not-allowed" : "pointer"}
            />
        </ChevronWrapper>
    );
};

export default CounterControl;
