import React, { useEffect } from "react";

import { getDateTimeFromMilliseconds } from "../../../helpers/DateTime";
import { logEventWithProps } from "../../../logging/Logger";
import { CoverImage } from "p6-react-shared";
import { TeacherTestDetails } from "p6m-p6u";
import UserContentCover from "../../../basic/userContentCover/UserContentCover";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useHistory } from "react-router-dom";

import {
    TestListElementHeader,
    ActionIconWrapper,
    TestListElementDetails,
    TestNameAndDateWrapper,
    TestListElementOpenActions,
    StyledArrowButton,
    TestName,
    TestDateAndShareWrapper,
    TestDate,
    DateAndShareStatusWrapper,
    TestActionsButtonsWrapper,
    BookCoverWrapper,
    NormalSizeIcon,
    AnimatedNormalSizeIcon,
    BiggerSizeIcon,
} from "./StyledComponents";
export interface TestListElementInnerWrapperHeaderProps {
    currentTab: "NONE" | "ONLINE" | "PDF";
    setCurrentTab: React.Dispatch<React.SetStateAction<"NONE" | "ONLINE" | "PDF">>;
    teacherTestDetails: TeacherTestDetails;
    index: number;
    testAlreadyShared: boolean;
    isOwnSubjectStillAvailable: boolean;
    setIsDuplicateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    areDetailsOpen: boolean;
    setAreDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestListElementInnerWrapperHeader: React.FC<TestListElementInnerWrapperHeaderProps> = ({
    currentTab,
    setCurrentTab,
    teacherTestDetails,
    index,
    testAlreadyShared,
    isOwnSubjectStillAvailable,
    setIsDuplicateModalOpen,
    setIsDeleteModalOpen,
    areDetailsOpen,
    setAreDetailsOpen,
}) => {
    const { isTestSharingInfoBeingLoaded, loadTestForEdition, canLogEvent } = useTestCreationContext();
    const history = useHistory();

    function toggleDetails() {
        if (!areDetailsOpen && currentTab === "NONE") {
            setCurrentTab("ONLINE");
        }
        setAreDetailsOpen(!areDetailsOpen);
    }

    async function handleTestEditRequest() {
        try {
            await loadTestForEdition(teacherTestDetails.id!);
            if (canLogEvent()) {
                logEventWithProps("Vokabeltest v2 Click Edit", {
                    testId: teacherTestDetails.id,
                });
            }
            history.push("/create/summary");
        } catch (e) {
            alert("Error while loading test " + e);
        }
    }

    useEffect(() => {
        if (index === 0) {
            toggleDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const BookCover = (
        <>
            {teacherTestDetails.bookInfo?.imagePath && (
                <BookCoverWrapper>
                    <CoverImage
                        imageId={"https://www.phase-6.de" + teacherTestDetails.bookInfo.imagePath}
                        size={"small"}
                    />
                </BookCoverWrapper>
            )}
            {teacherTestDetails.ownSubjectInfo?.primaryLang && (
                <BookCoverWrapper>
                    <UserContentCover
                        primaryLanguage={teacherTestDetails.ownSubjectInfo?.primaryLang}
                        secondaryLanguage={teacherTestDetails.ownSubjectInfo?.secondaryLang || ""}
                        small
                    />
                </BookCoverWrapper>
            )}
        </>
    );

    const TestDateAndShare = (
        <TestDateAndShareWrapper>
            {isTestSharingInfoBeingLoaded && <AnimatedNormalSizeIcon name={"sync"} />}
            {testAlreadyShared && <NormalSizeIcon name={"share"} />}
            <TestDate>
                {teacherTestDetails.updatedDate ? getDateTimeFromMilliseconds(teacherTestDetails.updatedDate) : ""}
            </TestDate>
        </TestDateAndShareWrapper>
    );

    const TestActionButtons = (
        <TestActionsButtonsWrapper className={isTestSharingInfoBeingLoaded ? "disabled" : ""}>
            <ActionIconWrapper
                onClick={testAlreadyShared ? () => {} : handleTestEditRequest}
                className={!isOwnSubjectStillAvailable ? "disabled" : ""}
            >
                <BiggerSizeIcon
                    name={"edit-avatar"}
                    color={testAlreadyShared ? "#00000030" : undefined}
                    className={testAlreadyShared ? "disabled" : ""}
                />
            </ActionIconWrapper>
            <ActionIconWrapper
                onClick={(e) => {
                    e.stopPropagation();
                    setIsDuplicateModalOpen(true);
                }}
                className={!isOwnSubjectStillAvailable ? "disabled" : ""}
            >
                <BiggerSizeIcon name={"duplicate"} />
            </ActionIconWrapper>
            <ActionIconWrapper
                onClick={(e) => {
                    e.stopPropagation();
                    setIsDeleteModalOpen(true);
                }}
            >
                <BiggerSizeIcon name={"trash"} />
            </ActionIconWrapper>
        </TestActionsButtonsWrapper>
    );

    const TestNameAndDate = (
        <TestNameAndDateWrapper>
            <TestName>{teacherTestDetails.name}</TestName>
            <DateAndShareStatusWrapper>
                {TestDateAndShare}
                {TestActionButtons}
            </DateAndShareStatusWrapper>
        </TestNameAndDateWrapper>
    );

    return (
        <TestListElementHeader onClick={toggleDetails}>
            <TestListElementDetails>
                {BookCover}
                {TestNameAndDate}
            </TestListElementDetails>
            <TestListElementOpenActions>
                <StyledArrowButton direction={areDetailsOpen ? "UP" : "DOWN"} />
            </TestListElementOpenActions>
        </TestListElementHeader>
    );
};

export default TestListElementInnerWrapperHeader;
