import styled from "styled-components";
import StyledButton from "../../../basic/styledButton/StyledButton";
import DesignConstants from "../../../constants/DesignConstants";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";

export const Wrapper = styled.div`
    padding-bottom: ${DesignConstants.SPACES.M};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-bottom: ${DesignConstants.SPACES.XXXL};
    }
`;

export const SyncStatusWrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.XS};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const ExercisesContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledButtonWithoutIcon = styled(StyledButton)`
    min-width: 30% !important;
`;

export const AddExerciseButton = styled(StyledButtonWithIcon)`
    align-self: start;
`;

export const ExerciseContentWrapper = styled.div``;

export const ExerciseExtraCommentsWrapper = styled.div`
    max-width: 80vw;
    min-width: min(500px, 80vw);
`;

export const ExerciseExtraComments = styled.p`
    text-align: center;
`;

export const ExerciseWordsWrapper = styled.div`
    user-select: none;
    min-width: min(500px, 80vw);
    padding: 0 ${DesignConstants.SPACES.S};
`;

export const ExerciseWordsList = styled.ul`
    margin-block: 0;
    padding-inline-start: 0;
`;

export const ExerciseWordItem = styled.li``;

export const ExerciseItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.XS};
`;

export const ExerciseItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
`;

export const ExerciseItemScoreWrapper = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    display: flex;
    flex-direction: row;
    gap: ${DesignConstants.SPACES.XXS};
    align-items: center;
`;

export const ExerciseItemScoreText = styled.p`
    text-align: right;
    min-width: ${DesignConstants.FONT_SIZES.XXL}; // matches the widest width from the getExerciseScoreWidth function
`;

export const ExercisePointsHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const TotalPointsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${DesignConstants.SPACES.XS};
`;

export const TotalPoints = styled.p`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    font-size: ${DesignConstants.FONT_SIZES.M};
    text-align: right;
`;

export const TotalPointsText = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
    text-align: right;
`;

export const ToggleSwitchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;
