import styled from "styled-components/macro";

export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: none;
`;

export const ExerciseTypeContainer = styled.div<{ isMobile: boolean }>`
    width: 90%;

    ${({ isMobile }) => isMobile && `width: 95%;`};
`;
