import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import { CustomStatusStyledIcon } from "../../StyledComponents";

export const TopDownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: ${DesignConstants.SPACES.XS};
    row-gap: ${DesignConstants.SPACES.S};
`;

export const LabelAndTopDownWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: ${DesignConstants.SPACES.XS};
    align-items: center;
    flex-wrap: wrap;
`;

export const SettingTitle = styled.p<{ disabled?: boolean }>`
    display: flex;
    flex-text: no-wrap;
    font-size: ${DesignConstants.FONT_SIZES.M};
    min-width: calc(${DesignConstants.SPACES.XXL}*2);
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const SettingStatusWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: ${DesignConstants.SPACES.XXS};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
    flex-wrap: wrap;
    justify-content: flex-end;
`;

export const RotatedP6Icon = styled(CustomStatusStyledIcon)`
    display: flex;
    transform: rotate(90deg);
    justify-content: center;
    align-items: center;
    width: ${DesignConstants.SIZES.ICON_XXS};
    height: ${DesignConstants.SIZES.ICON_XXS};
`;
