import React from "react";
import ExerciseEmptyLines from "../../detailsSubComponents/exerciseEmptyLines/ExerciseEmptyLines";
import ExerciseWordBox from "../../detailsSubComponents/exerciseWordBox/ExerciseWordBox";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";

import { Wrapper } from "../../StyledComponents";

interface DetailsCreateTextProps {
    hasWords: boolean;
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
}

const DetailsCreateText: React.FC<DetailsCreateTextProps> = (props) => {
    return (
        <Wrapper>
            {props.hasWords && (
                <>
                    <ExerciseWordBox onRemoveWord={props.onRemoveWord} />
                    <ExerciseEmptyLines linesAmount={6} />
                </>
            )}
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsCreateText;
