import { formatScoreToCommaSeparated, getExerciseScoreWidth } from "../../helpers/ScoringHelper";
import CounterControl from "../counterControl/CounterControl";

import { CounterControlWrapper, ScoreText, ScoreTextWrapper } from "./StyledComponents";

interface ScoreControllerProps {
    currentScore: number;
    isEditing: boolean;
    onIncrement: () => void;
    onDecrement: () => void;
    minPoints: number;
    maxPoints?: number;
    showSuffix?: boolean;
}

const ScoreController = (props: ScoreControllerProps) => {
    return (
        <>
            <ScoreTextWrapper>
                <ScoreText style={{ minWidth: getExerciseScoreWidth(props.currentScore) }}>
                    {formatScoreToCommaSeparated(props.currentScore)}
                </ScoreText>
                {props.showSuffix && "P."}
            </ScoreTextWrapper>

            {props.isEditing && (
                <CounterControlWrapper>
                    <CounterControl
                        currentCount={props.currentScore}
                        onIncrement={props.onIncrement}
                        onDecrement={props.onDecrement}
                        minPoints={props.minPoints}
                        maxPoints={props.maxPoints}
                    />
                </CounterControlWrapper>
            )}
        </>
    );
};

export default ScoreController;
