import styled from "styled-components/macro";
import { PrimaryButton } from "p6-react-shared";
import happyMonster from "../../../assets/lotties/monster_happy.json";
import thumbsUpMonster from "../../../assets/lotties/monster_thumb_up.json";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import DesignConstants from "../../../constants/DesignConstants";

export const happyMonsterAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: happyMonster,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const thumbsUpMonsterAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: thumbsUpMonster,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
`;

export const InfoWrapper = styled(MainBackgroundSquircle)<{ isMobile: boolean }>`
    width: 80%;
    padding: ${DesignConstants.SPACES.XL} ${DesignConstants.SPACES.XXXL};
    overflow: unset;
    display: flex;
    justify-content: space-between;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        padding: ${DesignConstants.SPACES.S};`};
`;

export const OutroMainScreenWrapper = styled.div`
    height: calc(100% - 15px);
`;

export const OutroSlidingItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
`;

export const OutroTitleWrapper = styled.div`
    margin: ${DesignConstants.SPACES.S} 0;
    width: 80%;
`;

export const OutroSubtitle = styled.h3`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    text-align: center;
    font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
    margin: 0;
`;

export const OutroTitle = styled.h3`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    text-align: center;
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
    margin: 0;
`;

export const OutroTextContent = styled.p<{ isMobile: boolean }>`
    text-align: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    width: 80%;
    line-height: 1.3;

    ${({ isMobile }) =>
        isMobile &&
        `width: 80%;
        line-height: 1.6;`};
`;

export const OutroCTA = styled(PrimaryButton)`
    width: unset;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.L};
    text-transform: uppercase;
    box-shadow: 0 5px 0 ${DesignConstants.COLORS.LIGHTEST_GREY};
    font-size: ${DesignConstants.FONT_SIZES.XL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
`;

export const NavigationDotsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 15px;
    align-items: center;
    justify-content: center;
`;

export const NavigationDot = styled.div`
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background: ${DesignConstants.COLORS.DARK_GREY};
    cursor: pointer;

    &.selected {
        background: ${(props) => props.theme.base.primaryColor};
    }
`;

export const StyledLogo = styled(SvgIcon)`
    height: ${DesignConstants.SPACES.XL};
`;

export const Divider = styled.div`
    width: 50%;
    height: 1px;
    border: ${DesignConstants.BORDERS.THIN_GREY};
`;
