// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { StudentTestContent, StudentTestSharingData, TeacherTestDetails } from "p6m-p6u";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { logEventWithProps } from "../../../logging/Logger";
import { cp } from "../../../config";
import TestListElementInnerWrapperHeader from "./TestListElementInnerWrapperHeader";
import TestListElementTabContent from "./TestListElementTabContent";
import TestListElementInfoModal from "./TestListElementInfoModal";
import { useHistory } from "react-router-dom";

import {
    TestListElementOuterWrapper,
    TestListElementInnerWrapper,
    OwnSubjectNotAvailableWarningWrapper,
    TestActionsWrapper,
    TabsWrapper,
    TabWrapper,
    PreviewWrapper,
    PreviewCTA,
} from "./StyledComponents";

export interface TestListElementProps {
    teacherTestDetails: TeacherTestDetails;
    index: number;
    isTestSharingInfoBeingLoaded: boolean;
}

const TestListElement: React.FC<TestListElementProps> = (props) => {
    const { teacherTestDetails } = props;

    const history = useHistory();

    const [currentTab, setCurrentTab] = useState<"NONE" | "ONLINE" | "PDF">("NONE");
    const [studentTestContent, setStudentTestContent] = useState<StudentTestContent>({});
    const [localSharingData, setLocalSharingData] = useState<StudentTestSharingData>({});
    const [testAlreadyShared, setTestAlreadyShared] = useState(false);

    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [copiedLinkModalOpen, setCopiedLinkModalOpen] = useState(false);

    const [teacherCommentIsInvalid, setTeacherCommentIsInvalid] = useState(false);

    const [isOwnSubjectStillAvailable, setIsOwnSubjectStillAvailable] = useState(true);
    const [areDetailsOpen, setAreDetailsOpen] = useState(false);

    const { studentTestContentWrapper, userOwnSubjects, openWarningModal, canLogEvent, loadTestForEdition } =
        useTestCreationContext();

    const t = useT();
    const t_OnlineT = t("Test (online)", { _tags: "CreateTest,TestListElement" });
    const t_PDFT = t("Test (pdf)", { _tags: "CreateTest,TestListElement" });
    const t_preview = t("Preview", { _tags: "CreateTest,TestListElement" });

    const t_errorWhileCreatingPreview = t("There were some errors while creating the Preview.", {
        _tags: "CreateTest,TestListElement",
    });
    const t_notAvailableOwnSubject = t("Your own created subject is not available. Some actions are not available.", {
        _tags: "CreateTest,TestListElement",
    });

    const linkToTest = cp.cfg.REACT_APP_P6_WEBSITE + "student/" + teacherTestDetails.id;

    const resetLocalSharingData = useCallback(
        (testData?: StudentTestContent) => {
            testData = testData || studentTestContentWrapper[teacherTestDetails.id!];
            setLocalSharingData({
                dueDate: testData.dueDate || 0,
                timeLimit: testData.timeLimit || "",
                teacherComment: testData.teacherComment || "",
            });
        },
        [studentTestContentWrapper, teacherTestDetails]
    );

    useEffect(() => {
        if (teacherTestDetails.id && studentTestContentWrapper.hasOwnProperty(teacherTestDetails.id)) {
            const testData = studentTestContentWrapper[teacherTestDetails.id];
            setStudentTestContent(testData);
            resetLocalSharingData(testData);
            // This is a required field
            if (testData.teacherComment) {
                setTestAlreadyShared(true);
            }
        }

        if (teacherTestDetails.ownSubjectInfo && teacherTestDetails.ownSubjectInfo.id) {
            const subject = userOwnSubjects.find((s) => s.id === teacherTestDetails.ownSubjectInfo!.id);
            setIsOwnSubjectStillAvailable(!!subject);
        }
    }, [
        studentTestContentWrapper,
        teacherTestDetails.id,
        teacherTestDetails.ownSubjectInfo,
        userOwnSubjects,
        resetLocalSharingData,
    ]);

    async function openPreview() {
        if (!teacherTestDetails.id) {
            openWarningModal(t_errorWhileCreatingPreview);
        }

        try {
            await loadTestForEdition(props.teacherTestDetails.id ?? "", true);

            if (canLogEvent()) {
                logEventWithProps("Opened preview", {
                    testId: props.teacherTestDetails.id,
                    origin: "overview",
                });
            }

            history.push("/create/preview", {
                urlBeforePreview: "/create",
            });
        } catch (e) {
            alert("Error while loading test " + e);
        }
    }

    function onShareTestRequested() {
        if ((localSharingData.teacherComment || "").trim().length < 1) {
            setTeacherCommentIsInvalid(true);
            return;
        } else {
            setIsShareModalOpen(true);
        }
    }

    const actionsContainer = (
        <TestActionsWrapper>
            <TabsWrapper>
                <TabWrapper
                    className={currentTab === "ONLINE" ? "selected" : ""}
                    onClick={() => {
                        setCurrentTab("ONLINE");
                    }}
                >
                    {t_OnlineT}
                </TabWrapper>
                <TabWrapper
                    className={currentTab === "PDF" ? "selected" : ""}
                    onClick={() => {
                        setCurrentTab("PDF");
                    }}
                >
                    {t_PDFT}
                </TabWrapper>
            </TabsWrapper>
            <PreviewWrapper>
                <PreviewCTA
                    icon={"preview"}
                    iconPosition={"RIGHT"}
                    buttonStyle={"BLANK"}
                    onClick={openPreview}
                >
                    {t_preview}
                </PreviewCTA>
            </PreviewWrapper>
        </TestActionsWrapper>
    );

    function copyTestLink() {
        navigator.clipboard.writeText(linkToTest);
        setCopiedLinkModalOpen(true);
        if (canLogEvent()) {
            logEventWithProps("VTG - Assignment Link Copied", {
                testId: teacherTestDetails.id,
            });
        }
    }

    return (
        <TestListElementOuterWrapper>
            <TestListElementInnerWrapper>
                <TestListElementInnerWrapperHeader
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    teacherTestDetails={teacherTestDetails}
                    index={props.index}
                    testAlreadyShared={testAlreadyShared}
                    isOwnSubjectStillAvailable={isOwnSubjectStillAvailable}
                    setIsDuplicateModalOpen={setIsDuplicateModalOpen}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    areDetailsOpen={areDetailsOpen}
                    setAreDetailsOpen={setAreDetailsOpen}
                />
                {!isOwnSubjectStillAvailable && (
                    <OwnSubjectNotAvailableWarningWrapper>
                        {t_notAvailableOwnSubject}
                    </OwnSubjectNotAvailableWarningWrapper>
                )}
                {areDetailsOpen && actionsContainer}
                {areDetailsOpen && currentTab !== "NONE" && (
                    <TestListElementTabContent
                        currentTab={currentTab}
                        teacherTestDetails={teacherTestDetails}
                        isOwnSubjectStillAvailable={isOwnSubjectStillAvailable}
                        localSharingData={localSharingData}
                        testAlreadyShared={testAlreadyShared}
                        teacherCommentIsInvalid={teacherCommentIsInvalid}
                        setTeacherCommentIsInvalid={setTeacherCommentIsInvalid}
                        setLocalSharingData={setLocalSharingData}
                        copyTestLink={copyTestLink}
                        onShareTestRequested={onShareTestRequested}
                        resetLocalSharingData={resetLocalSharingData}
                        studentTestContent={studentTestContent}
                        linkToTest={linkToTest}
                    />
                )}
            </TestListElementInnerWrapper>

            <TestListElementInfoModal
                isDuplicateModalOpen={isDuplicateModalOpen}
                teacherTestDetails={teacherTestDetails}
                setIsDuplicateModalOpen={setIsDuplicateModalOpen}
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                isShareModalOpen={isShareModalOpen}
                setIsShareModalOpen={setIsShareModalOpen}
                copiedLinkModalOpen={copiedLinkModalOpen}
                setCopiedLinkModalOpen={setCopiedLinkModalOpen}
                copyTestLink={copyTestLink}
                localSharingData={localSharingData}
            />
        </TestListElementOuterWrapper>
    );
};

export default TestListElement;
