import React, { useMemo } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { TeacherTestDetails } from "p6m-p6u";
import PDFFullTest from "../../../complex/pdfCreation/PDFFullTest";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { ensureReliableOverallScore } from "../../../helpers/ScoringHelper";
import usePDFUnits from "../../../hooks/usePDFUnits";

import { PDFViewWrapper } from "./StyledComponents";

interface PDFPreviewProps {
    testDetails?: TeacherTestDetails;
    isSolution?: boolean;
}

// change this comment to update the preview while developing:
//

const PDFPreview: React.FC<PDFPreviewProps> = (props) => {
    const { loadInitArticleIdData } = useTestCreationContext();

    const cleanedUpTestDetails = useMemo(
        () => ensureReliableOverallScore(props.testDetails ?? {}),
        [props.testDetails]
    );

    const { pdfUnits } = usePDFUnits(props.testDetails);

    return (
        <PDFViewWrapper>
            <PDFViewer style={{ width: "100%", height: "100%" }}>
                <PDFFullTest
                    testDetails={cleanedUpTestDetails}
                    bookArticleId={cleanedUpTestDetails?.articleId}
                    pdfUnits={pdfUnits}
                    loadInitArticleIdData={loadInitArticleIdData}
                    isSolution={props.isSolution}
                />
            </PDFViewer>
        </PDFViewWrapper>
    );
};

export default PDFPreview;
