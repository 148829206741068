import { useState } from "react";
import { useTestCreationContext } from "../../context/TestCreationContext";
import { getExerciseScoringByMode, getScoreForExercise } from "../../helpers/ScoringHelper";
import useClickOutside from "../../hooks/useClickOutside";
import PerWordScoreEditor from "./perWordScoreEditor/PerWordScoreEditor";
import ExerciseScoreCounter from "./exerciseScoreCounter/ExerciseScoreCounter";
import { useScoring } from "../../hooks/useScoring";

import { OverallExerciseScoreWrapper } from "./StyledComponents";

const ExerciseScore = () => {
    const { currentExerciseModel } = useTestCreationContext();
    const { adjustExerciseScore } = useScoring();

    const [isEditing, setIsEditing] = useState(false);

    const componentRef = useClickOutside(() => setIsEditing(false));

    const scoring = getExerciseScoringByMode(currentExerciseModel.questionMode ?? "");

    const scoreForExercise = getScoreForExercise(currentExerciseModel);
    const wordsCount = currentExerciseModel.wordsInProgress?.length ?? 0;
    const currentPointsPerWord = scoreForExercise / wordsCount;

    return (
        <OverallExerciseScoreWrapper ref={componentRef}>
            {scoring.storesScorePerWord && isEditing && (
                <PerWordScoreEditor
                    currentPointsPerWord={currentPointsPerWord}
                    adjustExerciseScore={adjustExerciseScore}
                />
            )}
            <ExerciseScoreCounter
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                scoreForExercise={scoreForExercise}
                isEditable={scoring.canEditOverallScore}
                isPerWord={scoring.storesScorePerWord}
            />
        </OverallExerciseScoreWrapper>
    );
};

export default ExerciseScore;
