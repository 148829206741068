// LIBRARIES
import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const SurvicateWrapper: React.FunctionComponent<any> = ({ children }) => {
    useCreateSurvicate();

    return <MainWrapper>{children}</MainWrapper>;
};

function useCreateSurvicate() {
    const location = useLocation();

    const [cookies] = useCookies(["GDPR"]);
    const scriptIsReady = useRef(false);

    useEffect(() => {
        const analyticsAllowed = cookies && cookies.GDPR && cookies.GDPR.includes("analytics");

        const disableSurvicate =
            location.pathname.includes("/result") ||
            location.pathname.includes("/unauthorized") ||
            location.pathname.includes("/auth");

        if (scriptIsReady.current || disableSurvicate || !analyticsAllowed) {
            // script already ready or surivcate not allowed!
            return;
        } else {
            // appending survicate
            // Start of Survicate (www.survicate.com) code -->
            const scriptToAppend = document.createElement("script");
            scriptToAppend.src =
                "https://survey.survicate.com/workspaces/7697bcf2843ee30112ae75a9b7cd18e9/web_surveys.js";
            scriptToAppend.async = true;
            const bodyElement = document.getElementsByTagName("body")[0];
            bodyElement?.append(scriptToAppend);
            // End of Survicate code -->
            scriptIsReady.current = true;
        }
    }, [cookies, location.pathname]);
}

export default SurvicateWrapper;
