import styled from "styled-components";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import InfoModal from "../../../basic/infoModal/InfoModal";
import DesignConstants from "../../../constants/DesignConstants";

export const Background = styled.div<{ limitHeight?: boolean; isMobile: boolean }>`
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px;
    margin: auto;
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};

    overflow: auto;

    ${({ isMobile }) =>
        isMobile &&
        `padding: 0 0.8rem 5px 0.8rem;

        &.mobFullScreen {
            height: 100vh;
        }`};

    &.landscape {
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    ${({ limitHeight }) => limitHeight && `max-height: 100%; overflow: auto;`};
`;

export const MainBodyContainer = styled.div`
    display: flex;
    position: relative;
    scrollbar-width: thin;
    width: 100%;
`;

export const DeviceOrientationAlertWrapper = styled(MainBackgroundSquircle)`
    height: 60vh;
    max-height: 60vh;
    max-width: 60vh;
    width: auto;
    border-radius: 1rem;
    padding: 2rem;
`;

export const DeviceOrientationAlert = styled.h2`
    text-align: center;
`;

export const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
`;

export const FooterItemWrapper = styled.div`
    flex: 1;
`;

export const FooterItem = styled.p`
    margin: 0.5rem 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
`;

export const FooterModals = styled(InfoModal)`
    height: 80vh;
    max-width: calc(800px - 2rem);
    width: 50vw;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: unset;
    }

    > button {
        padding: 0.5rem 0;
        height: 3rem;
    }
`;

export const FooterIframe = styled.iframe`
    height: 100%;
    width: 100%;
    border: none;
    margin-bottom: 0;
`;
