import { useState } from "react";
import ToggleSwitch from "../../../../basic/toggleSwitch/ToggleSwitch";

import {
    SettingHeader,
    StyledChevronIcon,
    SettingTitle,
    ExpandableIndicator,
    SettingStatus,
    ExpandedContent,
    SettingRow,
    SettingsStatusText,
} from "./StyledComponents";

interface BaseSettingProps {
    settingTitle: string;
    disabled?: boolean;
}

interface ToggleSettingProps extends BaseSettingProps {
    variant: "toggle";
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
}

interface ExpandableBaseSettings extends BaseSettingProps {
    children: React.ReactNode;
    disabled?: boolean;
}

interface ExpandableBaseSettingsWithStatusText extends ExpandableBaseSettings {
    variant: "expandable";
    statusText: string;
}

interface ExpandableBaseSettingsWithCustomStatus extends ExpandableBaseSettings {
    variant: "expandableWithCustomStatus";
    customStatus: () => React.ReactNode;
}

type ExpandableSettingProps = ExpandableBaseSettingsWithStatusText | ExpandableBaseSettingsWithCustomStatus;

type PDFSettingProps = ToggleSettingProps | ExpandableSettingProps;

const PDFSettingControl = ({ settingTitle, ...props }: PDFSettingProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const isToggle = props.variant === "toggle";
    const isExpandable = props.variant === "expandable";
    const isExpandableWithCustomStatus = props.variant === "expandableWithCustomStatus";

    const renderStatus = () => {
        if (isExpandable) {
            return (
                <SettingStatus>
                    <SettingsStatusText>{isExpandable ? props.statusText : ""}</SettingsStatusText>
                </SettingStatus>
            );
        } else if (isExpandableWithCustomStatus) {
            return props.customStatus();
        } else {
            return null;
        }
    };

    const renderCurrentSetting = () => {
        return (
            <ExpandableIndicator>
                {!isExpanded && (isExpandable || isExpandableWithCustomStatus) && renderStatus()}
                <StyledChevronIcon
                    name={isExpanded ? "chevron-down" : "chevron-right"}
                    disabled={props.disabled}
                />
            </ExpandableIndicator>
        );
    };

    const renderExpandedContent = () => {
        return (
            <ExpandedContent onClick={(e) => e.stopPropagation()}>
                {(isExpandable || isExpandableWithCustomStatus) && props.children}
            </ExpandedContent>
        );
    };
    return (
        <SettingRow>
            <SettingHeader
                isExpanded={isExpanded}
                onClick={
                    props.disabled ? undefined : isToggle ? () => props.onChange() : () => setIsExpanded(!isExpanded)
                }
                onMouseDown={(event: React.MouseEvent) => {
                    event.preventDefault(); // prevents accidentally selecting text in the children
                }}
                disabled={props.disabled}
            >
                <SettingTitle disabled={props.disabled}>{settingTitle}</SettingTitle>
                {isToggle ? (
                    <ToggleSwitch
                        checked={props.checked}
                        disabled={props.disabled}
                    />
                ) : (
                    renderCurrentSetting()
                )}
            </SettingHeader>

            {isExpanded && renderExpandedContent()}
        </SettingRow>
    );
};

export default PDFSettingControl;
