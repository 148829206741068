// LIBRARIES
import { useT } from "@transifex/react";
import React, { useEffect, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import { Views } from "../../../enums/views";
import { getIsLocalTestRun, getIsTestRun } from "../../../helpers/Url";
import Error from "../error/Error";
import Intro from "../intro/Intro";
import Outro from "../outro/Outro";
import Practice from "../practice/Practice";

import {
    Background,
    DeviceOrientationAlert,
    DeviceOrientationAlertWrapper,
    Footer,
    FooterIframe,
    FooterItem,
    FooterItemWrapper,
    FooterModals,
    MainBodyContainer,
} from "./StyledComponents";

let initialDataFetched = false;

export interface MainWrapperProps {
    isMobile?: boolean;
    limitHeight?: boolean;
    isTestRun?: boolean;
}

const MainWrapper = (props: MainWrapperProps) => {
    const { testId } = useParams<{ testId: string }>();

    const isLocalTestRun = props.isTestRun || getIsLocalTestRun() === "true" || getIsTestRun() === "true";

    const t = useT();
    const t_pleaseRotate = t("Please turn your phone back to portrait mode.", { _tags: "mainWrapper,alert" });
    const t_agb = t("AGB", { _tags: "MainWrapper,Footer" });
    const t_impressum = t("Impressum", { _tags: "MainWrapper,Footer" });
    const t_privacy = t("Privacy", { _tags: "MainWrapper,Footer" });
    const t_closeModal = t("Close", { _tags: "MainWrapper,Footer" });

    const { setStudentName, teacherTest, refreshData, currentMainView, setCurrentMainView } =
        useStudentPracticeContext();

    const [isFooterModalOpen, setIsFooterModalOpen] = useState(false);
    const [currentFooterLink, setCurrentFooterLink] = useState("");
    const [deviceOrientation, setDeviceOrientation] = useState<"portrait" | "landscape">();
    const backgroundRef = useRef<HTMLDivElement>(null);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    const isMobileByScreenWidth: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const isMobileView = props.isMobile ?? isMobileByScreenWidth;

    React.useEffect(() => {
        if (isMobileOnly) {
            window.addEventListener("orientationchange", handleOrientationChange);
            handleOrientationChange();
            // cleanup this component
            return () => {
                window.removeEventListener("orientationchange", handleOrientationChange);
            };
        }
    }, []);

    useEffect(() => {
        if (!testId || testId === "test") {
            if (isLocalTestRun) {
                // we are in the preview, which will fetch data for us
                initialDataFetched = true;
            } else {
                setCurrentMainView(Views.UNAUTHORIZED);
            }
        } else if (!initialDataFetched) {
            initialDataFetched = true;
            refreshData(testId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testId, refreshData]);

    function goToPractice(inputStudentName: string) {
        setCurrentMainView(Views.PRACTICE);
        setStudentName(inputStudentName);
    }

    function openModal(modalType: "agb" | "impressum" | "privacy") {
        let modalLink = "";
        if (modalType === "agb") {
            modalLink = "https://www.phase-6.de/agb_text.html";
        } else if (modalType === "impressum") {
            modalLink = "https://www.phase-6.de/impressum_text.html";
        } else {
            modalLink = "https://www.phase-6.de/datenschutz_text.html";
        }
        setCurrentFooterLink(modalLink);
        setIsFooterModalOpen(true);
    }

    function closeModal() {
        setIsFooterModalOpen(false);
    }

    function renderSwitch() {
        switch (currentMainView) {
            case Views.INTRO:
                return (
                    <Intro
                        goToPracticeAction={goToPractice}
                        testData={teacherTest}
                        isTestRun={isLocalTestRun}
                        isMobile={isMobileView}
                    />
                );
            case Views.PRACTICE:
                return (
                    <Practice
                        testData={teacherTest}
                        isMobile={isMobileView}
                        isTestRun={isLocalTestRun}
                    />
                );
            case Views.OUTRO:
                return <Outro isMobile={isMobileView} />;
            case Views.UNAUTHORIZED:
                return <Error />;
            default:
                return <div>Loading</div>;
        }
    }

    // Handle the device orientation lock.
    const handleOrientationChange = (event?: any) => {
        const eventTarget = event?.target || window;
        // console.log('orientationChange', eventTarget, eventTarget.screen?.orientation?.angle);
        const orientationAngle = eventTarget.screen?.orientation?.angle || eventTarget.orientation;
        if (orientationAngle) {
            if (orientationAngle === 0 || orientationAngle % 180 === 0) {
                setDeviceOrientation("portrait");
            } else {
                setDeviceOrientation("landscape");
            }
        } else {
            setDeviceOrientation("portrait");
        }
        backgroundRef.current?.blur();
    };

    return (
        <Background
            className={`${deviceOrientation ? deviceOrientation : ""} ${
                currentMainView === Views.OUTRO || currentMainView === Views.INTRO ? "mobFullScreen" : ""
            }`}
            ref={backgroundRef}
            limitHeight={props.limitHeight}
            isMobile={isMobileView}
        >
            {deviceOrientation !== "landscape" && <MainBodyContainer>{renderSwitch()} </MainBodyContainer>}
            {deviceOrientation === "landscape" && (
                <DeviceOrientationAlertWrapper isMobile={isMobileView}>
                    <DeviceOrientationAlert>{t_pleaseRotate}</DeviceOrientationAlert>
                </DeviceOrientationAlertWrapper>
            )}

            {(currentMainView === Views.INTRO || currentMainView === Views.OUTRO) && (
                <Footer>
                    <FooterItemWrapper>
                        <FooterItem
                            onClick={() => {
                                openModal("agb");
                            }}
                        >
                            {t_agb}
                        </FooterItem>
                    </FooterItemWrapper>
                    <FooterItemWrapper>
                        <FooterItem
                            onClick={() => {
                                openModal("privacy");
                            }}
                        >
                            {t_privacy}
                        </FooterItem>
                    </FooterItemWrapper>
                    <FooterItemWrapper>
                        <FooterItem
                            onClick={() => {
                                openModal("impressum");
                            }}
                        >
                            {t_impressum}
                        </FooterItem>
                    </FooterItemWrapper>
                </Footer>
            )}

            <FooterModals
                isOpen={isFooterModalOpen}
                onRequestClose={closeModal}
                cancelText={t_closeModal}
                cancelAction={closeModal}
            >
                <FooterIframe src={currentFooterLink} />
            </FooterModals>
        </Background>
    );
};

export default MainWrapper;
