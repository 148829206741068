import styled from "styled-components";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";

export const TimerIcon = styled(SvgIcon)<{ isMobile: boolean }>`
    height: 30px;
    margin-right: 0.8rem;
    border-radius: 6px;

    &.Warning5min {
        fill: ${(props) => props.theme.base.primaryColor};

        > path {
            fill: ${(props) => props.theme.base.primaryColor};
        }
    }

    &.Warning1min {
        height: 35px;

        > path {
            stroke: ${(props) => props.theme.base.primaryColor};
            stroke-width: 3px;
        }
    }

    ${({ isMobile }) => isMobile && `margin: 0`};
`;

export const TimerWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    ${({ isMobile }) => isMobile && `flex: 2`};
`;

export const TimeIntervalText = styled.p`
    &.Warning5min {
        color: ${(props) => props.theme.base.primaryColor};
    }

    &.Warning1min {
        font-size: 1.3rem;
        font-weight: bold;
    }
`;
