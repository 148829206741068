import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import DesignConstants from "../../../../constants/DesignConstants";
import { useT } from "@transifex/react";

const PDFName: React.FC = () => {
    const t = useT();
    const t_nameColon = t("Name:", { _tags: "PDFConfiguration" });

    return (
        <View style={styles.nameWrapper}>
            <Text style={styles.headerText}>{t_nameColon}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    headerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    nameWrapper: {
        flex: 1,
    },
});

export default PDFName;
