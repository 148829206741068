import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";

interface PDFExerciseTitleProps {
    exerciseTask: string;
    exerciseNumber: number;
    smallerMarginBottom?: boolean;
    exerciseScore: number;
    showExerciseScore: boolean;
}

// Create Document Component
const PDFExerciseTitle: React.FC<PDFExerciseTitleProps> = (props: PDFExerciseTitleProps) => {
    return (
        <View
            style={[
                styles.exerciseTitleContainer,
                { marginBottom: props.smallerMarginBottom ? DesignConstants.PDF.GAP_XXS : DesignConstants.PDF.GAP_S },
            ]}
        >
            <View style={styles.exerciseSentenceContainer}>
                <Text style={styles.exerciseTitle}>{props.exerciseNumber + "."}</Text>
                <View style={styles.taskContainer}>
                    <Text style={styles.exerciseTitle}>{props.exerciseTask}</Text>
                </View>
            </View>
            {props.showExerciseScore && <Text style={styles.exerciseTitle}>{`/${props.exerciseScore}`}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
    exerciseTitleContainer: {
        display: "flex",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_M,
        justifyContent: "space-between",
    },
    exerciseSentenceContainer: {
        display: "flex",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XS,
        width: "100%",
    },
    exerciseTitle: {
        fontSize: DesignConstants.PDF.FONTSIZE_EXERCISE_HEADER,
        fontFamily: DesignConstants.PDF.PROXIMA_FONT,
        fontWeight: DesignConstants.PDF.SEMIBOLD,
    },
    taskContainer: {
        flex: 1,
    },
});

export default PDFExerciseTitle;
