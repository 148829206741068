import { GoodLuckSettings, TestDetailsItem } from "p6m-p6u";
import React from "react";
import PDFTable from "../pdfSubComponents/PDFTable";
import { ExerciseDirections } from "../../../enums/directions";
import { PDFGoodLuckImageWrapper } from "../pdfGoodLuckImage/PDFGoodLuckImageWrapper";

interface PDFStandardExerciseProps {
    exercise: TestDetailsItem;
    goodLuckSettings: GoodLuckSettings;
    isLastExercise: boolean;
    isSolution?: boolean;
}

const PDFStandardExercise: React.FC<PDFStandardExerciseProps> = (props: PDFStandardExerciseProps) => {
    return (
        <PDFGoodLuckImageWrapper
            isLastElement={props.isLastExercise}
            goodLuckSettings={props.goodLuckSettings}
            showImageBelowExercise
            isSolution={props.isSolution}
        >
            <PDFTable
                selectedWords={props.exercise?.selectedWords ?? []}
                showAllQuestions={
                    props.exercise?.direction === ExerciseDirections.QUESTION_TO_ANSWER || props.isSolution
                }
                showAllAnswers={props.exercise?.direction === ExerciseDirections.ANSWER_TO_QUESTION || props.isSolution}
            />
        </PDFGoodLuckImageWrapper>
    );
};

export default PDFStandardExercise;
