// LIBRARIES
import { useT, UT } from "@transifex/react/dist";
import { StudentTestContent } from "p6m-p6u";
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import { ThemeContext } from "styled-components/macro";
import { ReactComponent as helpIcon } from "../../../assets/icons/help.svg";
import { ReactComponent as calendarIcon } from "../../../assets/icons/phase-interval.svg";
import { ReactComponent as NextArrow } from "../../../assets/icons/skip-card.svg";
import { ReactComponent as timeIcon } from "../../../assets/icons/time.svg";
import ButtonWithIcon from "../../../basic/buttonWithIcon/ButtonWithIcon";
import InfoModal from "../../../complex/students/infoModal/InfoModal";
import { cp } from "../../../config";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import {
    getDateTimeFromMilliseconds,
    getReadableTimeFromTimeLimit,
    isDeadlineAlreadyPassed,
} from "../../../helpers/DateTime";

import {
    AppInfoSubtitle,
    DataDeletionNote,
    DatePassedText,
    HelpIconComponent,
    InfoHeader,
    InputModalTestTime,
    InputModalTitleWrapper,
    NewTestTitle,
    QuestionIconWrapper,
    StartWrapper,
    StyledFlagIcon,
    StyledInput,
    TeacherComment,
    TeacherName,
    TeacherNameWrapper,
    TestConditionIcon,
    TestConditionIconDesc,
    TestConditionIconTitle,
    TestConditionItemSeparator,
    TestConditionItemWrapper,
    TestConditionsGoodLuck,
    TestConditionsInfoWrapper,
    TestConditionsTeacherDescWrapper,
    TestConditionsTitle,
    TestConditionsWrapper,
    TestDescriptionInner,
    TestDescriptionWrapper,
    Wrapper,
} from "./StyledComponents";

Modal.setAppElement("#root");

export interface IntroProps {
    goToPracticeAction: (studentName: string) => any;
    testData: StudentTestContent;
    isTestRun: boolean;
    isMobile: boolean;
}

const Intro = (props: IntroProps) => {
    const t = useT();
    const t_newTest = t("New Exercise", { _tags: "intro,testInfoScreen" });
    const t_assignment = t("Assignment", { _tags: "intro,testInfoScreen" });
    const t_goodLuck = t("Good Luck!", { _tags: "intro,testInfoScreen" });
    const t_dueDate = t("Due date:", { _tags: "intro,testInfoScreen" });
    const t_timeLimit = t("Time limit:", { _tags: "intro,testInfoScreen" });
    const t_hour = t("h", { _tags: "intro,testInfoScreen" });
    const t_startNow = t("Start Now", { _tags: "intro,testInfoScreen" });
    const t_studentName = t("Your name", { _tags: "intro,testInfoScreen" });
    const t_appInfoTitle = t("Are you here for the first time and you dont know what's happening?", {
        _tags: "intro,testInfoScreen",
    });
    const t_appInfoSubtitle = t("Then take a look first at our example exercise:", {
        _tags: "intro,testInfoScreen",
    });
    const t_inputModalContinueCTA = t("Continue", { _tags: "intro,testInfoScreen" });
    const t_appInfoCTA = t("Example Exercise", { _tags: "intro,testInfoScreen" });
    const t_appInfoCTACancel = t("Cancel", { _tags: "intro,testInfoScreen" });

    const theme = useContext(ThemeContext);

    const { studentTestAnswer } = useStudentPracticeContext();

    const [studentName, setStudentName] = useState(studentTestAnswer.studentName || "");
    const [appInfoModalIsOpen, setAppInfoModalIsOpen] = useState(false);
    const [nameInputModalIsOpen, setNameInputModalIsOpen] = useState(false);

    useEffect(() => {
        if (studentTestAnswer.studentName) {
            setStudentName(studentTestAnswer.studentName);
        }
    }, [studentTestAnswer.studentName]);

    function goToPractice() {
        props.goToPracticeAction(studentName);
    }

    function openInfoModal() {
        setAppInfoModalIsOpen(true);
    }

    function hideInfoModal() {
        setAppInfoModalIsOpen(false);
    }

    function openInputModal() {
        setNameInputModalIsOpen(true);
    }

    function hideInputModal() {
        setNameInputModalIsOpen(false);
    }

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    let isTestWorkable = true;

    if (props.testData.dueDate && isDeadlineAlreadyPassed(props.testData.dueDate)) {
        isTestWorkable = false;
    }

    const NameComponent = (
        <TeacherNameWrapper isMobile={props.isMobile}>
            <NewTestTitle isMobile={props.isMobile}>{t_newTest}</NewTestTitle>
            {!!props.testData.teacherName && (
                <TeacherName isMobile={props.isMobile}>{props.testData.teacherName}</TeacherName>
            )}
        </TeacherNameWrapper>
    );

    const renderTestLanguageComponent = () => {
        return <StyledFlagIcon languageName={props.testData?.language?.answerLanguageName ?? ""} />;
    };

    const QuestionPopupComponent = (
        <QuestionIconWrapper onClick={openInfoModal}>
            <HelpIconComponent svgComponent={helpIcon} />
        </QuestionIconWrapper>
    );

    const renderTestConditions = () => {
        if (isTestWorkable) {
            return (
                <TestConditionsWrapper isMobile={props.isMobile}>
                    <TestDescriptionWrapper isMobile={props.isMobile}>
                        <TestDescriptionInner>
                            {NameComponent}
                            {!!props.testData.teacherComment && (
                                <TestConditionsTeacherDescWrapper isMobile={props.isMobile}>
                                    <TestConditionsTitle>{t_assignment}</TestConditionsTitle>
                                    <TeacherComment>{props.testData.teacherComment}</TeacherComment>
                                    <TestConditionsGoodLuck> {t_goodLuck}</TestConditionsGoodLuck>
                                </TestConditionsTeacherDescWrapper>
                            )}
                        </TestDescriptionInner>
                    </TestDescriptionWrapper>

                    {(!!props.testData.timeLimit || !!props.testData.dueDate) && (
                        <TestConditionsInfoWrapper isMobile={props.isMobile}>
                            {!!props.testData.dueDate && (
                                <TestConditionItemWrapper isMobile={props.isMobile}>
                                    <TestConditionIcon
                                        svgComponent={calendarIcon}
                                        isMobile={props.isMobile}
                                    />
                                    <TestConditionIconTitle isMobile={props.isMobile}>
                                        {t_dueDate}
                                    </TestConditionIconTitle>
                                    <TestConditionIconDesc>
                                        {getDateTimeFromMilliseconds(props.testData.dueDate)} {t_hour}
                                    </TestConditionIconDesc>
                                </TestConditionItemWrapper>
                            )}

                            {!!props.testData.dueDate && !!props.testData.timeLimit && <TestConditionItemSeparator />}

                            {!!props.testData.timeLimit && (
                                <TestConditionItemWrapper
                                    className={"orange"}
                                    isMobile={props.isMobile}
                                >
                                    <TestConditionIcon
                                        isMobile={props.isMobile}
                                        svgComponent={timeIcon}
                                        color={theme.base.primaryColor}
                                    />
                                    <TestConditionIconTitle isMobile={props.isMobile}>
                                        {t_timeLimit}
                                    </TestConditionIconTitle>
                                    <TestConditionIconDesc>
                                        {getReadableTimeFromTimeLimit(props.testData.timeLimit)}
                                    </TestConditionIconDesc>
                                </TestConditionItemWrapper>
                            )}
                        </TestConditionsInfoWrapper>
                    )}
                </TestConditionsWrapper>
            );
        } else {
            return (
                <TestConditionsWrapper isMobile={props.isMobile}>
                    <DatePassedText>
                        <UT
                            _str="Sadly the deadline for this Exercise has already passed, and you cannot take part in it anymore.<br/> Please ask your teacher if he can extend the deadline."
                            _inline
                            _tags="intro,testInfoScreen"
                        />
                    </DatePassedText>
                </TestConditionsWrapper>
            );
        }
    };

    const renderInfoHeader = () => {
        if (isMobileView) {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    {QuestionPopupComponent}
                    {props.testData.language && renderTestLanguageComponent()}
                </InfoHeader>
            );
        } else {
            return (
                <InfoHeader isMobile={props.isMobile}>
                    {props.testData.language && renderTestLanguageComponent()}
                    {QuestionPopupComponent}
                </InfoHeader>
            );
        }
    };

    const updateStudentName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStudentName(event.target.value);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);
    const executeScroll = () => {
        wrapperRef.current!.scrollIntoView();
    };

    const handleEnter = function (event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter" && studentName !== "") {
            openInputModal();
        }
    };

    const openExampleExercise = function () {
        hideInfoModal();
        // the example exercise should be set here and in the .env!
        window.open(cp.cfg.REACT_APP_PREFIX + "/student/zXampGxvJUniLYEn74g8", "_blank");
    };

    const InputModalTitle = (
        <InputModalTitleWrapper>
            {!!props.testData.timeLimit && (
                <InputModalTestTime>
                    <TestConditionIcon
                        isMobile={props.isMobile}
                        svgComponent={timeIcon}
                        color={theme.base.primaryColor}
                    />
                    <TestConditionIconTitle isMobile={props.isMobile}>{t_timeLimit}</TestConditionIconTitle>
                    <TestConditionIconDesc>
                        {getReadableTimeFromTimeLimit(props.testData.timeLimit)}
                    </TestConditionIconDesc>
                </InputModalTestTime>
            )}
            {!!props.testData.timeLimit && (
                <>
                    <UT
                        _str="With the pass of time, the results will be stored and sent to your teacher - <b>keep an eye on the time! </b>"
                        _inline
                        _tags="intro,testInfoScreen"
                    />
                    <br />
                </>
            )}
            {!props.isTestRun && (
                <>
                    <UT
                        _str='<b>Info:</b> by clicking on "Continue" your name and your answers will be saved and sent to your teacher'
                        _inline
                        _tags="intro,testInfoScreen"
                    />
                    <DataDeletionNote>
                        <UT
                            _str="Both will be deleted after 1 year automatically or when your teacher deletes the assignment."
                            _inline
                            _tags="intro,testInfoScreen"
                        />
                    </DataDeletionNote>
                </>
            )}
            {props.isTestRun && (
                <UT
                    _str="This is just a test run, your results will not be saved."
                    _inline
                    _tags="intro,testInfoScreen"
                />
            )}
        </InputModalTitleWrapper>
    );

    return (
        <Wrapper>
            {renderInfoHeader()}
            {renderTestConditions()}
            {isTestWorkable && (
                <StartWrapper
                    ref={wrapperRef}
                    isMobile={props.isMobile}
                >
                    <StyledInput
                        isMobile={props.isMobile}
                        onChange={updateStudentName}
                        value={studentName}
                        placeholder={t_studentName}
                        onClick={executeScroll}
                        color={"#999"}
                        type={"text"}
                        onKeyUp={(e) => handleEnter(e)}
                    />
                    <ButtonWithIcon
                        iconSvgComponent={NextArrow}
                        text={t_startNow}
                        disabled={studentName === ""}
                        onClick={openInputModal}
                        iconColor={"white"}
                    />
                </StartWrapper>
            )}

            <InfoModal
                isOpen={appInfoModalIsOpen}
                onRequestClose={hideInfoModal}
                modalIcon={helpIcon}
                iconColor={theme.base.primaryColor}
                modalTitle={t_appInfoTitle}
                continueAction={openExampleExercise}
                continueText={t_appInfoCTA}
                includeCloseHeader={true}
            >
                <AppInfoSubtitle isMobile={props.isMobile}>{t_appInfoSubtitle}</AppInfoSubtitle>
            </InfoModal>

            <InfoModal
                isOpen={nameInputModalIsOpen}
                onRequestClose={hideInputModal}
                iconColor={theme.base.primaryColor}
                modalTitle={InputModalTitle}
                cancelAction={hideInputModal}
                cancelText={t_appInfoCTACancel}
                continueAction={goToPractice}
                continueText={t_inputModalContinueCTA}
            />
        </Wrapper>
    );
};

export default Intro;
