import { TestDetailsWord, VerbtrainingSelectedWord } from "p6m-p6u";

export const sanitizePDFText = (text?: string): string | undefined => {
    if (text) return text.replaceAll("\n", "").replaceAll("\t", "");
};

/**
 * Creates special keys to fix duplicate elements.
 *
 * @param {(TestDetailsWord | VerbtrainingSelectedWord)[]} selectedWords the words being rendered
 *
 * react-pdf seems to not always handle changing elements as expected.
 * Removing elements or changing their order can result in elements rendering multiple times.
 * Adding an additional key that contains all words in their order seems to force it to remove all former elements and fixes the issue.
 *
 */
export const createExerciseKey = (selectedWords: (TestDetailsWord | VerbtrainingSelectedWord)[]) => {
    return selectedWords
        .reduce((words: string[], word: TestDetailsWord | VerbtrainingSelectedWord) => {
            if ("id" in word && word.id) words.push(word.id);
            return words;
        }, [])
        .join();
};
