import styled from "styled-components";

export const StandardExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
`;

export const ExerciseRowContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 80%;

    ${({ isMobile }) =>
        isMobile &&
        `flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 1rem;`};
`;

export const ExerciseItemSeparator = styled.div`
    flex: 1;
`;

export const QuestionText = styled.p`
    flex: 4;
    text-align: right;

    &.left {
        text-align: left;
    }
`;
