import { useState } from "react";
import { TestDetailsWord, WordType } from "p6m-p6u";
import { useTestCreationContext } from "../../context/TestCreationContext";
import useClickOutside from "../../hooks/useClickOutside";
import { SCORING } from "../../constants/ScoringConstants";
import ScoreController from "../scoreController/ScoreController";
import { EditScoreDirection } from "../../enums/directions";

import { WordScoreBubbleWrapper, StyledIcon } from "./StyledComponents";

interface WordScoreBubbleProps {
    wordId: string;
}

const _findSelectedWord = (wordId: string, words: WordType[] = []) => {
    return words.find((w): w is TestDetailsWord => "id" in w && w.id === wordId);
};

const WordScoreBubble = (props: WordScoreBubbleProps) => {
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();

    const [isEditing, setIsEditing] = useState(false);

    const componentRef = useClickOutside(() => {
        if (isEditing) {
            setIsEditing(false);
        }
    });

    const selectedWord = _findSelectedWord(props.wordId, currentExerciseModel.wordsInProgress);
    const currentPoints = selectedWord?.wordScore ?? 1;

    const updateExercisePointsCount = (newPoints: number) => {
        if (!selectedWord || !("id" in selectedWord)) return;

        const updatedWordsInProgress = currentExerciseModel.wordsInProgress?.map((word) =>
            "id" in word && word.id === props.wordId ? { ...word, wordScore: newPoints } : word
        );

        updateCurrentExerciseProperties({ wordsInProgress: updatedWordsInProgress });
        setIsTestSynced(false);
    };

    const handlePointsChange = (direction: EditScoreDirection) => {
        const newPoints =
            direction === EditScoreDirection.INCREMENT
                ? Math.min(SCORING.MAX_POINTS, currentPoints + SCORING.POINTS_STEP)
                : Math.max(SCORING.MIN_POINTS, currentPoints - SCORING.POINTS_STEP);

        updateExercisePointsCount(newPoints);
    };

    return (
        <WordScoreBubbleWrapper
            ref={componentRef}
            onClick={() => setIsEditing(true)}
            isEditing={isEditing}
        >
            <ScoreController
                currentScore={currentPoints}
                isEditing={isEditing}
                onIncrement={() => handlePointsChange(EditScoreDirection.INCREMENT)}
                onDecrement={() => handlePointsChange(EditScoreDirection.DECREMENT)}
                minPoints={SCORING.MIN_POINTS}
                maxPoints={SCORING.MAX_POINTS}
                showSuffix
            />

            {!isEditing && <StyledIcon name="edit-avatar" />}
        </WordScoreBubbleWrapper>
    );
};

export default WordScoreBubble;
