import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";

export const ExerciseScoreWrapper = styled.div<{
    isEditing: boolean;
    isEditable: boolean;
}>`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    padding: 0 ${DesignConstants.SPACES.S};
    cursor: ${({ isEditable, isEditing }) => (isEditable && !isEditing ? "pointer" : "default")};
`;

export const ExerciseScorePoints = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    text-align: right;
`;

export const CounterControlWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    max-width: 1.5rem;
`;

export const ExerciseScoreText = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const StyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    max-width: 1.5rem;
`;
