import { ExercisePointsToggleWrapper, ExercisePointsToggle } from "./StyledComponents";

interface ToggleSwitchProps {
    checked: boolean;
    onChange?: () => void;
    disabled?: boolean;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
    return (
        <ExercisePointsToggleWrapper>
            <ExercisePointsToggle
                type="checkbox"
                checked={props.checked}
                onChange={props.disabled ? undefined : props.onChange}
                readOnly={!props.onChange}
                disabled={props.disabled}
            />
        </ExercisePointsToggleWrapper>
    );
};

export default ToggleSwitch;
