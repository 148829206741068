import * as React from "react";
import { useState } from "react";

import { CancelTextWrapper, CancelButtonWrapper, CloseIconWrapper, StyledHeaderActionsIcon } from "./StyledComponents";

export interface CloseButtonProps {
    onClick: () => void;
    hintText: string;
}

const CloseButton: React.FC<CloseButtonProps> = (props) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };
    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <CancelButtonWrapper>
            <CloseIconWrapper
                onClick={props.onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <StyledHeaderActionsIcon name={"close"} />
                {isHovering && <CancelTextWrapper>{props.hintText}</CancelTextWrapper>}
            </CloseIconWrapper>
        </CancelButtonWrapper>
    );
};

export default CloseButton;
