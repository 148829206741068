import { useT } from "@transifex/react";
import { SCORING } from "../../../constants/ScoringConstants";
import ScoreController from "../../scoreController/ScoreController";
import { EditScoreDirection } from "../../../enums/directions";

import { PerWordScoreEditorWrapper } from "./StyledComponents";

interface PerWordScoreEditorProps {
    adjustExerciseScore: ({
        direction,
        currentPointsPerWord,
    }: {
        direction: EditScoreDirection;
        currentPointsPerWord?: number;
    }) => void;
    currentPointsPerWord: number;
}

const PerWordScoreEditor = (props: PerWordScoreEditorProps) => {
    const t = useT();

    const t_pointsPerWord = t("Points per word", { _tags: "CreateTest,Summary" });

    return (
        <PerWordScoreEditorWrapper>
            <ScoreController
                currentScore={props.currentPointsPerWord}
                isEditing
                onIncrement={() => {
                    if (props.currentPointsPerWord < SCORING.MAX_POINTS) {
                        props.adjustExerciseScore({
                            direction: EditScoreDirection.INCREMENT,
                            currentPointsPerWord: props.currentPointsPerWord,
                        });
                    }
                }}
                onDecrement={() =>
                    props.adjustExerciseScore({
                        direction: EditScoreDirection.DECREMENT,
                        currentPointsPerWord: props.currentPointsPerWord,
                    })
                }
                minPoints={SCORING.MIN_POINTS}
                maxPoints={SCORING.MAX_POINTS}
                showSuffix
            />
            {t_pointsPerWord}
        </PerWordScoreEditorWrapper>
    );
};

export default PerWordScoreEditor;
