import { useT } from "@transifex/react";
import {
    NearestAdaptableRange,
    MinMaxGradeRange,
    MinMaxGradingScale,
    PercentageGradingScale,
    SettingsSection,
    SettingsSubSection,
} from "p6m-p6u";
import { useEffect, useState } from "react";
import { settingsDefault } from "../../../../../../config/settingsDefault";
import {
    convertToMinMaxGradingScale,
    createPercentageFromMinMax,
    findAllAdaptableRangeTargets,
    updateGradeRange,
} from "../../../../../../helpers/GradingScaleHelper";
import { SettingsChangeProps } from "../../../PDFConfiguration";
import PDFGradeRangeEditor from "./PDFGradeRangeEditor";
import { GradeRangeLimitType } from "../../../../../../enums/settings";
import { EditScoreDirection } from "../../../../../../enums/directions";

import {
    GradingScaleContent,
    GradingScaleEditor,
    GradingScaleEditorCell,
    GradingScaleEditorRow,
    GradingScaleExpandable,
    GradingScaleExpandableHeader,
    ResetButton,
    ResetButtonText,
    StyledP6Icon,
    Text,
} from "./StyledComponents";

interface PDFGradingScaleEditorProps {
    gradingScaleSettings: PercentageGradingScale;
    overallScore: number;
    disabled?: boolean;
    handleSettingsChange: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        settingsChangeProps: SettingsChangeProps<T, K>
    ) => void;
}

export type UpdateGradingScaleParams = {
    gradingScale: MinMaxGradingScale;
    index: number;
    minOrMax: GradeRangeLimitType;
    direction: EditScoreDirection;
};

const PDFGradingScaleEditor = (props: PDFGradingScaleEditorProps) => {
    const t = useT();
    const t_editGradingScale = t("Edit grading scale", { _tags: "PDFConfiguration" });
    const t_resetGradingScale = t("Reset grading scale", { _tags: "PDFConfiguration" });

    const defaultGradingScaleSettings = settingsDefault.gradingAndSignatures.gradingScale.scale;

    const [gradingScale, setGradingScale] = useState<MinMaxGradingScale | undefined>(undefined);
    const [nearestAdaptableRanges, setNearestAdaptableRanges] = useState<NearestAdaptableRange[]>([]);

    const [isOpen, setIsOpen] = useState(false);
    const showExpandedContent =
        isOpen &&
        !props.disabled &&
        gradingScale &&
        gradingScale.length !== 0 &&
        gradingScale.length === nearestAdaptableRanges.length;

    useEffect(() => {
        // calculated inside useEffect to reduce the amount of times the clean up function runs
        // react-pdf does not seem to optimise this enough to move it outside

        const gradingScaleSettings = props.gradingScaleSettings ?? defaultGradingScaleSettings;

        const minMaxGradingScale = convertToMinMaxGradingScale(props.overallScore ?? 100, gradingScaleSettings);
        setGradingScale(minMaxGradingScale);

        const nearestAdaptableRanges = findAllAdaptableRangeTargets(minMaxGradingScale);
        setNearestAdaptableRanges(nearestAdaptableRanges);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.gradingScaleSettings, props.overallScore]);

    const handleGradingScaleChange = (newPercentageGradingScale: PercentageGradingScale) => {
        props.handleSettingsChange({
            sectionTitle: "gradingAndSignatures",
            settingTitle: "gradingScale",
            setting: {
                key: "scale",
                value: newPercentageGradingScale,
            },
        });
    };

    const resetGradingScale = () => {
        const defaultGradingScale = defaultGradingScaleSettings;
        handleGradingScaleChange(defaultGradingScale);
    };

    const updateGradingScale = (updateGradingScaleParams: UpdateGradingScaleParams) => {
        const updateGradingScale = updateGradeRange({ ...updateGradingScaleParams, nearestAdaptableRanges });

        const newPercentageGradingScale = createPercentageFromMinMax(updateGradingScale);

        handleGradingScaleChange(newPercentageGradingScale);
    };

    const renderExpandedContent = (gradingScale: MinMaxGradingScale) => {
        return (
            <GradingScaleContent>
                {gradingScale.map((gradeRange: MinMaxGradeRange, index: number) => {
                    return (
                        <GradingScaleEditorRow
                            key={"editor" + gradeRange.grade}
                            borderBottom={index < (gradingScale?.length ?? 0) - 1}
                        >
                            <GradingScaleEditorCell borderRight>
                                <Text>{gradeRange.grade}</Text>
                            </GradingScaleEditorCell>
                            <PDFGradeRangeEditor
                                gradingScale={gradingScale ?? []}
                                gradeRange={gradeRange}
                                index={index}
                                overallScore={props.overallScore}
                                handleSettingsChange={props.handleSettingsChange}
                                adaptableScoreLeftIndex={nearestAdaptableRanges[index].left}
                                adaptableScoreRightIndex={nearestAdaptableRanges[index].right}
                                updateGradeRange={updateGradingScale}
                            />
                        </GradingScaleEditorRow>
                    );
                })}
            </GradingScaleContent>
        );
    };

    return (
        <GradingScaleEditor>
            <GradingScaleExpandable disabled={props.disabled}>
                <GradingScaleExpandableHeader
                    onClick={() => setIsOpen(!isOpen)}
                    onMouseDown={(event: React.MouseEvent) => {
                        event.preventDefault(); // prevents accidentally selecting text in the children
                    }}
                    isOpen={isOpen && !props.disabled}
                    disabled={props.disabled}
                >
                    <Text>{t_editGradingScale}</Text>
                    <StyledP6Icon name={isOpen && !props.disabled ? "chevron-up" : "chevron-down"} />
                </GradingScaleExpandableHeader>
                {showExpandedContent && renderExpandedContent(gradingScale)}
            </GradingScaleExpandable>
            <ResetButton
                icon={"close"}
                iconPosition={"LEFT"}
                buttonStyle={"LIGHT_BORDER"}
                disabled={props.disabled || props.gradingScaleSettings === defaultGradingScaleSettings}
                onClick={props.disabled ? undefined : resetGradingScale}
            >
                <ResetButtonText>{t_resetGradingScale}</ResetButtonText>
            </ResetButton>
        </GradingScaleEditor>
    );
};

export default PDFGradingScaleEditor;
