import standardExercise from "../assets/img/exerciseTypes/Standard.png";
import connectWordsExercise from "../assets/img/exerciseTypes/ConnectWords.png";
import gapSentenceExercise from "../assets/img/exerciseTypes/GapSentence.png";
import createSentenceExercise from "../assets/img/exerciseTypes/CreateSentence.png";
import createTextExercise from "../assets/img/exerciseTypes/CreateText.png";
import freeTextExercise from "../assets/img/exerciseTypes/FreeText.png";

export interface ExerciseScoring {
    storesScorePerWord: boolean; // if true, score is stored in word.wordScore
    showScoreBubbles: boolean;
    canEditOverallScore: boolean;
}

export enum Exercises {
    STANDARD = "STANDARD", // table exercise / 'tabelarische Abfrage'
    CONNECT_WORDS = "CONNECT_WORDS", // connect words / 'Wörter verbinden'
    FILL_GAP = "FILL_GAP", // fill gap / 'Lückensätze'
    WRITE_SENTENCE = "WRITE_SENTENCE", // form sentences / 'Sätze bilden'
    CREATE_TEXT = "CREATE_TEXT", // text production / 'Textproduktion'
    FREE_TEXT = "FREE_TEXT", // individual text fields / 'individuelles Textfeld'
    VERBTRAINING = "VERBTRAINING", // verb training / 'Verb training'
}

export const ExerciseTypes = new Map<Exercises, string>([
    [Exercises.STANDARD, "standard"],
    [Exercises.CONNECT_WORDS, "verbinden"],
    [Exercises.FILL_GAP, "fillInTheGap"],
    [Exercises.WRITE_SENTENCE, "writeSentence"],
    [Exercises.CREATE_TEXT, "jumbledWords"],
    [Exercises.FREE_TEXT, "textOnly"],
    [Exercises.VERBTRAINING, "verbTraining"],
]);

export const ExercisePrefixes = new Map<Exercises, string>([
    [Exercises.STANDARD, "std"],
    [Exercises.CONNECT_WORDS, "con"],
    [Exercises.FILL_GAP, "fig"],
    [Exercises.WRITE_SENTENCE, "wrs"],
    [Exercises.CREATE_TEXT, "juw"],
    [Exercises.FREE_TEXT, "txt"],
    [Exercises.VERBTRAINING, "vtr"],
]);

export const ExerciseImages = new Map<Exercises, string>([
    [Exercises.STANDARD, standardExercise],
    [Exercises.CONNECT_WORDS, connectWordsExercise],
    [Exercises.FILL_GAP, gapSentenceExercise],
    [Exercises.WRITE_SENTENCE, createSentenceExercise],
    [Exercises.CREATE_TEXT, createTextExercise],
    [Exercises.FREE_TEXT, freeTextExercise],
    [Exercises.VERBTRAINING, ""],
]);

export const ExercisesWithoutPageWrapInPDF: Array<string> = [
    ExerciseTypes.get(Exercises.CONNECT_WORDS) ?? "",
    ExerciseTypes.get(Exercises.FILL_GAP) ?? "",
    ExerciseTypes.get(Exercises.CREATE_TEXT) ?? "",
];

export const ExercisesWithDirection: Array<string> = [
    ExerciseTypes.get(Exercises.STANDARD) ?? "",
    ExerciseTypes.get(Exercises.FILL_GAP) ?? "",
    ExerciseTypes.get(Exercises.WRITE_SENTENCE) ?? "",
    ExerciseTypes.get(Exercises.CREATE_TEXT) ?? "",
];

export const TextInputExercises: Array<string> = [
    ExerciseTypes.get(Exercises.WRITE_SENTENCE) ?? "",
    ExerciseTypes.get(Exercises.CREATE_TEXT) ?? "",
];

export const ActiveExerciseTypes = [
    Exercises.STANDARD,
    Exercises.CONNECT_WORDS,
    Exercises.FILL_GAP,
    Exercises.WRITE_SENTENCE,
    Exercises.FREE_TEXT,
];

export const ExerciseScoringMap: Record<Exercises, ExerciseScoring> = {
    [Exercises.STANDARD]: {
        storesScorePerWord: true,
        showScoreBubbles: true,
        canEditOverallScore: false,
    },
    [Exercises.CONNECT_WORDS]: {
        storesScorePerWord: true,
        showScoreBubbles: false,
        canEditOverallScore: true,
    },
    [Exercises.FILL_GAP]: {
        storesScorePerWord: true,
        showScoreBubbles: true,
        canEditOverallScore: false,
    },
    [Exercises.WRITE_SENTENCE]: {
        storesScorePerWord: true,
        showScoreBubbles: true,
        canEditOverallScore: false,
    },
    [Exercises.CREATE_TEXT]: {
        storesScorePerWord: false,
        showScoreBubbles: false,
        canEditOverallScore: true,
    },
    [Exercises.FREE_TEXT]: {
        storesScorePerWord: false,
        showScoreBubbles: false,
        canEditOverallScore: true,
    },
    [Exercises.VERBTRAINING]: {
        storesScorePerWord: false,
        showScoreBubbles: false,
        canEditOverallScore: false,
    },
};
