import { useT } from "@transifex/react";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";
import { settingsDefault } from "../../../../../config/settingsDefault";
import { SettingPosition } from "../../../../../enums/settings";
import PDFCollapsible from "../../pdfCollapsible/PDFCollapsible";
import { SettingsChangeProps } from "../../PDFConfiguration";
import PDFSettingControl from "../../pdfSettingControl/PDFSettingControl";
import PDFTopBottomSettingControl from "../../pdfSettingControl/specialisedControls/pdfTopBottomSettingControl/PDFTopBottomSettingControl";

interface PDFScoreSettingsProps {
    teacherTestDetails: TeacherTestDetails;
    handleSettingsChange: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        settingsChangeProps: SettingsChangeProps<T, K>
    ) => void;
    onToggleScore: () => Promise<void>;
}

const PDFScoreSettings = (props: PDFScoreSettingsProps) => {
    const t = useT();
    const t_scoring = t("Scoring", { _tags: "PDFConfiguration" });
    const t_scores = t("Scores", { _tags: "PDFConfiguration" });
    const t_overallScore = t("Overall score", { _tags: "PDFConfiguration" });

    const settings = props.teacherTestDetails?.settings ?? settingsDefault;
    const pdfGradingSignaturesSettings = settings.gradingAndSignatures;

    const showScores = !!props.teacherTestDetails.showScores;
    const isOverallScoreActive = !!pdfGradingSignaturesSettings?.overallScore?.show;

    const toggleOverallScore = (settingTitle: SettingsSubSection<"gradingAndSignatures">, isActive: boolean) => {
        props.handleSettingsChange({
            sectionTitle: "gradingAndSignatures",
            settingTitle: settingTitle,
            setting: {
                key: "show",
                value: isActive,
            },
        });
    };

    const togglePosition = (
        settingTitle: SettingsSubSection<"gradingAndSignatures">,
        currentPosition: SettingPosition
    ) => {
        const newPosition = currentPosition === SettingPosition.TOP ? SettingPosition.BOTTOM : SettingPosition.TOP;
        props.handleSettingsChange({
            sectionTitle: "gradingAndSignatures",
            settingTitle: settingTitle,
            setting: {
                key: "position",
                value: newPosition,
            },
        });
    };

    return (
        <PDFCollapsible title={t_scoring}>
            <PDFSettingControl
                variant={"toggle"}
                settingTitle={t_scores}
                checked={showScores}
                onChange={props.onToggleScore}
            />
            <PDFTopBottomSettingControl
                settingTitle={t_overallScore}
                topBottomSettingDetails={{
                    isActive: isOverallScoreActive,
                    position: pdfGradingSignaturesSettings.overallScore.position,
                    toggleOnOff: () => toggleOverallScore("overallScore", !isOverallScoreActive),
                    togglePosition: () => {
                        togglePosition("overallScore", pdfGradingSignaturesSettings.overallScore.position);
                    },
                }}
            />
        </PDFCollapsible>
    );
};

export default PDFScoreSettings;
