import { useState } from "react";

import { PDFCollapsibleWrapper, PDFCollapsibleHeader, StyledIcon, PDFCollapsibleHeaderText } from "./StyledComponents";

interface PDFCollapsibleProps {
    title: string;
    children: React.ReactNode;
}

const PDFCollapsible = ({ title, children }: PDFCollapsibleProps) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <PDFCollapsibleWrapper>
            <PDFCollapsibleHeader
                onClick={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
            >
                <PDFCollapsibleHeaderText>{title}</PDFCollapsibleHeaderText>
                <StyledIcon name={isOpen ? "chevron-up" : "chevron-down"} />
            </PDFCollapsibleHeader>

            {isOpen && children}
        </PDFCollapsibleWrapper>
    );
};

export default PDFCollapsible;
