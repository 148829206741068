import ExerciseStarterText from "../../detailsSubComponents/exerciseStarterText/ExerciseStarterText";
import ExerciseEmptyLines from "../../detailsSubComponents/exerciseEmptyLines/ExerciseEmptyLines";

import { Wrapper } from "../../StyledComponents";

const DetailsFreeText = () => {
    return (
        <Wrapper>
            <ExerciseStarterText />
            <ExerciseEmptyLines linesAmount={6} />
        </Wrapper>
    );
};

export default DetailsFreeText;
