import { useT } from "@transifex/react";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";
import { useMemo } from "react";
import { settingsDefault } from "../../../../../config/settingsDefault";
import { SettingPosition } from "../../../../../enums/settings";
import { ensureReliableOverallScore } from "../../../../../helpers/ScoringHelper";
import PDFCollapsible from "../../pdfCollapsible/PDFCollapsible";
import { SettingsChangeProps } from "../../PDFConfiguration";
import PDFGradingScaleControl from "../../pdfSettingControl/specialisedControls/pdfGradingScaleControl/PDFGradingScaleControl";
import PDFTopBottomSettingControl from "../../pdfSettingControl/specialisedControls/pdfTopBottomSettingControl/PDFTopBottomSettingControl";

interface PDFGradesSignaturesSettingsProps {
    teacherTestDetails: TeacherTestDetails;
    handleSettingsChange: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        settingsChangeProps: SettingsChangeProps<T, K>
    ) => void;
}

const PDFGradesSignaturesSettings = (props: PDFGradesSignaturesSettingsProps) => {
    const cleanedUpTestDetails = useMemo(
        () => ensureReliableOverallScore(props.teacherTestDetails ?? {}),
        [props.teacherTestDetails]
    );

    const settings = cleanedUpTestDetails?.settings ?? settingsDefault;
    const pdfGradingSignaturesSettings = settings.gradingAndSignatures;

    const t = useT();
    const t_gradesAndSignatures = t("Grades and Signatures", { _tags: "PDFConfiguration" });
    const t_fieldForGrade = t("Field for grade", { _tags: "PDFConfiguration" });
    const t_average = t("Ø Average", { _tags: "PDFConfiguration" });

    const t_signature = t("Signature", { _tags: "PDFConfiguration" });
    const t_parentSignature = t("of a parent", { _tags: "PDFConfiguration" });
    const t_teacherSignature = t("of the teacher", { _tags: "PDFConfiguration" });
    const t_parent = t("parent", { _tags: "PDFConfiguration" });
    const t_teacher = t("teacher", { _tags: "PDFConfiguration" });

    const isAverageActive = !!pdfGradingSignaturesSettings?.average?.show;
    const isFieldForGradeActive = !!pdfGradingSignaturesSettings?.grade?.show;
    const isParentSignatureActive = !!pdfGradingSignaturesSettings?.parentSignature?.show;
    const isTeacherSignatureActive = !!pdfGradingSignaturesSettings?.teacherSignature?.show;

    const toggleVisibility = (settingTitle: SettingsSubSection<"gradingAndSignatures">, isActive: boolean) => {
        props.handleSettingsChange({
            sectionTitle: "gradingAndSignatures",
            settingTitle: settingTitle,
            setting: {
                key: "show",
                value: isActive,
            },
        });
    };

    const togglePosition = (
        settingTitle: SettingsSubSection<"gradingAndSignatures">,
        currentPosition: SettingPosition
    ) => {
        const newPosition = currentPosition === SettingPosition.TOP ? SettingPosition.BOTTOM : SettingPosition.TOP;
        props.handleSettingsChange({
            sectionTitle: "gradingAndSignatures",
            settingTitle,
            setting: {
                key: "position",
                value: newPosition,
            },
        });
    };

    return (
        <PDFCollapsible title={t_gradesAndSignatures}>
            <PDFTopBottomSettingControl
                settingTitle={t_average}
                topBottomSettingDetails={{
                    isActive: isAverageActive,
                    position: pdfGradingSignaturesSettings.average.position,
                    toggleOnOff: () => toggleVisibility("average", !isAverageActive),
                    togglePosition: () => togglePosition("average", pdfGradingSignaturesSettings.average.position),
                }}
            />
            <PDFTopBottomSettingControl
                settingTitle={t_fieldForGrade}
                topBottomSettingDetails={{
                    isActive: isFieldForGradeActive,
                    position: pdfGradingSignaturesSettings.grade.position,
                    toggleOnOff: () => toggleVisibility("grade", !isFieldForGradeActive),
                    togglePosition: () => togglePosition("grade", pdfGradingSignaturesSettings.grade.position),
                }}
            />
            <PDFGradingScaleControl
                teacherTestDetails={props.teacherTestDetails}
                togglePosition={togglePosition}
                toggleVisibility={toggleVisibility}
                handleSettingsChange={props.handleSettingsChange}
            />
            <PDFTopBottomSettingControl
                settingTitle={t_signature}
                topBottomSettingDetails={{
                    isActive: isParentSignatureActive,
                    toggleOnOff: () => toggleVisibility("parentSignature", !isParentSignatureActive),
                    togglePosition: () =>
                        togglePosition("parentSignature", pdfGradingSignaturesSettings.parentSignature.position),
                    position: pdfGradingSignaturesSettings.parentSignature.position,
                    subSettingTitle: t_parentSignature,
                    statusLabel: t_parent,
                }}
                secondaryTopBottomSettingDetails={{
                    isActive: isTeacherSignatureActive,
                    toggleOnOff: () => toggleVisibility("teacherSignature", !isTeacherSignatureActive),
                    togglePosition: () =>
                        togglePosition("teacherSignature", pdfGradingSignaturesSettings.teacherSignature.position),
                    position: pdfGradingSignaturesSettings.teacherSignature.position,
                    subSettingTitle: t_teacherSignature,
                    statusLabel: t_teacher,
                }}
            />
        </PDFCollapsible>
    );
};

export default PDFGradesSignaturesSettings;
