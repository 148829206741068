import styled from "styled-components";

export const Squircle = styled.div<{ isMobile: boolean }>`
    display: flex;
    width: 90%;
    background: white;
    border-radius: 1rem;
    padding: 2rem 2rem;
    flex: 1;
    margin: 2rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100%;

    ${({ isMobile }) =>
        isMobile &&
        `width: 100%;
        border-radius: 1rem;
        padding: 2rem 1rem 1rem;
        margin: 0.5rem 0 1rem;
        `};
`;
