import { useEffect, useState } from "react";
import { useTestCreationContext } from "../context/TestCreationContext";
import { calculateTotalScore } from "../helpers/ScoringHelper";

const useTestScore = () => {
    const { testContent, currentExerciseModel, showScores } = useTestCreationContext();

    const [testScore, setTestScore] = useState(0);

    useEffect(() => {
        if (!showScores) return;

        const previewTestContent = [...testContent];

        const exerciseIndex = previewTestContent.findIndex((ex) => ex.exerciseId === currentExerciseModel.exerciseId);

        if (exerciseIndex === -1) {
            if (Object.keys(currentExerciseModel).length !== 0) {
                previewTestContent.push(currentExerciseModel); // for exercises that haven't been saved yet
            }
        } else {
            previewTestContent[exerciseIndex] = currentExerciseModel; // for exercises that have been saved and are being edited, so that we can update the exercise score in real time
        }

        setTestScore(calculateTotalScore(previewTestContent));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testContent, currentExerciseModel, showScores]);

    return { testScore };
};

export default useTestScore;
